<template lang="pug">
  .map
    Score(v-if='user&&user.id')
    .map-sign(style='z-index:100;')
      .f-22
        h4.inline.vam.gray.ml-1e Круг Доверия
        img.w-1e.no-select.pointer.edit-map-btn.inline.vam(
          @click.stop='edit_map' :class='{"edit-map-btn--active":map_edit_mode}'
          src='/static/img/edit.svg'
        )

    .map-content
      .row
        Cell(n=36)
        Cell(n=19)
        Cell(n=20)
      .row
        Cell(n=34)
        Cell(n=35)
        Cell(n=18)
        Cell(n=7 )
        Cell(n=8 )
        Cell(n=21)
        Cell(n=22)
      .row
        Cell(n=33)
        Cell(n=17)
        Cell(n=6 )
        Cell(n=1 )
        Cell(n=2 )
        Cell(n=9 )
        Cell(n=23)
      .row
        Cell(n=32)
        Cell(n=16)
        Cell(n=5 )
        Cell(n=0 )
        Cell(n=3 )
        Cell(n=10)
        Cell(n=24)
      .row
        Cell(n=31)
        Cell(n=15)
        Cell(n=14)
        Cell(n=4 )
        Cell(n=12)
        Cell(n=11)
        Cell(n=25)
      .row
        .dummy
        Cell(n=30)
        Cell(n=29)
        Cell(n=13)
        Cell(n=27)
        Cell(n=26)
        .dummy
      .row
        .dummy
        Cell(n=28)
        .dummy
      div(style='position:absolute;bottom:0;right:0;width:18%;')
        Cell(n=-3 :u="$store.getters.userById(user.blist)" style='width:100%;transform:none;')
</template>








<script>
import {mapState,mapGetters} from 'vuex'

import Cell from '@/components/Cell.vue'
import Hovermenu from '@/components/Hovermenu.vue'
import Score from '@/components/Score.vue'

export default {
  name: 'Map',
  components: {
    Cell,
    Hovermenu,
    Score
  },

  data(){
    return {
      showMenu: false
    }
  },

  computed: {
    ...mapGetters({user:'currentUser'}),
    ...mapState({map_edit_mode: 'map_edit_mode'})
  },

  methods: {
    edit_map(){
      this.showMenu = false
      let val
      if (this.map_edit_mode){
        notie.hideAlerts()
        val = false
      } else {
        notie.alert({text:'Редактирование круга доверия',stay:true})
        val = true
      }
      this.$store.commit('EDIT_MAP', val)
    }
  }
}
</script>


<style scoped>
.map {
  /* background-color: lightyellow; */
  position: relative;
  overflow: hidden;
}

.map-sign {
  position: absolute;
  top: 0;
  left: 0;
}

.map:before {
  content: '';
  display: block;
  padding-top: 110%;
}

.map-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.row {
  /* background-color: lightblue; */
  height: 14.286%;
  white-space: nowrap;
  text-align: center;
  display: block;
}

.dummy {
  display: inline-block;
}

.edit-map-btn {
  /* margin: .75em; */
  padding: .5em;
  margin: .25em;
  opacity: .3;
}

.edit-map-btn:hover,
.edit-map-btn--active {
  opacity: 1;
}
.edit-map-btn--active {
  background-color: darkseagreen;
  border-radius: .5em;
}
</style>
