<template lang="pug">
	.homebar
		template(v-if='user')
			//- GO HOME BUTTON
			.hoverBtn(@click='goHome')
				.username {{user.firstName||user.lastName||user.userid}}
				.userimg
					.img(:style="{'background-image':'url('+img+')'}")

			//- TOGGLE HOVER MENU BUTTON
			.hoverBtn(
				@click='menuClick'
				@blur='showmenu=false'
				tabindex='0'
				:class='{hover:showmenu}'
			)
				Hovermenu.tal(v-show='showmenu' style='bottom: 1.1cm;right:0;')
					.menuitem.break-spaces(v-if='onMapPage' @click.stop='edit_map' :class='{"active":map_edit_mode}')
						span(v-if='map_edit_mode') Выйти&nbsp;из&nbsp;режима&#10;редактирования
						span(v-else) Редактировать&#10;Круг Доверия
					.menuitem(@click='goProfile') Профиль
					.menuitem.flex(@click='$router.push({name:"deals"})')
						span Баланс
						span.badge.tac(
							v-if='importantDealsCount>0'
						) {{importantDealsCount}}
					.menuitem(@click='logout') Выйти
					.menuitem.flex(@click='show_welcome_modal = true')
						span О Нас
						img.h-1_5e(src='static/img/question.svg')
				.username(style="font-size:36px;") ≡
				//- .userimg
				//- 	.img(:style="{'background-image':'url(/static/img/menu.svg)','margin-left':'4px'}")

		template(v-else)
			//- GO HOME BUTTON
			.hoverBtn(@click='goHome')
				.username Домой

			//- LOGIN BUTTON
			.hoverBtn(@click='showLogin=true')
				.username Войти

		Modal(v-if='showLogin' @close='showLogin=false')
			.tar(slot='header')
				span.f-22.f-1_5e.pointer.no-select(@click='showLogin=false') ✕
			div(slot='body' style='padding:2em;')
				Auth(@close='showLogin=false')
			div(slot='footer')

		Modal(v-if='show_welcome_modal' @close='show_welcome_modal=false')
			h2(slot='header') О нас
			About(slot='body')
			.tac(slot='footer' style='padding:1em;')
				button(@click='show_welcome_modal=false') Закрыть

</template>









<script>
import Hovermenu from '@/components/Hovermenu.vue'
import Auth from '@/components/Auth.vue'
import Modal from '@/components/Modal2.vue'
import {isImportant} from '@/deals'
import {mapState} from 'vuex'
import About from '@/components/About.vue'
// import {focus} from '@/directives'



export default {
	name: 'Homebar',
	components: {Hovermenu, Auth, Modal, About},

	data(){
		return {
			showmenu: false,
			showLogin: false,
			// searching: false,
			show_welcome_modal: false,
		}
	},

	directives: {focus},

	computed: {
		...mapState({user: 'loggedInUser', map_edit_mode: 'map_edit_mode'}),

		img() {
			if (this.user && this.user.img) return this.user.img
			return '/static/img/avatar-placeholder.png'
		},

		importantDealsCount() {
			const myId = this.user.id
			return this.$store.state.deals.filter(deal=>isImportant(deal, myId)).length
		},

		onMapPage() {
			return this.$route.name == 'map'
		},
	},

	methods: {
		logout() {
			this.$store.dispatch('logout').then(data=>{
				this.showmenu = false
			})
		},

		goProfile() {
			this.$router.push(
				this.user.slug
					? '/'+this.user.slug+'/profile'
					: '/id'+this.user.id+'/profile'
			)
		},

		goHome() {
			if (this.user){
				this.$router.push(
					this.user.slug
						? '/'+this.user.slug
						: '/id'+this.user.id
				)
			} else {
				this.$router.push('/')
			}
		},

		edit_map() {
			this.showmenu = false
			let val
			if (this.map_edit_mode){
				notie.hideAlerts()
				val = false
			} else {
				notie.alert({text:'Редактирование круга доверия',stay:true})
				val = true
			}
			this.$store.commit('EDIT_MAP', val)
		},

		menuClick() {
			this.showmenu = !this.showmenu
		},
	}
}
</script>


<style scoped>
.search {
	float: right;
	margin-top: 2px;
	margin-right: 2px;
}
input.search {
	/* box-sizing: border-box; */
	border: none;
	/* border-bottom: 1px solid gray; */
	padding: 0;
	margin: 0;
	background: transparent;
	text-align: center;
}

input.search:focus {
	outline: none;
}
</style>







<style scoped>
.homebar {
	background: white;
	font-size: 0;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	/* position: absolute; */
	position: fixed;
	bottom: 0;
	height: 40px;
	/* width: 100%; */
	width:inherit;

	box-shadow: 0 0 4px rgba(0,0,0,.5);
}

.hoverBtn {
	position: relative;
	display: inline-block;
	user-select: none;
	cursor: pointer;
	white-space: nowrap;
}

.hoverBtn:hover {
	background: lightgray;
}

.hoverBtn.hover {
	background: lightgray;
}

.hoverBtn:focus {
	outline: none;
}

.username {
	display: inline-block;
	vertical-align: top;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	margin-right: .5em;
	margin-left: .5em;
}

.userimg {
	/* font-size: 16px;
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0; */
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	line-height: 40px;
}

.img {
	/* width: 51%; */
	/* height: 59%; */
	height: 32px;
	width: 32px;
	margin-top: 4px;
	margin-right: 4px;
	display: inline-block;
	border-radius: 10%;
	/* margin: 2% auto; */
	background-repeat: no-repeat;
	background-size: cover;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,.5);
	padding: 20%;
}

.content {
	position: relative;
	background:white;
	border-radius:20px;
	padding:20px;
	box-shadow: 0 0 10px rgba(0,0,0,.5);
}

.close {
	color: gray;
	font-weight: bold;
	font-size: 24px;
	user-select: none;
	cursor: pointer;
	position: absolute;
	top: 2px;
	right: 10px;
}
.close:hover {
	color: dimgray;
}
</style>
