<template>
  <div class="open-deal f-16">
    <!-- TYPE -->
    <div>
      <div class="col-50 tal vam dimgray">тип</div>
      <div class="col-50 vam">
        <div class="flex" style="align-items:center;">
          <span class='smooth-color no-select pointer' :style="{color:type_buy?'darkgray':'black'}" @click='change_side("sell")'>продажа</span>
          <span class="grow tac">
             <div class='switch' :class='{"switch--on":type_buy}' @click='change_side()'>
               <div class="switch__toggle" :style="{'background-color':type_buy?'#6093DF':'#8AC74E'}"></div>
             </div>
          </span>
          <span class='smooth-color no-select pointer' :style="{color:!type_buy?'darkgray':'black'}" @click='change_side("buy")'>покупка</span>
        </div>
      </div>
    </div>

    <!-- DAYS -->
    <div>
      <div class="col-50 tal vam gray">срок (дней)</div>

      <div class="col-50 tac vam">
        <input v-if='editable' type="number" class="deal-input tac" style="width:100px;" v-onlyNums maxlength="3" v-model='deal.days'>
        <input v-else type="number" disabled style="width:100px;" v-model='deal.days' class="deal-input tac">
      </div>
    </div>

    <!-- GOODLESS DEAL TOTAL -->
    <div v-if='!hasDealgoods'>
      <div class="col-50 tal vam gray">сумма</div>

      <div class="col-50 tac vam">
        <input v-if='editable' type="number" class="deal-input tac" style="width:100px;" v-onlyNums maxlength="6" v-model='deal.dealgoods[0].price'>
        <input v-else disabled='true' type="number" class="deal-input tac" style="width:100px;" v-model="deal.dealgoods[0].price">
      </div>
    </div>

    <!-- GOODS LIST -->
    <div v-if='editable || hasDealgoods' class="tal">
      <div class="col-50 tal vam dimgray">товары</div>
      <GoodsList2 :deal='deal'></GoodsList2>
    </div>


    <!-- DETAILS -->
    <div v-if='hasDetails' class="tal">
      <div class="col-50 tal vam dimgray">детали</div>
      <Details :deal='deal'></Details>
    </div>


    <!-- BUTTONS -->
    <div class="opendeal__buttons" v-if='editable'>
      <button class='dealsview2__button button--red button--empty'>Удалить</button>
      <div class="buttons__sep"></div>
      <button v-if='dirty' class="dealsview2__button button--red button--empty">Отменить</button>
      <button class="dealsview2__button button--green">Сохранить</button>
      <button class="dealsview2__button button--blue">Отправить</button>
    </div>
  </div>
</template>





<script>
import GoodsList2 from '@/components/GoodsList2.vue'
import Details from '@/components/Details.vue'
import {
  ensureCss,
  ensureHtml,
  clone,
} from '@/funcs'
import {
  isNoGoodsDeal,
  isEditable,
  switchSides,
  hasDetails,
  isDirty2,
} from '@/deals'


export default {
  components: {
    GoodsList2,
    Details,
  },

  props: ['deal'],

  created() {
    if (isEditable(this.deal)) {
      //* save original deal
      if (!this.deal.original){

        //* fix deal.details plain text format to html
        this.deal.details = ensureHtml(this.deal.details)

        //* save original deal to check if there were changes
        this.deal.original = Object.freeze(clone(this.deal))
      }
    }

    // TODO: move this to Details.vue
    if (hasDetails(this.deal)) {
      ensureCss('/static/css/quill.snow.css')
    }
  },

  data() {
    return {
      editable: true,
    }
  },

  computed: {
    // editable() {return isEditable(this.deal)},
    hasDealgoods() {return !isNoGoodsDeal(this.deal)},
    type_buy() {return !this.deal.creator_is_seller},
    hasDetails() {return hasDetails(this.deal)},
    dirty() {return isDirty2(this.deal)},
  },

  methods: {
    change_side(type) {
      if ((type === undefined || (type == 'buy' && !this.type_buy) || (type == 'sell' && this.type_buy))) {
        switchSides(this.deal)
      }
    },
  }
}
</script>






<style lang="scss" scoped>

.open-deal {
  border-bottom: 1px solid lightgray;
  padding: .5em;
}

.deal-input {
  color: #222;
  border-radius: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,.25)
}

.opendeal__buttons {
  display: flex;
  padding: .4em 0;
}
.buttons__sep {
  flex-grow: 1;
  display: inline-block;
}

.switch {
  $this: &;
  border-radius: .9em;
  height: 1.8em;
  max-width: 100%;
  min-width: 3em;
  display: inline-block;
  background-color: white;
  vertical-align: middle;
  position: relative;
  text-align: left;
  padding-top:.3em;
  padding-left:.3em;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.25);
  user-select: none;
  cursor:pointer;
  &__toggle {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border-radius: .6em;
    background-color: lightblue;
    box-shadow: 0 2px 3px rgba(0,0,0,.25);
    margin-left: 0;
    transition: margin-left .2s, background-color .2s;
  }
  &--on {
    #{$this}__toggle {
      margin-left: calc(100% - 1.5em);
    }
  }
}

.smooth-color {
  transition: color .2s;
}
</style>
