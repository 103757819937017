<template lang="pug">
	.page

		//- tutorial
		#shade.abs.top.r.w-2e.h-2e.rad-50p.f-2e(style='font-size:2rem;')
		#overlay.fixed.w-100p.h-100p.t-0.l-0(style='opacity:0;')
			#textbox.abs.bottom.f-22.w-80p(style='left:10%;padding-bottom:2rem;')
				#card1.tut-card.hidden
					.card-body Это Ваш Круг Доверия. Он состоит из трех кругов.
					.btn.next(data-next='#card2') далее
				#card2.tut-card.hidden
					.card-body Самый маленький круг для тех, кому Вы доверяете больше всех.
					.btn.next(data-next='#card3') далее
				#card3.tut-card.hidden
					.card-body Помещенным в него персонам он дает 3 балла.
					.btn.next(data-next='#card4') далее
				#card4.tut-card.hidden
					.card-body Средний круг дает 2 балла.
					.btn.next(data-next='#card5') далее
				#card5.tut-card.hidden
					.card-body Самый большой круг дает 1 балл.
					.btn.next(data-next='#card1') далее
		#btn.abs.top.r.w-1_5e.h-1_5e.rad-50p.blue-btn.f-1_5e.lh-1_5e ?

		.left
			About.letter(v-if='!$store.state.loggedInUser')
		.mid
			.header(
				style='z-index:100;'
				:class='{"on-map-view": ["map", "map_by_id"].includes($route.name) }'
			)
				.head.topbar.tal
					.name.vat.topbar-element

						//- SEARCH
						div(
							v-if='searching',
							style='background:lightgray;'
						)
							input.f-26.search(
								v-focus,
								@keyup.enter='search'
								style='max-width:100%;'
							)
							span.f-26(
								@click='searching=false',
								style='cursor:pointer;'
							) ✕

						//- NAME
						.f-26.userName(
							v-else,
							@click='go_profile'
							:style='nameStyle'
						) {{userName}}


					.search.topbar-element

						div(
							@click='searching=true'
							style='cursor:pointer;'
						)
							svg#i-search(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 32 32', width='24', height='24', fill='none', stroke='currentcolor', stroke-linecap='round', stroke-linejoin='round', stroke-width='3')
								circle(cx='14', cy='14', r='12')
								path(d='M23 23 L30 30')


				//- .city.f-18.gray {{user.city || '...'}}
					span(v-if='user.interests && user.interests.length')
						span &nbsp;|&nbsp;
						span.comma-sep(v-for='id in user.interests') {{interestName(id)}}
				.city.f-18.gray.tal(:style='cityStyle') {{userCityInterests}}
				//- Score(v-if='user&&user.id')

			router-view(:key="$route.fullPath")

			Homebar
		.right

</template>






<script>
import {mapGetters} from 'vuex'
import {someName, getCssRules, score, loadJs} from '@/funcs.js'
import Bag from '@/components/Bag.vue'
import Homebar from '@/components/Homebar.vue'
// import Score from '@/components/Score.vue'
import About from '@/components/About.vue'
import {focus} from '@/directives.js'

const fontRules = [
	{name:'.f-16', k:48, rules:[]},
	{name:'.f-22', k:35, rules:[]},
]

var page = null
var left = null
var mid = null
var right = null
// var map = null
const mapAspectRatio = 1.254
var showingMap = false

function update(){
	let midPercent = !showingMap
		? Math.min(100, 800/page.clientWidth*100)
		: Math.min(100, (page.clientHeight-100)/mapAspectRatio/page.clientWidth*100)

	mid.style.width = midPercent + '%'
	left.style.width = (50-midPercent/2) + '%'
	right.style.width = left.style.width

	fontRules.forEach(i=>{
		if (!i.rules.length)
			i.rules = getCssRules(i.name)
		if (i.rules.length)
			i.rules.forEach(r=>r.style.fontSize = mid.clientWidth/i.k+'px')
		else
			console.warn('cssRules not found: ', i)
	})
}







export default {
	name: 'Home',

	components: {
		Bag,
		Homebar,
		// Score
		About,
	},

	directives: {focus},

	data(){
		return {
			searching: false,
			windowWidth: window.innerWidth,
		}
	},

	created(){
		window.addEventListener('resize', this.onWindowResize)
	},

	mounted(){
		page = document.querySelector('.page')
		left = document.querySelector('.left')
		mid = document.querySelector('.mid')
		right = document.querySelector('.right')
		showingMap = ['home','map','map_by_id'].includes(this.$route.name)
		update()
		window.addEventListener('resize', update)
		initTutorial(this)
	},

	beforeUpdate(){
		showingMap = ['home','map','map_by_id'].includes(this.$route.name)
		update()
	},

	beforeDestroy(){
		destroyTutorial()
		window.removeEventListener('resize', update)
		page = null
		left = null
		mid = null
		right = null
	},

	destroyed(){
		window.removeEventListener('resize', this.onWindowResize)
	},


	computed: {
		...mapGetters({user:'currentUser'}),

		userName(){
			return someName(this.user)
		},

		userCityInterests(){
			return (this.user.city || '...') + (this.user.interests && this.user.interests.length ? ' | ' + this.user.interests.map(interestId => {
				return this.$store.state.interests.find(i=>i.id==interestId).name
			}).join(', ') : '')
		},

		nameStyle(){
			const maxNameFontSize = Math.floor(this.windowWidth / this.userName.length * 1.4)
			return {
				fontSize: Math.min(26, maxNameFontSize) + 'px'
			}
		},

		cityStyle(){
			return {
				fontSize: Math.min(
					18,
					Math.floor(this.windowWidth / this.userCityInterests.length * 1.4)
				) + 'px'
			}
		}
	},

	methods: {
		someName,
		interestName(id){
			return this.$store.state.interests.find(i=>i.id==id).name
		},
		search(ev){
			if (ev.target.value.length<3) return
			// console.log('ENTER')
			this.$router.push({name:'search', params: {string: ev.target.value}})
		},
		go_profile(){
			//console.log('log==this.user', this.$store.state.loggedInUser==this.user)
			// if (this.$store.state.loggedInUser==this.user){
				let path
				if (this.user.slug) path = `/${this.user.slug}/profile`
				else path = `/id${this.user.id}/profile`
				//console.log('path', path)
				this.$router.push(path)
			// }
		},
		onWindowResize(e){
			this.windowWidth = window.innerWidth
		}
	}
}









// let tut
// let page
let btn
let shade
let overlay
let scale
let $el
const tutStates = {
	on: {
		init(){
			btn.classList.remove('blue-btn')
			btn.classList.add('transparent-x-btn')
			btn.innerText = '❌'
			btn.style.zIndex = '1003'

			shade.style.transform = `scale(${scale})`
			shade.style.zIndex = '1000'
			shade.style.opacity = '.8'

			overlay.style.zIndex = '1002'
			overlay.style.opacity = '1'
		}
	},
	off: {
		init(){
			btn.classList.remove('transparent-x-btn')
			btn.classList.add('blue-btn')
			btn.innerText = '?'
			btn.style.zIndex = ''

			shade.style.transform = 'scale(1)'
			shade.style.zIndex = ''
			shade.style.opacity = '.0'

			overlay.style.zIndex = ''
			overlay.style.opacity = '0'
		}
	},
	spotlightMap:{
		init(){
			$el.querySelectorAll('.map .cell').forEach(c=>c.style.zIndex='1001')
		},
		undo(){
			$el.querySelectorAll('.map .cell').forEach(c=>c.style.zIndex='')
		}
	},
	card1:{
		init(){
			$el.querySelectorAll('.map .cell').forEach(c=>c.style.zIndex='1001')
		},
		next(){
			$el.querySelectorAll('.map .cell').forEach(c=>c.style.zIndex='')
		}
	},
	card2:{
		init(){
			$el.querySelectorAll('.map .cell.circle-1').forEach(c=>c.style.zIndex='1001')
		},
		next(){
			$el.querySelectorAll('.map .cell.circle-1').forEach(c=>c.style.zIndex='')
		}
	}
}
const statesRoutes = {
	toggleOn: ['off','on'],
	toggleOff: ['on','off'],
	showMap: ['on','spotlightMap'],
	strightToMap: ['off','on','spotlightMap'],
	quitMap: ['spotlightMap','on','off']
}
let curState = 'off'
function runStateRoute(name){
	const route = statesRoutes[name]
	if (route&&route[0]==curState){
		if (tutStates[curState].undo){
			// ???
			tutStates[curState].undo()
		}
		route.slice(1).forEach(st=>{
			tutStates[st].init()
		})
		curState = route.slice(-1)[0]
	} else {
		console.warn('failed to runStateRoute',name)
	}
}

function onResize(){
	// console.log('onResize')
	scale = 2*Math.hypot(page.clientWidth,page.clientHeight)/btn.clientWidth
	if (curState == 'on'|| curState=='spotlightMap'){
		shade.style.transition = 'none'
		shade.style.transform = `scale(${scale})`
		shade.style.transition = ''
	} else {
		// console.log('skipping')
	}
}

function initTutorial(vm){
	$el = vm.$el
	// tut = $el.querySelector('#tut')
	// page = document.querySelector('.page')
	shade = page.querySelector('#shade')
	overlay = page.querySelector('#overlay')
	btn = page.querySelector('#btn')
	btn.addEventListener('click',btnClick)
	scale = 2*Math.hypot(page.clientWidth,page.clientHeight)/btn.clientWidth
	window.addEventListener('resize', onResize)
	overlay.querySelectorAll('.btn.next').forEach(on('click',nextClick))
}

function btnClick(e){
	if (curState=='off') runStateRoute('strightToMap')
	else runStateRoute('quitMap')
}

function destroyTutorial(){
	window.removeEventListener('resize', onResize)
	btn.removeEventListener('click',btnClick)
	overlay.querySelectorAll('.btn.next').forEach(off('click',nextClick))
	btn = void 0
	shade = void 0
	// tut = void 0
	// page = void 0
	$el = void 0
	overlay = void 0
}

function on(event,cb){
	return function(el){
		el.addEventListener(event,cb)
	}
}
function off(event,cb){
	return function(el){
		el.removeEventListener(event,cb)
	}
}

let curCard
const cards = {
	card1: {
		init(){

		}
	}
}

function nextClick(e){
	e.preventDefault()
	this.parentNode.classList.add('hidden')
	curCard = this.dataset.next
	overlay.querySelector(curCard).classList.remove('hidden')
}




</script>

<style>
	.transparent-x-btn {
		background: transparent;
		color: white;
		opacity: .5;
		transition: opacity .4s;
		cursor: pointer;
		user-select: none;
	}
	.transparent-x-btn:hover {
		opacity: .8;
	}

	#shade {
		transition: all .4s;
		transform: scale(1);
		background: black;
		opacity: 0;
	}
	.overlay {
		transition: opacity .4s;
	}
	.tut-card {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		text-align: center;
		/* background: black; */
		/* color: white; */
		/* padding: 1em; */
		/* border-radius: 1em; */
		transition: opacity 1s;
		opacity: 1;
		/* display: inline-block; */
		margin-bottom: 2em;
	}
	.tut-card.hidden {
		opacity: 0;
		/* display: none; */
	}
	.card-body {
		background: black;
		color: white;
		padding: 1em;
		border-radius: 1em;
		display: inline-block;
	}
</style>


<style scoped>
.topbar-element {
	display: inline-block;
}

.search {
	float: right;
	margin-top: 2px;
	margin-right: 2px;
}

.userName {
	display: block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; /* won't work with % width */
	cursor: pointer;
}

.page {
	vertical-align: top;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	/* background: lightgoldenrodyellow; */
	background: #eee;
}

.left {
	background: lightcyan;
}

.right {
	background: lightpink;
}

.left, .right {
	width: 20%;
	display: inline-block;
	vertical-align: top;
	max-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.mid {
	width: 60%;
	display: inline-block;
	vertical-align: top;
	max-height: 100vh;
	height: 100%;
	overflow: hidden;
	position: relative;
	box-shadow: 0 0 4px rgba(0,0,0,.5);
}

.header {
	height: 60px;
	background: white;
	/* padding-left: 10px; */
	position: relative;
	/* filter: drop-shadow(0 0 4px rgba(0,0,0,.5)); */
	box-shadow: 0 0 8px rgba(0,0,0,.5);
	/* z-index: 100; */
}
.on-map-view::after {
	position: absolute;
	right: .5em;
	bottom: 0;
	content: "доверители";
	font-size: 1rem;
	color: gray;
	font-weight: bold;
}

/* .name {
	cursor: pointer;
} */

/* .city {
	position: absolute;
	bottom: 0;
} */

input.search {
	/* box-sizing: border-box; */
	border: none;
	/* border-bottom: 1px solid gray; */
	padding: 0;
	margin: 0;
	background: transparent;
	text-align: center;
}

input.search:focus {
	outline: none;
}

/* used in BagList and AllList components in child router-view */
.scrollbox {
	overflow-y: auto;
	position: absolute;
	top: 60px;
	bottom: 40px;
	width: 100%;
	padding-top: 8%;
	padding-bottom: 1%;
}

.letter {
	box-shadow: 0 4px 12px gray;
	overflow: hidden;
}

</style>
