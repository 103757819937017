<template lang="pug">
  .cell.f-22(@click.stop="$emit('click')")
    .content
      slot ND
</template>


<script>
export default {
  name: 'HalfCell',

}
</script>

<style scoped>
.cell{
  /* width: 17.7%; */
  width: 17.9%;
  position: relative;
  display: inline-block;
  margin: 0 -2.11%;
  clip-path: polygon(0 0,100%0,75%100%,25%100%);
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.cell:first-child {
  margin-left: 0;
}

.cell:last-child {
  margin-right: 0;
}

.cell:before{
  content: '';
  display: block;
  padding-top: 43.3%;
}

.content{
  /* font-size: 48px; */
  font-size: 2.2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


</style>
