<template lang="pug">
	div
		Map
		Bag
</template>


<script>
import Map from '@/components/Map.vue'
import Bag from '@/components/Bag.vue'

export default {
	name: 'MapView',
	components: {Map, Bag}
}
</script>
