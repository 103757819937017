<template lang="pug">
	.auth
		div(v-if="mode!='register'")
			h3 {{is_not_reset?"Вход":"Восстановить пароль"}}
		div(v-else)
			h3 Регистрация
		input(
			v-model='userid'
			v-focus
			type='text'
			:placeholder='mode=="normal"?"Email":"Email"'
		)
		input(
			v-show="is_not_reset"
			v-model='pass'
			type='password'
			placeholder='Пароль'
			@keyup.enter='login'
		)
		template(v-if="mode=='register'")
			input(
				v-model='firstName'
				type='text'
				placeholder='Имя'
			)
			input(
				v-model='lastName'
				type='text'
				placeholder='Фамилия'
			)
			button.btn(@click='register') Далее
		button.btn(@click='login' v-if="mode!='register'") {{is_not_reset?"Войти":"Отправить"}}
		br
		a.btn(@click='mode="register"' v-if="mode=='normal'") Регистрация
		br
		a.btn(v-if="mode!='normal'" @click='mode="normal"') Назад
		br
		a.btn(@click='mode="reset"' v-if="is_not_reset") Забыли пароль?
		div
		a.btn(href="https://oauth.vk.com/authorize?client_id=6490715&display=popup&redirect_uri=http://sot.name/api/oauth/vk&scope=email&response_type=code")
			img.w-2e.vab(src="/static/img/logo_vk.com.svg")
		a.bnt(href="#")
			img.w-2e.vab(src="/static/img/logo_facebook.com.svg")
</template>











<script>
import {focus} from '@/directives'
import {validateEmail} from '@/funcs'


export default {
	name: 'Auth',

	directives: {focus},

	data(){
		return {
			userid: '', // email or tel
			pass: '',
			firstName: '',
			lastName: '',
			mode: 'normal'
		}
	},

	computed: {
    	is_not_reset: function () {
      		return this.mode == 'normal' || this.mode == 'register'
    	}
  	},

	methods: {
		login(){
			notie.hideAlerts()
			if (this.mode=="reset"){
				this.$store.dispatch('resetpass', this.userid).then(data=>{
					notie.alert({stay:true,text:'Ссылка для восстановления пароля отправлена на указанный адрес электронной почты.'})
				})
				return
			}
			this.$store.dispatch('login',
				{
					userid: this.userid,
					password: this.pass
				}
			).then(data=>{
				if (data.login=='OK')
					this.$emit('close')
				else
					notie.alert({
						type:'error',
						stay:true,
						text:'Неверный email или пароль! Попробуйте еще раз или обновите пароль нажав на ссылку "забыли пароль?"'
					})
			}).catch(data => {
				notie.alert({
					type:'error',
					stay:true,
					text:'Неверный email или пароль! Попробуйте еще раз или обновите пароль нажав на ссылку "забыли пароль?"'
				})
				console.log('err', data)
			})
		},

		register(){
			if (!validateEmail(this.userid)){
				notie.alert({type:'warning',text:'Необходимо указать email.'})
				return
			}
			this.userid = this.userid.trim()
			this.pass = this.pass.trim()
			this.firstName = this.firstName.trim()
			this.lastName = this.lastName.trim()
			if (!(this.userid&&this.pass&&this.firstName&&this.lastName)){
				notie.alert({text:'Заполнены не все поля',type:'warning'})
				return
			}

			// TODO validate userid

			this.$store.dispatch('register', {
				id:this.userid,
				pass:this.pass,
				firstName:this.firstName,
				lastName:this.lastName
			}).then(data=>{
				if (data.register=='OK')
					this.$emit('close')
					// this.$router.push(`/${this.lastName}_${this.firstName}/profile`)
					this.$router.push(`/profile`)
			})
		}
	}
}
</script>



<style scoped>
	input {
		display: block;
		margin: .2em auto;
		max-width: 100%;
	}
	a {
		font-size: 22px;
	}
</style>
