import Vue from 'vue'

const EventBus = new Vue()

export default EventBus

/*

  How to use:

  1. import Bus from '@/bus.js'

  2. Bus.$on or Bus.$once('event_name', (data)=>{})

  3. Bus.$emit('event_name', data)


  How to connect to a component:

  1. create event handler in the component's methods

  2. register the handler in the `created` hook with Bus.$on method

  3. unregister the handler in the `beforeDestroy` hook with Bus.$off method

*/
