import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MapView from './views/MapView.vue'
// import MapPage from './views/MapPage.vue'
// import Auth from './views/Auth.vue'
import BagList from './views/BagListView.vue'
import AllList from './views/AllListView.vue'
import Profile from './views/ProfileView.vue'
import ScoreList from './views/ScoreListView.vue'
import SearchView from './views/SearchView.vue'
// import DealsView from './views/DealsView.vue'
import BalanceView from '@/views/BalanceView.vue'
import EnterNewPassword from './views/EnterNewPassword'

import Test from './views/Test'

import store from './store'

Vue.config.devtools = true
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  /*
    RULES               if authenticated    not authed

    /                   my map              home map
    /bag                my bag list         -
    /profile            my profile          -
    /score/[1|2|3]      my score list       -
    /balance            my deals            -

    /all                all list            <- same
    /search             search result list  <- same
    /some_name          someone's map       <- same
    /some_name/profile  someone's profile   <- same
    /some_name/score/[1|2|3]  someone's score list

    !! some_name is a <slug> or user <id>

    /api                api calls
    /media              avatar images
    /static             js, css, img, fonts
  */

  routes: [
  // reserved-not userids
    {
      path: '/resetpassword',
      component: EnterNewPassword,
      name: 'resetpassword'
    },
    {
      path: '/search',
      component: Home,
      children: [
        {
          path: ':string',
          name: 'search',
          component: SearchView
        }
      ]
    },
    {
      path: '/test',
      component: Test,
      name: 'test'
    },


  // logged in user
    // {
    //   path: '/',
    //   name: 'home',
    //   component: MapPage
    // },
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '',
          name: 'home',
          component: MapView
        },
        {
          path: 'bag',
          name: 'bag',
          component: BagList
        },
        {
          path: 'all',
          name: 'all',
          component: AllList
        },
        {
          path: 'score/:group',
          name: 'score',
          component: ScoreList
        },
        {
          path: 'profile',
          name: 'profile',
          component: Profile
        },
        {
          path: 'balance',
          name: 'deals',
          component: BalanceView
        }
      ]
    },

  // other user - userids
    // {
    //   path: '/:id(id\\d+)',
    //   name: 'map_by_id',
    //   component: MapPage
    // },
    {
      path: '/:id(id\\d+)',
      component: Home,
      children: [
        {
          path: '',
          name: 'map_by_id',
          component: MapView
        },
        {
          path: 'profile',
          name: 'profile_by_id',
          component: Profile
        },
        {
          path: 'score/:group',
          name: 'score_by_id',
          component: ScoreList
        }
      ]
    },
    // {
    //   path: '/:slug',
    //   name: 'map',
    //   component: MapPage
    // },
    {
      path: '/:slug',
      component: Home,
      children: [
        {
          path: '',
          name: 'map',
          component: MapView
        },
        {
          path: 'profile',
          name: 'other_profile',
          component: Profile
        },
        {
          path: 'score/:group',
          name: 'other_score',
          component: ScoreList
        }
      ]
    },

  // other
    // {
    //   path: '/auth',
    //   name: 'auth',
    //   component: Auth
    // },
    {
      path: '/bag', redirect: {name: 'bag'}
    },
    {
      path: '/all', redirect: {name: 'all'}
    },
    {
      path: '/score', redirect: '/score/1'
    },

    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})

router.beforeEach((to,from,next) => {

  // unselect any selected cell
  store.commit('SELECT_CELL')


  // console.log('router.beforeEach')
  // console.log('store.state.loggedInUser', store.state.loggedInUser)
  // console.log('to', to)
  // console.log('from', from)
  const log = store.state.loggedInUser
  if (to.path=='/' && log){
    // console.log('before each ...')
    if (log.slug){
      next('/'+log.slug)
    } else {
      next(`/id${log.id}`)
    }
    // return
  } else {
    // console.log('skipping before each route')
    next()
  }

})

export default router
