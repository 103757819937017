<template>
  <div>
    <Editor
      v-if='editable'
      v-model='deal.details'
      :tools='tools'
      @hidetools='$emit("hidetools")'
    ></Editor>

    <div
      v-else-if='hasDetails'
      class="ql-container ql-snow tal"
      style='font-size: 1em;'
    >
      <div class="ql-editor" v-html='deal.details'></div>
    </div>
  </div>
</template>



<script>
import Editor from '@/components/Editor.vue'
import {
  isEditable,
  hasDetails,
} from '@/deals'



export default {
  components: {
    Editor,
  },

  // props: ['deal'],
  props: {
    deal: Object,
    tools: {default: true},
  },

  computed: {
    editable() {return isEditable(this.deal)},
    hasDetails() {return hasDetails(this.deal)},
  },
}
</script>







<style lang="scss" scoped>
.ql-container.ql-snow {
  border: none !important;
  background-color: rgba(255,255,255,.5);
  // padding: 1em;
}

</style>
