<template lang="pug">
  .field(:style="{background:editing?'lightgoldenrodyellow':''}" spellcheck='false')
    .col-20.f-22.dimgray
      slot label
    .col-80(v-if='!editing')
      .col-80.f-22(style='user-select:text;')
        span.sublabel {{sublabel}}
        span {{val}}
      .col-20.f-16.tar(v-if='editable')
        span.btn(v-if='editing && changed' @click.stop='save') сохранить
        span.btn(v-else @click.stop='edit') изменить
    .col-80(v-else-if='editable')
      div(style='display:table;width:100%;')
        .col-70.f-22(style='display:table-cell;')
          div(style='display:table;')
            span.sublabel(style='display:table-cell;') {{sublabel}}
            input.input.f-22(style='display:table-cell;' v-model='temp' v-focus @blur='blur' @keyup.enter='save' ref='input')
        .col-30.f-16.tar(style='display:table-cell;')
          span.btn(@mousedown.prevent @click.stop='save') готово
          span.btn(@mousedown.prevent @click.stop='cancel') отмена

</template>



<script>
export default {
  name: 'Edit',
  props: ['value', 'editable', 'sublabel', 'default', 'valid_re','illigal_values'],

  data(){
    return {
      editing: false,
      temp: this.value || ''
    }
  },

  directives: {
    focus: {
      inserted(el){
        el.focus()
      }
    }
  },

  computed: {
    changed(){
      if (!this.value)
        return Boolean(this.temp.trim())
      return this.temp.trim() != this.value
    },
    val(){
      return this.value || this.default
    }
  },

  methods: {
    valid(){
      const temp = this.temp.trim()
      if (this.default && this.default == temp) return true
      if (this.illigal_values && this.illigal_values.includes(temp)) return false
      if (this.valid_re && !temp.match(RegExp(this.valid_re))) return false
      return true
    },
    edit(){
      this.temp = this.value || ''
      this.editing = true
    },
    save(){
      if (!this.valid()){
        notie.alert({type:'warning', text:'Такое имя страницы нельзя использовать. Выберите другое.'})
        this.$refs.input.focus()
        return
      }
      // console.log('save')
      // this.value = this.temp
      this.editing = false
      this.temp = this.temp.trim()
      if (this.temp!==this.value)
        this.$emit('change', this.temp)
    },
    cancel(){
      // console.log('cancel')
      this.editing = false
      this.temp = this.value
    },
    blur(){
      if (!this.valid()) {
        notie.alert({type:'warning', text:'Такое имя страницы нельзя использовать. Выберите другое.'})
        this.$refs.input.focus()
        return
      }
      this.cancel()
    }
  }
}
</script>




<style scoped>
.field {
  border-bottom: 1px solid darkgray;
  padding: 1% 2%;
  font-size: 0;
  user-select: none;
  /* height: 1em; */
}
.field input {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  background: transparent;
}
.field input:focus {
  outline: none;
  /* background: #f8f8f8; */
}

span.btn {
  cursor: pointer;
  color: mediumblue;
  margin-left: 1em;
}
span.btn:hover {
  text-decoration: underline;
}
span.sublabel {
  color: lightslategray;
}
.input {
  display: inline-block;
}
</style>
