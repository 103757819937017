<template>
  <div
    @click.stop='toggleOpen'
    class="inline_deal f-22"
    :style='{"background-color": colors.title}'
  >
    <img
      class="inline_deal__avatar"
      :src='log.img || "static/img/avatar-placeholder.png"'
    />

    <template v-if='deal.seller==log'>

      <div class="inline_deal__short" :class='["font_color--"+stateName]'>
        {{short}}
      </div>
      <div class="inline_deal__arrow_right" :class='["arrow--"+stateName]'></div>
      <div class="inline_deal__sum" :class='["font_color--"+stateName]'>{{total}}</div>
      <img v-if='editable && !hasGoods' @click.stop='changeDealCost' src="/static/img/edit.svg" alt="edit" class='vam' style='height:1em;margin-left:.2em;'>

    </template>
    <template v-else>

      <div class="inline_deal__sum" :class='["font_color--"+stateName]'>{{total}}</div>
      <img v-if='editable && !hasGoods' @click.stop='changeDealCost' src="/static/img/edit.svg" alt="edit" class='vam' style='height:1em;margin-left:.2em;'>
      <div class="inline_deal__arrow_left" :class='["arrow--"+stateName]'></div>
      <div class="inline_deal__short" :class='["font_color--"+stateName]'>{{short}}</div>

    </template>

    <img
      class="inline_deal__avatar"
      :src='deal.counterpart.img || "static/img/avatar-placeholder.png"'
    />
  </div>
</template>




<script>
import {mapState} from 'vuex'
import {
  total,
  short,
  dealColors,
  dealState,
  hasGoods,
  isEditable,
  dealDate2,
} from '@/deals'

export default {
  components: {},

  props: ['deal'],

  computed: {
    ...mapState({log:'loggedInUser'}),
    editable() {return isEditable(this.deal)},
    total() {return total(this.deal)},
    short() {return short(this.deal)},
    colors() {return dealColors(this.deal)},
    stateName() {return dealState(this.deal)},
    hasGoods() {return hasGoods(this.deal)},
    // dateStr() {return dealDate2(this.deal)},
  },

  methods: {
    toggleOpen() {
      if (this.deal.open) {
        this.deal.open = false
      } else {
        this.$store.dispatch('get_user_goods', this.deal.seller.id).then(() => {
          this.deal.open = true
        })
      }
    },

    changeDealCost() {
      notie.input({
        text: 'Укажите стоимость соглашения',
        placeholder: this.deal.dealgoods[0].price,
        allowed: new RegExp('[^0-9]', 'g'),

        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text:'Стоимость соглашения должна быть положительной.'})
            return
          }
          this.deal.dealgoods[0].price = value
        },
        submitText: 'Готово',
        cancelText: 'Отменить'
      })
    },
  }
}
</script>





<style lang="scss" scoped>
.inline_deal {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px;
  margin-bottom: 0;
  padding: 0;
  border-radius: .5em;
  position: relative;
  box-sizing: border-box;


  &__avatar {
    height: 4em;
    width: 4em;
    border-radius: 5px;
    margin: .5em;
  }

  &__sum {
    font-size: 2em;
  }

  &__arrow_left {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    height: 5em;
    width: 10px;
    min-width: 10px;
    margin-left: 30px;
    margin-right: 10px;
    background-color: #eee;
    &::before {
      content: '';
      position: absolute;
      height: 0;
      right: 100%;
      border-top: 2.5em solid skyblue;
      border-bottom: 2.5em solid skyblue;
      border-right: 1em solid #eee;
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      border-top: 2.5em solid transparent;
      border-bottom: 2.5em solid transparent;
      border-right: 1em solid skyblue;
    }
  }

  &__arrow_right {
    display: inline-block;
    position: relative;
    height: 5em;
    width: 10px;
    min-width: 10px;
    margin-left: 10px;
    margin-right: 30px;
    background-color: #eee;
    &::before {
      content: '';
      position: absolute;
      left: 100%;
      border-top: 2.5em solid skyblue;
      border-bottom: 2.5em solid skyblue;
      border-left: 1em solid #eee;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      border-top: 2.5em solid transparent;
      border-bottom: 2.5em solid transparent;
      border-left: 1em solid skyblue;
    }
  }

  &__short {
    text-align: left;
    font-size: 1.5em;
    line-height: 1.2em;
    max-height: 2.4em;
    overflow: hidden;
    flex-grow: 1;
  }
}

$draft-font-color: #000;
$offered-font-color: #000;
$actual-font-color: #000;
$outdated-font-color: #fff;
$fulfilled-font-color: #ddd;
$important-font-color: #fff;

$draft-title-color: #1abc9c;
$offered-title-color: #f0b37e;
$actual-title-color: #6ab0de;
$outdated-title-color: #f24537;
$fulfilled-title-color: #888;
$important-title-color: #036db7;

.font_color--new,.font_color--draft {color: $draft-font-color;}
.font_color--offered {color: $offered-font-color;}
.font_color--actual {color: $actual-font-color;}
.font_color--outdated {color: $outdated-font-color;}
.font_color--fulfilled {color: $fulfilled-font-color;}
.font_color--important,.font_color--any {color: $important-font-color;}

.arrow--new,.arrow--draft {
  &::before {
    border-top-color: $draft-title-color;
    border-bottom-color: $draft-title-color;
  }
  &::after {
    border-left-color: $draft-title-color;
    border-right-color: $draft-title-color;
  }
}

.arrow--offered {
  &::before {
    border-top-color: $offered-title-color;
    border-bottom-color: $offered-title-color;
  }
  &::after {
    border-left-color: $offered-title-color;
    border-right-color: $offered-title-color;
  }
}

.arrow--actual {
  &::before {
    border-top-color: $actual-title-color;
    border-bottom-color: $actual-title-color;
  }
  &::after {
    border-left-color: $actual-title-color;
    border-right-color: $actual-title-color;
  }
}

.arrow--outdated {
  &::before {
    border-top-color: $outdated-title-color;
    border-bottom-color: $outdated-title-color;
  }
  &::after {
    border-left-color: $outdated-title-color;
    border-right-color: $outdated-title-color;
  }
}

.arrow--fulfilled {
  &::before {
    border-top-color: $fulfilled-title-color;
    border-bottom-color: $fulfilled-title-color;
  }
  &::after {
    border-left-color: $fulfilled-title-color;
    border-right-color: $fulfilled-title-color;
  }
}

.arrow--any,.arrow--important {
  &::before {
    border-top-color: $important-title-color;
    border-bottom-color: $important-title-color;
  }
  &::after {
    border-left-color: $important-title-color;
    border-right-color: $important-title-color;
  }
}
</style>
