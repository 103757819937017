<template lang="pug">
  .userrow
    .userrow-content
      div
      Cell(:u="user", :n='-2')
      .info.f-22
        p {{someName(user)}}
        p(style='font-size:.8em;') {{user.city}} | {{interests()||""}}
      .buttons.f-22
        a.btn(
          v-if='addible'
          @click='addToBag'
        ) добавить к себе
        a.btn(
           v-else-if='removable'
           @click='removeFromBag'
        ) удалить

</template>




<script>
import Cell from '@/components/Cell.vue'
import {
  someName,
  inMapOrBag,
  inBag,
} from '@/funcs'




export default {
  name: 'UserRow',
  components: {Cell},

  props: ['user'],

  computed: {
    log() {return this.$store.state.loggedInUser},

    addible(){
      // const log = this.$store.state.loggedInUser
      if (!this.log) return false
      if (this.log===this.user) return false
      return inMapOrBag(this.log, this.user)==-2
    },

    removable() {
      if (!this.log) return false
      if (this.log==this.user) return false
      return inBag(this.log, this.user) > -1
    },
  },

  methods: {
    someName,
    interests(){
      return this.user.interests? this.user.interests.map(id=>this.$store.state.interests.find(i=>i.id==id).name).join(', '): ''
    },

    addToBag(){
      /* ALREADY CHECKED IN computed.addible
      // check if the user can edit data
      if (!this.$store.state.loggedInUser) return

      // check if user is already in the bag
      if (this.$store.state.loggedInUser.bag.find(id=>id==this.user.id)) return
      */

      this.$store.dispatch('add_user_to_bag', this.user).then(data=>{
        if (!data.errors.length)
          notie.alert({text:'user added to bag'})
        else
          notie.alert({text:'error adding user to bag', type:'error'})
      })
    },

    removeFromBag() {
      // this.$store.commit('SELECT_CELL', )
      this.$store.dispatch('remove_selected_user', {user: this.user, n: -1})
    },
  }
}
</script>



<style scoped>
  .userrow {
    text-align: left;
    /* padding-left: 2%; */
    padding: 0.3% 0 0.3% 2%;
    position: relative;
    font-size: 0;
  }
  .userrow:before {
    content: '';
    padding-top: 15.54%;
    display: block;
  }
  .userrow-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    padding-left: 2%;
  }
  .info{
    margin-left: 4%;
    max-width: 70%;
  }

  .info p {
    margin: 0;
    font-size: 1.5em;
  }
  .buttons {
    position:absolute;
    top: 50%;
    right: 1%;
    transform: translateY(-50%);
  }
</style>
