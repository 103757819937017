<template lang="pug">
	.inlineDeal
		.title.tal(
			@click='inlinedeal.open=!inlinedeal.open'
			:style='titleColor'
		)
			.col-33
				.col-9.fold.f-22
					//- ▼ &#x25bc; ► &#x25ba;
					//- ˅ &#x02c5; ˃ &#x02c3;
					//- ⁞ &#x205e; ∙ &#x2219;
					//- ≡ &#x2261; − &#x2212;
					span(v-if='inlinedeal.open') &#x2261;
					span(v-else) &#x2212;

				//- date
				.col-90.f-22 {{inlinedeal.deal.accepted||inlinedeal.deal.created}}(+{{inlinedeal.deal.days}})

			//- doc n
			.col-33.f-22.tac
				| № {{inlinedeal.deal.id}}

			//- balance
			.col-30.f-22.tar(:style='{color:balanceColor}')
				| {{sign}} {{-1*balance(inlinedeal.deal)}}

		//- Deal
		template(v-if='inlinedeal.open')
			Deal(
				v-if='inlinedeal.deal.state=="draft"'
				:deal='inlinedeal.deal'
				@setUser='setUser'
				@delete='del'
			)
			Deal(
				v-else-if='inlinedeal.deal.state=="offered"'
				:deal='inlinedeal.deal'
				@delete='del'
			)
			Deal(
				v-else
				:deal='inlinedeal.deal'
			)
</template>










<script>
import Deal from '@/components/Deal.vue'
import {
	stateColor,
	total,
	balance
} from '@/deals.js'





export default {
	name: 'InlineDeal',
	props: ['inlinedeal'],
	components: {Deal},
	computed: {
		sign(){
			return this.inlinedeal.deal.sellerId==this.$store.state.loggedInUser.id
				? '+'
				: '-'
		},
		titleColor(){
			const colors = stateColor(this.inlinedeal.deal.state)
			return {
				backgroundColor: colors.title,
				color: colors.font
			}
		},
		balanceColor(){
			return this.sign=='+'
				? 'green'
				: this.inlinedeal.deal.state=='outdated'
					? '#fbb'
					: 'red'
		}
	},
	methods: {
		balance,
		setUser(user){
			console.log('InlineDeal:setUser()')
			this.$emit('setUser', {user, inlinedeal:this.inlinedeal})
		},
		del(){
			this.$emit('delete')
		}
	}
}
</script>












<style scoped>
.inlineDeal {
	/* border: 2px solid darkgreen; */
	margin: 2%;
	box-shadow: 0 0 4px gray;
	border-radius: .5em;
	overflow: hidden;
}
.title {
	background: darkgray;
	cursor: pointer;
	user-select: none;
	padding: 1%;
}
.fold {
	/* width: 3%; */
	/* vertical-align: text-bottom; */
	/* display: inline-block; */
	font-weight: bold;
}
.num {
	display: inline-block;
	width: 8%;
	text-align: right;
}
.date {
	display: inline-block;
	width: 20%;
	padding-left: 1%;
}
.sign {
	display: inline-block;
	width: 3%;
	/* padding-left: 1%; */
	text-align: center;
}
.total {
	display: inline-block;
	width: 15%;
	text-align: right;
}
</style>
