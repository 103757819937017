<template lang="pug">
  div(
    @click.stop
    :style='{"background-color":inlinedeal.open?"#ddd":"unset"}'
  )
    .inline-deal(
      @click.stop='toggleOpen'
    )
      .col-50(style='border-right:1px solid lightgray;box-sizing:border-box;')
        .userrow2
          .content
            .f-22.tal(
              v-if='!inlinedeal.open'
              style='max-height:100%;overflow:hidden;'
            )
              span {{short}}

      .col-50
        .userrow2
          .content
            .f-22
              span(
                style='font-size:2em;'
                :style='balanceStyle'
              )
                span(v-if='im_seller') +
                span(v-if='editable && noGoodsDeal' @click.stop)
                  span(v-if='!im_seller') -
                  Editable(
                    v-model='inlinedeal.deal.dealgoods[0].price'
                    placeholder='сумма'
                    style="min-width:.5em;"
                  )
                span.tac.rel(
                  v-else
                ) {{balance}}
                  .abs.l-0.f_5e {{stateRu(inlinedeal.deal.state)}}

            Cell(:u='u', :n='-2')

    .deal(v-if='inlinedeal.open')

      //- DEAL N DATE
      .f-22 {{date}} № {{inlinedeal.deal.id}}

        span &nbsp;на {{inlinedeal.deal.days}} {{days(inlinedeal.deal.days)}}
        span(v-if='!nonBalanceDeal') &nbsp;осталось: {{left}}

      //- DAYS SUM(if no goods) SWOP-btn
      .f-22(v-if='editable')
        template(v-if='im_seller')

          //- swop
          span
            .btn.f-22(@click='swop') &rlarr;

          //- days
          span на
            button(@click='decreaseDays') &lt;
            input#days(
              type='number'
              min='1'
              v-model='inlinedeal.deal.days'
              style='-moz-appearance:textfield;width:4em;text-align:center;'
              @keypress='handleDaysKeypress'
            )
            button(@click='increaseDays') &gt;
            span {{days(inlinedeal.deal.days)}}

          //- sum
          span.f-22.tac(
            v-if='noGoodsDeal && editable'
            @click.stop
            style='margin: 0 1em;'
          )
            span Сумма:
            Editable(
              v-model='inlinedeal.deal.dealgoods[0].price'
              placeholder=''
              style='padding:.5em;background-color: white;'

            )

        template(v-else)
          //- sum
          span.f-22.tac(
            v-if='noGoodsDeal && editable'
            @click.stop
            style='margin: 0 1em;'
          )
            span Сумма:
            Editable(
              v-model='inlinedeal.deal.dealgoods[0].price'
              placeholder=''
              style='padding:.5em;background-color: white;'
            )

          //- days
          span на
            button(@click='decreaseDays') &lt;
            input#days(
              type='number'
              min='1'
              v-model='inlinedeal.deal.days'
              style='-moz-appearance:textfield;width:4em;text-align:center;'
              @keypress='handleDaysKeypress'
            )
            button(@click='increaseDays') &gt;
            span {{days(inlinedeal.deal.days)}}

          //- swop
          span
            .btn.f-22(@click='swop') &rlarr;


      //- DETAILS
      //- Editor.f-22(v-if='editable' v-model='inlinedeal.deal.details')
      //- .ql-editor.f-22(v-else v-html='inlinedeal.deal.details')
      .ql-editor.f-22(v-html='inlinedeal.deal.details')
      .btn.f-22(
        v-if='editable'
        @click='$modal.show(`deal-details-${inlinedeal.deal.id}`)'
      ) edit

      //- GOODS
      .f-22.tac(v-if='!noGoodsDeal')
        br
        .goodline.gray
          .col-40 наименование
          .col-20 цена
          .col-20 кол-во
          .col-20 сумма
        .goodline(v-if='editable')
          .col-40.vam
            input.f-22.tac(
              list='goodslist'
              v-model='addDealgoodName'
              placeholder='выбрать'
            )
            datalist#goodslist
              option(
                v-for='good in $store.state.goods.slice(1)'
                :value='good.name'
              )
          .col-20.vam
            input.f-22.tac(
              type='number'
              min='0'
              v-model='addDealgoodPrice'
            )
          .col-20.vam
            input.f-22.tac(
              type='number'
              min='0'
              v-model='addDealgoodQ'
            )
          .col-20.f-22.tac.vam(style='background:white;')
            a.btn(@click='addDealgood') добавить

        .goodline(
          v-for='(dg,index) in inlinedeal.deal.dealgoods'
          :key='dg.id'
        )
          .col-40.tac.vam {{goodName($store,dg)}}
          .col-20.tac.vam {{dg.price}}
          .col-20.tac.vam {{dg.q}}
          .col-20.tac.vam {{dg.price * dg.q}}
            a.btn(v-if='editable' @click='remDealgood(index)') &times;



      //- BUTTONS
      .buttons.f-22.tac

        template(v-if='editable')
          .btn(@click='delete_deal') Удалить
          .btn(
            v-if='dirty'
            @click='discard_changes'
          ) Отмена
          .btn(
            v-if='noGoodsDeal'
            @click='setDealWithGoods'
          ) Добавить товар
          .btn(
            v-else
            @click='setNoGoodsDeal'
          ) Без товаров
          .btn(@click='send_offer') Отправить
          .btn(
            v-if='dirty'
            @click='save_deal'
          ) Сохранить


        template(v-else-if='inlinedeal.deal.state=="offered"')
          template(v-if='im_creator')
            .btn(@click='cancel_offer') Отменить
          template(v-else)
            .btn(@click='reject_offer') Отклонить
            .btn(@click='accept_offer') Принять


        template(
          v-else-if='inlinedeal.deal.state=="actual" || inlinedeal.deal.state=="outdated"'
        )
          template(v-if='im_seller')
            .btn(@click='extend_deal') Продлить
            .btn(@click='close_deal') Закрыть сделку
          template(v-else)
            .btn(
              v-if='!inlinedeal.deal.extend_request'
              @click='request_extend'
            ) Запросить продление
            .btn(
              v-else
              @click='cancel_request'
            ) Отменить запрос на продление



    //- Modal Edit Deal details
    modal(
      :name='`deal-details-${inlinedeal.deal.id}`'
      :adaptive='true'
      :scrollable='true'
      height="auto"
    )
      Editor(
        v-model='inlinedeal.deal.details'
        style='font-size:1rem;'
      )
</template>








<script>
import {
  ensureHtml,
  ensureCss,
  clone,
  days,
  isArray,
  eraseArray,
  copyArray,
  lim,
} from '@/funcs'

import {
  balance,
  short,
  isEditable,
  hasDetails,
  isNoGoodsDeal,
  setupEditableDealVm,
  isDirty,
  isNonBalance,
  daysLeft,
  goodName,
  stateColor,
  stateRu,
  dealState,
  total,
} from '@/deals'

import Cell from '@/components/Cell.vue'
import Editor from '@/components/Editor.vue'
import Editable from '@/components/Editable2.vue'

import Bus from '@/bus.js'


export default {
  name: 'InlineDeal2',

  props: ['inlinedeal', 'u'],

  components: {Cell, Editor, Editable},

  created(){
    if (isEditable(this.inlinedeal.deal))
      setupEditableDealVm(this)

    if (hasDetails(this.inlinedeal.deal))
      ensureCss('/static/css/quill.snow.css')
  },

  computed: {
    log(){
      return this.$store.state.loggedInUser
    },

    editable(){
      return isEditable(this.inlinedeal.deal)
    },

    hasDetails(){
      return hasDetails(this.inlinedeal.deal)
    },

    noGoodsDeal(){
      return isNoGoodsDeal(this.inlinedeal.deal)
    },

    im_seller(){
      return this.log.id==this.inlinedeal.deal.sellerId
    },

    im_creator(){
      return this.inlinedeal.deal.creator_is_seller
        ? this.im_seller
        : !this.im_seller
    },

    dirty(){
      return isDirty(this)
    },

    balance(){
      const deal = this.inlinedeal.deal
      const sign = this.im_seller?-1:1
      const sum = isNonBalance(deal)
        ? total(deal) * sign * -1
        : sign * balance(deal)

      return sum
      // return (this.im_seller?-1:1) * balance(this.inlinedeal.deal)
      // return balance(this.inlinedeal.deal)
    },

    nonBalanceDeal(){
      return isNonBalance(this.inlinedeal.deal)
    },

    date(){
      return this.inlinedeal.deal.accepted
        || this.inlinedeal.deal.created
    },

    left(){
      return daysLeft(this.inlinedeal.deal)
    },

    short(){
      // return 'mock'
      return short(this.inlinedeal.deal)
    },

    balanceStyle(){
      return {
        color: stateColor(dealState(this.inlinedeal.deal)).title
      }
    }
  },

  methods: {
    days,
    goodName,
    stateRu,

    toggleOpen(){
      if (this.inlinedeal.open){
        // close
        this.inlinedeal.open = false
      } else {
        // open
        this.inlinedeal.open = true
        Bus.$emit('scrollTo', this.$el)
        // this.$emit('open')
      }
    },

    handleDaysKeypress(e){
      console.log('keypress', e)
    },

    increaseDays(){
      this.inlinedeal.deal.days = Number(this.inlinedeal.deal.days) + 1
    },

    decreaseDays(){
      this.inlinedeal.deal.days = Math.max(1, Number(this.inlinedeal.deal.days)-1)
    },

    swop(){
      if (this.inlinedeal.deal.creator_is_seller){
        this.inlinedeal.deal.counterpartId = this.inlinedeal.deal.sellerId
        this.inlinedeal.deal.creatorId = this.inlinedeal.deal.buyerId
      } else {
        this.inlinedeal.deal.counterpartId = this.inlinedeal.deal.buyerId
        this.inlinedeal.deal.creatorId = this.inlinedeal.deal.sellerId
      }

      [
        this.inlinedeal.deal.sellerId,
        this.inlinedeal.deal.buyerId
      ] = [
        this.inlinedeal.deal.buyerId,
        this.inlinedeal.deal.sellerId
      ]

      this.inlinedeal.deal.creator_is_seller = !this.inlinedeal.deal.creator_is_seller
    },

    addDealgood(){
      const name = this.addDealgoodName.trim().replace(/\s\s+/g,' ')
      if (!name) return
      if (this.addDealgoodPrice<=0) return
      if (this.addDealgoodQ<=0) return
      const dealgood = {
        id: null,
        price: Number(this.addDealgoodPrice),
        q: Number(this.addDealgoodQ)
      }
      const good = this.$store.getters.goodByName(name)

      if (good)
        dealgood.goodId = good.id
      else
        dealgood.goodName = name

      this.inlinedeal.deal.dealgoods.unshift(dealgood)
      this.addDealgoodName = ''
      this.addDealgoodPrice = 0
      this.addDealgoodQ = 0
    },

    remDealgood(index){
      this.inlinedeal.deal.dealgoods.splice(index, 1)
    },

    /* toggleOpen(){
      console.log('InlineDeal2 toggleOpen')
      this.inlinedeal.open=!this.inlinedeal.open
    }, */

    // draft
    delete_deal(){
      this.$store.dispatch(
        'delete_deal',
        {
          id: this.inlinedeal.deal.id,
          deal: this.inlinedeal.deal
        }
      ).then(data => this.$emit('delete'))
    },

    discard_changes(){
      let orig = this.inlinedeal.deal.original
      for (let k in orig){
        let field = this.inlinedeal.deal[k]
        if (isArray(field)){
          eraseArray(field)
          copyArray(orig[k], field)
        } else {
          this.inlinedeal.deal[k] = orig[k]
        }
      }
    },

    setDealWithGoods(){
      if (this.noGoodsDeal)
        eraseArray(this.inlinedeal.deal.dealgoods)
    },

    setNoGoodsDeal(){
      const _total = total(this.inlinedeal.deal)
      eraseArray(this.inlinedeal.deal.dealgoods)
      this.inlinedeal.deal.dealgoods.push({
        goodId: 1, q: 1, price: _total
      })
    },

    payload(){
      return clone(this.inlinedeal.deal, [
        'id',
        'sellerId',
        'buyerId',
        'creator_is_seller',
        'state',
        'created',
        'dealgoods',
        'details',
        'days'
      ])
    },

    valid(){
      if (!(this.inlinedeal.deal.sellerId && this.inlinedeal.deal.buyerId)){
				notie.alert({
					type:'warning',
					text:'Не выбран покупатель/продавец!'
				})
				return false
			}

			if (!this.inlinedeal.deal.dealgoods.length){
				notie.alert({
					type:'warning',
					text:'Сделка на нулевую сумму! Добавьте какой-нибудь товар или укажите сумму и детали сделки.'
				})
				return false
			}

			if (this.noGoodsDeal && !this.hasDetails){
				notie.alert({
					type:'warning',
					text:'Сделка без товаров должна иметь описание/детали.'
				})
				return false
      }

      if (Number(this.inlinedeal.deal.days)<=0){
        notie.alert({
          type:'warning',
          text: 'Срок сделки должен быть пложительным'
        })
        return false
      }

			return true
    },

    send_offer(){
      if (this.valid()){
        const deal = this.payload()
        this.$store.dispatch('offer_deal', {
          deal, dirty: this.dirty
        }).then(data => {
          if (!data.errors.length){
            delete this.inlinedeal.deal.original
            const deal = this.inlinedeal.deal
            for (let k in data.deal)
              if (deal[k] != data.deal[k])
                deal[k] = data.deal[k]
          }
        })
      }
    },

    save_deal(){
      if (this.valid()){
        const deal = this.payload()
        if (deal.state=='new') deal.state='draft'
        this.$store.dispatch('save_deal', deal).then(data=>{
          if (!data.errors.length){
            const deal = this.inlinedeal.deal
            for (let k in data.deal)
              if (deal[k]!=data.deal[k])
                deal[k]=data.deal[k]
            this.update_original()
          }
        })
      }
    },

    update_original(){
      delete this.inlinedeal.deal.original
      this.$set(this.inlinedeal.deal, 'original', Object.freeze(clone(this.inlinedeal.deal)))
    },

    // offered
    cancel_offer(){
      this.$store.dispatch('cancel_deal', this.inlinedeal.deal.id).then(data => {
        notie.alert({text: 'Предложение отменено!'})
        this.update_original()
      })
    },

    reject_offer(){
      this.$store.dispatch('reject_deal', this.inlinedeal.deal.id).then(data => {
        notie.alert({text: 'Предложение отклонено!'})
        this.$emit('delete')
      })
    },

    accept_offer(){
      this.$store.dispatch('accept_deal', this.inlinedeal.deal.id).then(data=>{
        notie.alert({text: 'Предложение принято!'})
      })
    },

    // actual/outdated
    extend_deal(){
      notie.input({
        text: 'На сколько дней продлить шаг?',
        placeholder: this.inlinedeal.deal.extend_request || "7",
        allowed: new RegExp('[^0-9]', 'g'),
        submitText: 'Продлить',
        cancelText: 'Отменить',

        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text: 'Количество дней должно быть положительным'})
            return
          }
          this.$store.dispatch(
            'extend_deal',
            {
              days: value,
              dealId: this.inlinedeal.deal.id
            }
          ).then(data =>{
            notie.alert({text: 'Шаг продлен'})
          })
        }
      })
    },

    close_deal(){
      this.$store.dispatch('close_deal', this.inlinedeal.deal.id).then(data=>{
        notie.alert({text:'Шаг закрыт'})
      })
    },

    request_extend(){
      notie.input({
        text: 'На сколько дней запросить продление?',
        placeholder: '7',
        allowed: new RegExp('[^0-9]', 'g'),
        submitText: 'Отправить запрос',
        cancelText: 'Отменить',

        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text:'Количество дней должно быть положительным'})
            return
          }
          this.$store.dispatch(
            'request_deal_extend',
            {
              days: value,
              dealId: this.inlinedeal.deal.id
            }
          ).then(data => {
            notie.alert({text:'Запрос о продлении отправлен'})
          })
        }
      })
    },

    cancel_request(){
      this.$store.dispatch('cancel_extend_request', this.inlinedeal.deal.id).then(data=>{
        notie.alert({text: 'Запрос о продлении отменен'})
      })
    }
  }
}
</script>








<style scoped src='@/components/DealsView2/style.css'></style>

<style lang="stylus" scoped>
.buttons
  display flex
  justify-content space-between
  flex-wrap wrap
  padding-bottom 2em

.btn
  display inline-block
  margin 0.2em
  padding 0.5em
  border-radius 0.5em
  user-select none
  cursor pointer
  white-space nowrap

.goodline
  border-bottom 1px solid rgba(0,0,0,.1)
  input
    padding 0
    border none
    width 100%
    margin 0

.deal
  border-bottom 1px solid lightgray
  #days::-webkit-inner-spin-button,
  #days::-webkit-outer-spin-button
    -webkit-appearance none
    margin 0

.editor
  background-color white
  .ql-container
    font-size unset!important
</style>
