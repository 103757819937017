<template>
  <div class="opendeal">
    <div v-if='!im_seller' class="opendeal__arrow-left" :style='{"border-color":colors.title}'></div>

    <div class="opendeal__deal" :style='{"background-color":colors.body}'>


      <!-- DETAILS -->
      <div v-if='editable'>
        <div class="opendeal__header">
          <span>Описание</span>
          <img
            @click='showDetailsTools=true'
            src="/static/img/edit.svg"
            style="width:1em;float:right;user-select:none;cursor:pointer;"
            alt="edit"
          >
        </div>
        <Details
          :deal='deal'
          :tools='showDetailsTools'
          @hidetools='showDetailsTools=false'
        ></Details>
      </div>
      <div v-else-if='hasDetails'>
        <div class="opendeal__header">Описание</div>
        <Details :deal='deal'></Details>
      </div>


      <!-- GOODS -->
      <div v-if='editable'>

        <div class="opendeal__header">
          <span>Товары</span>

          <div
            @click='editGoods=!editGoods'
            style="width: .5em; height: .5em;border-left:3px solid black;border-bottom:3px solid black; float:right;margin: .1em 0em .2em .5em;"
            :style='{transform: editGoods ? "rotateZ(135deg)": "rotateZ(-45deg)"}'
          ></div>

          <img
            @click='editGoods=!editGoods'
            src="/static/img/edit.svg"
            style="width:1em;float:right;user-select:none;cursor:pointer;"
            alt="edit"
          >

        </div>

        <div v-if='editGoods' style='padding: .5em;'>
          <GoodsList
            :deal='deal'
            class="opendeal__goods-summary"
          ></GoodsList>
        </div>
        <div v-else class="opendeal__goods-summary">
          <DealgoodsSummary
            v-if='hasGoods'
            :deal='deal'
          ></DealgoodsSummary>
          <i v-else>Чтобы добавить товар нажмите на кнопку редактирования</i>
        </div>

      </div>
      <div v-else-if='hasGoods'>

        <div class="opendeal__header">Товары</div>
        <div class="opendeal__goods-summary">
          <DealgoodsSummary
            :deal='deal'
          ></DealgoodsSummary>
        </div>

      </div>


      <!-- DEAL IMGS -->
      <div v-if='deal.imgs.length' class="images" style="overflow-y:auto;">
        <div v-for='img in deal.imgs' :key='img.id' class="image">
          <img :src="img.url" class='image__img' @click='zoomImg = img.url'>
          <div v-if='editable' @click.stop='delImg(img)' class="image__del"></div>
        </div>
        <div v-if="zoomImg" style="position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:10000;overflow:auto;background-color:rgba(0,0,0,.5);font-size:0;">
          <img :src="zoomImg">
          <div style="position:fixed;top:0;right:0;width:40px;height:40px;background-color:white;color:red;font-style:bold;line-height:40px;font-size:40px;" @click="zoomImg = null">X</div>
        </div>
      </div>


      <!-- BUTTONS -->
      <div class="opendeal__buttons">

        <template v-if='editable'>

          <img @click='delete_deal' src="/static/img/trash_red.svg" alt="delete" class='button'>

          <div class="sep"></div>

          <img v-if='goods.length || im_seller' @click='showPricelist=true' src="/static/img/bag.svg" alt="pricelist" class='button'>

          <label v-if='deal.imgs.length < 4'>
            <img src="/static/img/camera.svg" alt="picture" class="button block">

            <input @change='loadImages($event)' type="file" multiple accept="image/*" class='abs wh-0 hidden'>
          </label>

          <a @click='changeDays' class="button">
            <img src="/static/img/calendar.svg" alt="days" style='height:1em;vertical-align:middle;margin-right:.2em;'>
            <span class='vam'>{{deal.days}}</span>
          </a>

          <img @click='swap_sides' src="/static/img/swap.svg" alt="swap sides" style='height:1em;' class='button vam'>

          <div class="sep"></div>

          <img v-if='dirty' @click='save_deal' src="/static/img/save_blue.svg" alt="save" class='button'>

          <img @click='send_offer' src="/static/img/send_blue_2.svg" alt="send" class='button'>

        </template>
        <template v-else-if='deal.state=="offered"'>

          <img @click='cancel_offer' v-if='im_creator' src="/static/img/block.svg" alt="cancel" class='button'>
          <template v-else>
            <img @click='reject_offer' src="/static/img/block.svg" alt="reject" class='button'>
            <div class="sep"></div>
            <img @click='accept_offer' src="/static/img/check.svg" alt="accept" class='button'>
          </template>

        </template>
        <template v-else-if='deal.state=="actual"||deal.state=="outdated"'>

          <template v-if='im_seller'>
            <div class="sep"></div>
            <a @click='extend_deal' class="button button--green">продлить</a>
            <a @click='close_deal' class="button button--blue">закрыть</a>
          </template>
          <template v-else>
            <div class="sep"></div>
            <a v-if='!deal.extend_request' @click='request_extend' class="button button--green">запросить продление</a>
            <a v-else @click='cancel_request' class="button button--blue">отменить запрос на продление</a>
          </template>

        </template>

      </div>

      <!-- PRICELIST MODAL -->
      <Pricelist
        v-if='editable && showPricelist'
        :user='deal.seller'
        :addGoodButton='true'
        @close='showPricelist=false'
      ></Pricelist>

    </div>

    <div v-if='im_seller' class="opendeal__arrow-right" :style='{"border-color":colors.title}'></div>
  </div>
</template>




<script>
import store from '@/store'

import Details from '@/components/Details.vue'
import GoodsList from '@/components/GoodsList2.vue'
import Pricelist from '@/components/Pricelist.vue'
import DealgoodsSummary from '@/components/DealgoodsSummary.vue'

import {
  clone,
  loadImg,
  loadImgURL,
} from '@/funcs'

import {
  isEditable,
  // isNoGoodsDeal,
  hasGoods,
  hasDetails,
  isDirty2,
  saveOriginalDeal,
  isValid,
  getPayload,
  updateOriginal,
  dealDate2,
  dealColors,
  short,
  // goodsSummary,
  switchSides,
  unsetNoGoodsDeal,
} from '@/deals'

import Bus from '@/bus'


var newImgsCount = 0


export default {
  components: {
    Details,
    GoodsList,
    Pricelist,
    DealgoodsSummary,
  },

  props: ['deal'],

  created() {
    if (isEditable(this.deal)) {
      this.fetch_seller_goods()
      saveOriginalDeal(this.deal)
    }

    Bus.$on('add-usergood-to-deal', this.add_usergood_to_deal)
  },

  beforeDestroy() {
    Bus.$off('add-usergood-to-deal', this.add_usergood_to_deal)
  },

  data() {
    return {
      showDetailsTools: false,
      goods: [],
      editGoods: false,
      editDetails: false,
      showPricelist: false,
      zoomImg: null,
    }
  },

  watch: {
    'deal.seller'() {
      this.fetch_seller_goods()
    },
  },

  computed: {
    log() {return this.$store.state.loggedInUser},
    editable() {return isEditable(this.deal)},
    hasDetails() {return hasDetails(this.deal)},
    hasGoods() {return hasGoods(this.deal)},
    dirty() {
      if (!this.editable) return false;
      return isDirty2(this.deal)
    },
    im_creator() {return this.log == this.deal.creator},
    im_seller() {return this.log == this.deal.seller},
    date() {return dealDate2(this.deal)},
    colors() {return dealColors(this.deal)},
    short() {return short(this.deal)},
    // goodsSummary() {return goodsSummary(this.$store, this.deal.dealgoods)},
  },

  methods: {
    fetch_seller_goods() {
      this.goods.splice(0)
      if (!this.deal.seller) {return}
      this.$store.dispatch('get_user_goods', this.deal.seller.id).then(usergoods => {
        const goods = clone(usergoods)
        goods.forEach(good => {
          good.orderQ = 0
        })
        this.goods = goods
      })
    },

    updateOriginal() {
      updateOriginal(this.deal, this)
    },

    changeDays() {
      notie.input({
        text: 'Укажите срок действия соглашения (в днях)',
        placeholder: this.deal.days,
        allowed: new RegExp('[^0-9]', 'g'),
        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text:'Количество дней должно быть положительным.'})
            return
          }
          this.deal.days = value
        },
        submitText: 'Готово',
        cancelText: 'Отменить'
      })
    },

    save_deal() {
      if (!isValid(this.deal)) {return}

      const deal = getPayload(this.deal)
      let new_deal_id = false

      if (deal.state=='new'){
        new_deal_id = deal.id
      }

      this.$store.dispatch('save_deal', deal).then(data=>{
        if (!data.errors.length){
          if (new_deal_id===false){
            for (let p in data.deal){
              // if (p=='id') continue
              if (this.deal[p]!=data.deal[p])
                this.deal[p] = data.deal[p]
            }
          }
          this.updateOriginal()
        }
      })
    },

    delete_deal() {
      this.$store.dispatch('delete_deal', this.deal)
        .then(()=>this.$emit('delete'))
    },

    send_offer() {
      if (!isValid(this.deal)) {return}

      const deal = getPayload(this.deal)
      this.$store.dispatch('offer_deal', {deal, dirty: this.dirty}).then(data=>{
        if (!data.errors.length){
          delete this.deal.original
          for (let p in data.deal){
            if (this.deal[p] != data.deal[p]){
              this.deal[p] = data.deal[p]
            }
          }
        }
      })
    },

    cancel_offer() {
      this.$store.dispatch('cancel_deal', this.deal.id).then(data=>{
        notie.alert({text:'Предложение отменено.'})
        // updateOriginal(this.deal)
        this.updateOriginal()
      })
    },

    reject_offer() {
      this.$store.dispatch('reject_deal', this.deal.id).then(data=>{
        notie.alert({text: 'Предложение отклонено.'})
        this.$emit('delete')
      })
    },

    accept_offer() {
      this.$store.dispatch('accept_deal', this.deal.id).then(data=>{
        notie.alert({text:'Предложение принято!'})
      })
    },

    request_extend() {
      notie.input({

        text: 'На сколько дней запросить продление?',
        placeholder: '7',
        allowed: new RegExp('[^0-9]', 'g'),

        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text:'Количество дней должно быть положительным.'})
            return
          }
          this.$store.dispatch(
            'request_deal_extend',
            {days: value, dealId: this.deal.id}
          ).then(data=>{
            notie.alert({text:'Запрос о продлении отправлен.'})
          })
        },
        submitText: 'Отправить запрос',
        cancelText: 'Отменить'

      })
    },

    cancel_request() {
      this.$store.dispatch('cancel_extend_request', this.deal.id).then(data=>{
        notie.alert({text:'Запрос о продлении отменен.'})
      })
    },

    extend_deal() {
      notie.input({

        text: 'На сколько дней продлить сделку?',
        placeholder: this.deal.extend_request || '7',
        allowed: new RegExp('[^0-9]', 'g'),
        submitText: 'Продлить',
        cancelText: 'Отменить',

        submitCallback: value => {
          value = Number(value)
          if (value <= 0){
            notie.alert({type:'warning', text: 'Количество дней должно быть положительным.'})
            return
          }
          this.$store.dispatch(
            'extend_deal',
            {days: value, dealId: this.deal.id}
          ).then(data=>{
            notie.alert({text: 'Сделка продлена.'})
          })
        }

      })
    },

    close_deal() {
      this.$store.dispatch('close_deal', this.deal.id).then(data=>{
        notie.alert({text:'Сделка закрыта.'})
      })
    },

    swap_sides() {
      switchSides(this.deal, this.log)
    },

    add_usergood_to_deal(usergood) {
      console.log('add_usergood_to_deal context', this)
      const good = this.$store.getters.goodByName(usergood.name)

      const dealgood = {
        id: null,
        price: usergood.price,
        q: 1,
        usergoodId: usergood.id,
      }

      if (good) {
        dealgood.goodId = good.id
      } else {
        dealgood.goodName = usergood.name
      }

      if (this.deal.dealgoods.length == 1) {
        unsetNoGoodsDeal(this.deal)
      }

      this.deal.dealgoods.push(dealgood)
    },

    loadImages(e) {
      if (!(e.target.files && e.target.files.length)) {return}

      Array.from(e.target.files).slice(0, 4 - this.deal.imgs.length).forEach(file =>
      {
        const newId = 'newImgId:' + newImgsCount++
        // this.deal.imgs.push({id:newId, file})
        loadImgURL(file).then(data => {
          this.deal.imgs.push({id:newId, url:data, file})
        })
      })
      // {
      //   const newId = 'newImgId:' + newImgsCount++
      //   loadAndCropSquare(file).then(data => {
      //     this.deal.imgs.push({id:newId, url:data})
      //   })
      // })
    },

    delImg(img) {
      this.deal.imgs = this.deal.imgs.filter(i => i != img)
    },
  }
}
</script>









<style lang="scss" scoped>
.opendeal {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-bottom: 40px;
  font-size: 2em;

  &__header {
    text-align: left;
    font-weight: bold;
    color:rgba(0,0,0,.4);
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-top: .5em;
    padding-left: .5em;
    padding-right: .5em;
  }

  &__arrow-left {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-left: 8px solid skyblue;
    border-bottom: 8px solid skyblue;
    margin: 8px;
    transform: rotateZ(45deg);
  }

  &__arrow-right {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-left: 8px solid skyblue;
    border-bottom: 8px solid skyblue;
    margin: 8px;
    transform: rotateZ(-135deg);
  }

  &__deal {
    border-radius: 6px;
    overflow:hidden;
    background-color: skyblue;
    box-shadow: 0px 1px 5px rgba(0,0,0,.2);
    // padding: 5px;
    padding: 0;
    flex-grow: 1;
    // height: 100px;
  }

  &__goods-summary {
    text-align: left;
    padding: 0;
  }

  &__field--editable {
    position: relative;
    &::after {
      content: "";
      // height: 1em;
      width: calc(1em + 3px);
      height: calc(1em + 3px);
      position: absolute;
      right: 0;
      top: 0;
      // background: url('/static/img/edit.svg') no-repeat 50px 50px;
      background-size: 1em 1em;
      background-repeat: no-repeat;
      background-image: url('/static/img/edit.svg');
      background-position: center;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,.2);
    margin-top: .5em;

    .sep {
      flex-grow: 1;
    }

    img.button {
      padding: .5em;
      cursor: pointer;
      user-select: none;
      height: 1em;
    }


    a.button {
      color: black;
      background-color: transparent;
      font-weight: 600;
      padding: .5em;
      cursor: pointer;
      user-select: none;
      text-transform: uppercase;

      &--red {
        color: brown;
      }

      &--blue {
        color: mediumblue;
      }

      &--green {
        color: green;
      }

      &:hover {
        text-decoration: underline;
      }
    }

  }

  .images {
    display: flex;

    .image {
      position: relative;

      &__img {
        height: 150px;
      }

      &__del {
        &::after {
          content: "\2715";
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          right: 0;
          line-height: 20px;
          font-size: 20px;
          text-align: center;
          color: crimson;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
</style>
