<template lang="pug">
	.scorerow(style='z-index:200;')
		HalfCell(
			style="z-index:3;transform:none;"
			:style="{background:bgc(3)}"
			@click='go(3)'
			) {{score(3)}}
		HalfCell(
			style="z-index:2;transform:none;"
			:style="{background:bgc(2)}"
			@click='go(2)'
			) {{score(2)}}
		HalfCell(
			style="z-index:1;transform:none;"
			:style="{background:bgc(1)}"
			@click='go(1)'
			) {{score(1)}}
</template>




<script>
import {mapGetters} from 'vuex'
import HalfCell from '@/components/HalfCell.vue'

export default {
	name: 'Score',
	components: {HalfCell},
	computed: {
		...mapGetters({user:'currentUser'}),

	},
	methods: {
		score(i){
			if (this.user.score)
				return this.user.score[i-1].length * i
			return 0
		},
		go(group){
			let path = '/'
			if (this.user){
				if (this.user.slug)
					path = `/${this.user.slug}/score/${group}`
				else
					path = `/id${this.user.id}/score/${group}`
			}
			this.$router.push(path)
		},
		bgc(group){
			if (['score', 'other_score'].includes(this.$route.name) && this.$route.params.group==group)
				return 'gold'
			return ['gray', 'darkgray', 'lightgray'][group-1]
		}
	}
}
</script>




<style scoped>
.scorerow {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 0;
	text-align: right;
	top: 0;
	filter: drop-shadow(0 0 4px rgba(0,0,0,.5));
}
/* .scorerow::after {
	position: absolute;
	font-size: 1rem;
	content: 'доверители';
} */
</style>
