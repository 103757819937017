<template lang="pug">
  .cell(:class='[cls]')
    .cell-content(
      @click.stop='click'
      :style="{'background-color':bgc}")
      .cell-user(v-if='user')
        .cell-img(:style="{'background-image':'url('+img()+')'}")
        .cell-name.f-16 {{user.lastName || user.userid}}
        .cell-score.f-22(v-if='!hidescore') {{score()}}
</template>




<script>
import {mapState} from 'vuex'

import {
  mapCircleFromN,
  inRange,
  inMapOrBag
} from '../funcs'

// var touchTrg, touchData, lastTap, tapCallback = () => console.log('Cell DblClick')

// function fingerDown(e){
//   // touchDragging = true
//   touchData = {
//     time: e.timeStamp,
//     x: e.changedTouches[0].pageX,
//     y: e.changedTouches[0].pageY
//   }
// }
// function fingerUp(e){
//   const dist = Math.hypot(
//     e.changedTouches[0].pageX-touchData.x,
//     e.changedTouches[0].pageY-touchData.y
//   )
//   if (dist*(e.timeStamp-touchData.time)<1000){
//     const tap = Date.now()
//     if (lastTap && tap-lastTap<250){
//       tapCallback()
//     } else {
//       // console.log('Cell tap')
//       lastTap = tap
//     }
//   } else {
//     // console.log('Cell no tap')
//   }
// }

export default {
  props: ['n', 'u', 'hidescore'],

  data(){
    const circle = mapCircleFromN(this.n)
    const cls = inRange(1,circle,3) ? 'circle-'+circle : ''
    return {
      selected: false,
      circle,
      cls
    }
  },

  // mounted(){
  //   touchTrg = this.$el.querySelector('.cell-content')
  //   touchTrg.addEventListener('touchstart', fingerDown)
  //   touchTrg.addEventListener('touchend', fingerUp)

  //   console.log('cell mounted u:', this.u, 'n:', this.n)
  //   tapCallback = () => {
  //     console.log(this.user)
  //   }
  // },

  // updated(){
  //   console.log('cell updated u:', this.u, 'n:', this.n)
  //   tapCallback = () => {
  //     console.log(this.user)
  //   }
  // },

  // beforeDestroy(){
  //   touchTrg.removeEventListener('touchstart', fingerDown)
  //   touchTrg.removeEventListener('touchend', fingerUp)
  // },

  computed: {
    ...mapState({log:'loggedInUser',map_edit_mode:'map_edit_mode'}),

    user(){
      if (this.u) return this.u
      if (this.n==0) return this.$store.getters.currentUser
      else if (this.n>0) return this.$store.getters.userById(this.$store.getters.currentMap[this.n-1])
    },

    bgc(){
      //// selected
      if (this.selected) return 'lightblue'

      //// has outdated deals
      if (this.user && this.user.hasOutdated){
        return '#f24537'
      }

      // const circle = mapCircleFromN(this.n)
      const circle = this.circle

      switch (circle){
        case 3: return 'gray';
        case 2: return 'darkgray';
        case 1: return 'lightgray';
        case 0:
        case -1: return 'white';
        case -2:
          if (this.log){ //if user isn't logged in, then show green cell
            const n = inMapOrBag(this.log, this.user)
            const _circle = mapCircleFromN(n)
            switch (_circle){
              case 3: return 'gray';
              case 2: return 'darkgray';
              case 1: return 'lightgray';
              case 0: return 'gold';
              case -1: return 'white';
              case -2: return 'DarkSeaGreen'
            }
          }
        case -3: return 'dimgray'; // blacklist
      }

      return 'DarkSeaGreen'
    }
  },

  methods: {
    click(){
      //if (this.n==-2) return; // это значит мы не в круге доверия и не в bag, а в каком-то списке или сделке или профиле
      // this.n >= 0 - Круг доверия
      // this.n == -1 - Bag
      // this.n == -2 - Список
      // this.n - позиция клетки в круге доверия
      if (this.map_edit_mode){
        this.$store.dispatch('cell_click', this) // перестановка
      } else if (this.user) {
        this.go() // переход
      }
    },

    score(){
      const blist_count = this.$store.state.users.filter(u=>u.blist==this.user.id).length
      if (this.user && this.user.score){
        return this.user.score.reduce((acc,cur,idx)=>{
          return acc + cur.length * (idx + 1)
        }, 0) - 6 * blist_count
      }
      return 0 - 6 * blist_count
    },

    go(){
       //console.log('log==this.user', this.$store.state.loggedInUser==this.user)
      if (!this.user) return
      let path
      if (this.log && this.user.userid == this.log.userid){ //if user logged in and it's his map
        if (this.n==0){
          // path = {name:'profile'}
          if (this.user.slug) path = `/${this.user.slug}/profile`
          else path = `/id${this.user.id}/profile`
        }else{
          path = {name:'home'}
        }
      }else if (this.n==0) { //if click on user in his map
        if (!this.user.id) return
        if (this.user.slug){
          path = {name:'other_profile', params:{slug:this.user.slug}}
        } else {
          path = {name:'profile_by_id', params:{id:'id'+this.user.id}}
        }
      } else {
        if (this.user.slug){
           path = {name:'map', params:{slug:this.user.slug}}
          //path = '/'+this.user.slug
        } else {
           path = {name:'map_by_id', params:{id:'id'+this.user.id}}
          //path = '/id'+this.user.id
        }
      }
        this.$router.push(path)
    },

    img(){
      if (this.user && this.user.img) return this.user.img
      return '/static/img/avatar-placeholder.png'
    }
  }
}
</script>




<style scoped>
  .cell {
    display: inline-block;
    position: relative;
    -webkit-clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    background-color: darkgray;
    cursor: pointer;
    user-select: none;
  }

  .map-content .row .cell {
    margin: 0 -2.11%;
    width: 17.9%;
  }

  .userrow .cell {
    /* margin: 0; */
    width: 18.1%;
  }

  .userrow2 .cell,
  .userrow-half .cell,
  .dealrow .cell {
    width: 37.4%;
  }

  .cell:before {
    content: '';
    display: block;
    padding-top: 86.6%;
  }

  .cell-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .cell-user {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .cell-img {
    width: 51%;
    height: 59%;
    border-radius: 10%;
    /* margin: 2% auto 0 auto; */
    margin-top: 2%;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
  }

  .cell-name {
    font-weight: bold;
    font-weight: 500;
    font-family: arial;
    font-family: 'Roboto', sans-serif;
    /* font-size: midWidth/50 + 'px' == 16px */
    /* font-size: 1em; */
    color: black;
  }

  .cell-score {
    font-weight: bold;
    /* font-size: 1.375em; */
    /* line-height: 23px; */
    /* line-height: 1em; */
    font-family: arial;
    color: black;
  }
</style>

<style>

  .cell-shadow {
    display: inline-block;
    filter: drop-shadow(0 0 4px rgba(0,0,0,.5));
    width: 17.9%;
    margin: 0 -2.11%;
  }

  .cell-shadow .cell {
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
  }

  .cell-shadow:first-child {
    margin-left: 0;
  }

  .cell-shadow:last-child {
    margin-right: 0;
  }

  .dummy-cell {
    display: inline-block;
  }

  .map .cell:nth-child(odd){
    transform: translateY(50.7%);
  }

  .bag .cell {
    background-color: white;
  }
  /* .bag .cell:first-child {
    margin-left: 0;
  } */
</style>

