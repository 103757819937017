// import { logicalExpression } from "babel-types";
import {
	ensureHtml,
	stripHtml,
	clone,
} from './funcs'
import store from './store'

const monthMap = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря'
]

const STATE = Object.freeze({
	new: 'new',
	draft: 'draft',
	offered: 'offered',
	actual: 'actual',
	outdated: 'outdated',
	fulfilled: 'fulfilled',
})

export const stateColors = {
	draft: {
		title:'#1abc9c', body:'#dbfaf4', font:'white'
	},
	new: {
		title:'#1abc9c', body:'#dbfaf4', font:'white'
	},
	offered: {
		title:'#f0b37e', body:'#ffedcc', font:'white'
	},
	actual: {
		title:'#6ab0de', body:'#e7f2fa', font:'white'
	},
	outdated: {
		title:'#f24537', body:'#f5978e', font:'white'
	},
	fulfilled: {
		title:'#888', body:'lightgray', font:'#ddd'
	},
	any: {
		title:'#036db7', body: '#0488e7', font:'white'
	},
	important: {
		title:'#036db7', body: '#0488e7', font:'white'
	}
}
export function stateColor(state){
	return stateColors[state]
}

export function dealColors(deal) {
	return stateColors[dealState(deal)]
}

//// total is dealgoods prices x quantity
export function total(deal){
	return deal.dealgoods.reduce((m,dg)=>m+Number(dg.q)*Number(dg.price),0)
}

//// balance is total - paybacks
export function balance(deal){
	if (deal.paybacks)
		return deal.paybacks.reduce((m, pb)=>m+Number(pb.value),0) - total(deal)
	return -total(deal)
}

//// balance if a bunch of deals that are
//// filtered out of non-balance-deals (like drafts)
export function dealsBalance(deals, logId){
	return deals.reduce((m, dl) => {
		if (nonBalanceDeals.includes(dl.state))
			return m
		const sign = logId==dl.sellerId ? -1 : 1
		return m + sign * balance(dl)
	}, 0)
}

export const nonBalanceDeals = Object.freeze([
	'new', 'draft', 'fulfilled', 'offered'
])

const DAY_MS = 86400000 // 24 hours in miliseconds
export function dealDate(deal){
	return deal.accepted || deal.created
}
export function dealDate2(deal) {
	const dateString = dealDate(deal)

	const [year, month, date] = dateString.match(/(\d{4})-(\d{2})-(\d{2})/).slice(1).map(i=>parseInt(i))
	const curYear = (new Date()).getFullYear()

	return `${date} ${monthMap[month-1]}` + (year!=curYear?' '+year:'')
}
export function daysLeft(deal){

	if (deal.accepted) {

		const accepted = new Date(deal.accepted)

		if (deal.fulfilled) {
			const fulfilled = new Date(deal.fulfilled)
			return Math.floor(
				(accepted - fulfilled)/DAY_MS + deal.days
			)
		}

		const today = new Date()

		return Math.floor(
			deal.days - (today - accepted)/DAY_MS
		)

	}

	return deal.days
}

export const State = Object.freeze({
	new: 0,
	draft: 1,
	offered: 2,
	actual: 3,
	outdated: 4,
	fulfilled: 5,
	"0": 'new',
	"1": 'draft',
	"2": 'offered',
	"3": 'actual',
	"4": 'outdated',
	"5": 'fulfilled'
})

export function stateRu(state){
	if (typeof state == 'string')
		state = State[state]
	return {
		"0": 'новая',
		"1": 'черновик',
		"2": 'предложение',
		"3": 'действующая',
		"4": 'просроченная',
		"5": 'закрытая',
	}[state]
}

export function dealState(deal){
	if (deal.fulfilled) return 'fulfilled'
	if (deal.accepted)
		if (daysLeft(deal)<0) return 'outdated'
		else return 'actual'
	if (deal.offered) return 'offered'
	if (deal.created && !deal.id) return 'new'
	if (deal.created) return 'draft'
}

function ensureCreatorId(deal){
	if (!deal.creatorId)
		deal.creatorId = deal.creator_is_seller
			? deal.sellerId
			: deal.buyerId
}

export function ensureCounterpartId(deal, logId){
	if (deal.counterpartId) return
	if (logId==deal.sellerId)
		if (deal.buyerId)
			deal.counterpartId = deal.buyerId
		else
			deal.counterpartId = null
	else if (logId==deal.buyerId)
		if (deal.sellerId)
			deal.counterpartId = deal.sellerId
		else
			deal.counterpartId = null
	else
		deal.counterpartId = null
}

export const isImportant = (deal, myId) => {
	ensureCreatorId(deal)
	if (deal.state=='offered'&&myId!=deal.creatorId) return true
	if (deal.state=='actual'&&deal.extend_request>0&&myId==deal.sellerId) return true
	if (deal.state=='actual'&&daysLeft(deal)/deal.days<0.1) return true
	if (deal.state=='outdated') return true
}
export function isOutdated(deal){
	return deal.state=='outdated'||(deal.state=='actual'&&(daysLeft(deal)>deal.days))
}
export const isOffered = (deal, myId) => {
	ensureCreatorId(deal)
	return deal.state=='offered'&&myId!=deal.creatorId
}
export const isOfferedByMe = (deal, myId) => {
	ensureCreatorId(deal)
	return deal.state=='offered'&&myId==deal.creatorId
}


export const isActual = (deal) => ['actual', 'outdated'].includes(deal.state)
export const isDraft = (deal) => deal.state == 'draft'
export const isEditable = (deal) => ['draft', 'new'].includes(deal.state)

export const isNonBalance = deal => nonBalanceDeals.includes(deal.state)

export const hasDetails = deal => {
	if (!deal.details) return false
	if (deal.details.match(/^<\w+><\/\w+>$/i)) return false
	if (deal.details.length) return true
	return false
}
export const isNoGoodsDeal = deal => {
	if (deal.dealgoods.length==1 && deal.dealgoods[0].goodId==1 && deal.dealgoods[0].q==1) return true
	return false
}
export const hasGoods = deal => !isNoGoodsDeal(deal)
export const isDirty = vm => {
	if (!vm.editable) return false
	return isDirty2(vm.deal)
}
export function isDirty2 (deal) {
	if (!deal.original) return false

	if (deal.state=='new') return true

	if (deal.imgs.length != deal.original.imgs.length) return true

	if (deal.imgs.some(img => {
		const img_orig = deal.original.imgs.find(io => io.id == img.id)
		if (!img_orig) return true
		return img.url != img_orig.url
	})) return true

	if ([
		'days',
		'sellerId',
		'buyerId',
		'creator_is_seller',
		'details'
	].some(
		k=>deal[k]!=deal.original[k]
	)){
		console.log('dirty from k fields')
		return true
	}

	if (deal.dealgoods.length != deal.original.dealgoods.length){
		console.log('dirty from dealgoods length')
		return true
	}

	if (deal.dealgoods.some(
		dg=>{
			const orig_dg =deal.original.dealgoods.find(odg=>odg.id==dg.id)

			if (!orig_dg) return true

			if (!(dg.goodId==orig_dg.goodId||dg.goodName==orig_dg.goodName)) return true

			if (dg.price!=orig_dg.price) return true

			if (dg.q!=orig_dg.q) return true
		}
	)){
		console.log('dirty from dealgoods')
		return true
	}

	return false
}
export function isValid (deal) {
	if (!(deal.sellerId && deal.buyerId)) {
		return false
	}

	if (!deal.dealgoods.length) {
		return false
	}

	if (isNoGoodsDeal(deal) && !hasDetails(deal)) {
		return false
	}

	return true
}
export function sortBy(prop){
	switch(prop){
		case 'date':
			return (a,b)=>(new Date(dealDate(a.deal)))-(new Date(dealDate(b.deal)))
		case 'left':
			return (a,b)=>daysLeft(a.deal)-daysLeft(b.deal)
		case 'state':
			return (a,b)=>State[dealState(a.deal)]-State[dealState(b.deal)]
			return (a,b)=>State[a.deal.state]-State[b.deal.state]
		case 'total':
			return (a,b)=>total(a.deal)-total(b.deal)
		case 'balance':
			return (a,b)=>balance(a.deal)-balance(b.deal)
	}
}
export function sortDealsBy(prop){
	switch(prop){
		case 'date':
			return (a,b)=>(new Date(dealDate(a)))-(new Date(dealDate(b)))
		case 'left':
			return (a,b)=>daysLeft(a)-daysLeft(b)
		case 'state':
			return (a,b)=>State[dealState(a)]-State[dealState(b)]
		case 'total':
			return (a,b)=>total(a)-total(b)
		case 'balance':
			return (a,b)=>balance(a)-balance(b)
	}
}
export function getPayload(deal) {
	return clone(deal, [
		'id',
		'sellerId',
		'buyerId',
		'creator_is_seller',
		'state',
		'created',
		'dealgoods',
		'details',
		'days',
		'imgs',
	])
}


// state: is for colors in stateColors {}
export const tabs = Object.freeze([
	Object.freeze({
		name: 'important',
		test: isImportant,
		ru: 'Важные',
		state: 'important'}),

	Object.freeze({
		name: 'outdated',
		test: isOutdated,
		ru: 'Просроченные',
		state: 'outdated'}),

	Object.freeze({
		name: 'offers',
		test: isOffered,
		ru: 'Предложения',
		state: 'offered'}),

	Object.freeze({
		name: 'myoffers',
		test: isOfferedByMe,
		ru: 'Исходящие',
		state: 'offered'}),

	Object.freeze({
		name: 'actual',
		test: isActual,
		ru: 'Действующие',
		state: 'actual'}),

	Object.freeze({
		name: 'drafts',
		test: isDraft,
		ru: 'Черновики',
		state: 'draft'}),

	Object.freeze({
		name: 'all',
		test: ()=>true,
		ru: 'Все',
		state: 'any'}),

	Object.freeze({
		name: 'new',
		test: (deal)=>deal.state=='new',
		ru: 'Новые',
		state: 'new'}),

	Object.freeze({
		name: 'fulfilled',
		test: (deal)=>deal.state=='fulfilled',
		ru: 'Закрытые',
		state: 'fulfilled'
	})
])

export function goodName(store, dealgood){
	if (dealgood.goodId)
		return store.getters.goodById(dealgood.goodId).name
	if (dealgood.goodName)
		return dealgood.goodName
	return 'NoName Good'
}
export function goodsSummary(store, dealgoods){
	return dealgoods.map(dg=>goodName(store, dg)).join(',')
}
export function short(deal){
	if (hasDetails(deal)) return stripHtml(deal.details)
	if (isNoGoodsDeal(deal)) return '<НЕТ ОПИСАНИЯ ИЛИ ТОВАРА>'
	// if (deal.id==62) {
	// 	console.log('SHORT', deal)
	// }
	return deal.dealgoods
		.map(dg=>goodName(store, dg))
		.join(',')

	// return stripHtml(deal.details) || deal.dealgoods.map(dg=>goodName(store, dg)).join(',')
}
export function setupEditableDealVm(vm){
	const deal = vm.deal
	//* add input fields
	vm.addDealgoodName = ''
	vm.addDealgoodPrice = 0
	vm.addDealgoodQ = 0

	//* save original deal
	saveOriginalDeal(deal)
}
export function saveOriginalDeal(deal) {
	if (!deal.original) {
		//* fix deal.details plain text format to html
		deal.details = ensureHtml(deal.details)

		//* save original deal to check if there were changes
		deal.original = Object.freeze(clone(deal))
	}
}
export function updateOriginal(deal, vm) {
	delete deal.original
	vm.$set(deal, 'original', Object.freeze(clone(deal)))
}

export function setNoGoodsDeal(deal) {
	const _total = total(deal)
	deal.dealgoods.splice(0)
	deal.dealgoods.push({
		id: null,
		goodId: 1,
		q: 1,
		price: _total,
	})
}

export function unsetNoGoodsDeal(deal) {
	const idx = deal.dealgoods.findIndex(dg=>dg.goodId==1&&dg.q==1)
	if (idx>-1){
		deal.dealgoods.splice(idx,1)
	}
}

export function switchSides(deal, loggedUser) {
	const buyer = deal.buyer
	deal.buyer = deal.seller
	deal.seller = buyer
	deal.buyerId = deal.buyer.id
	deal.sellerId = deal.seller.id
	deal.creator_is_seller = !deal.creator_is_seller

	// deal.creator_is_seller = !deal.creator_is_seller
	// let sellerId = deal.sellerId
	// deal.sellerId = deal.buyerId
	// deal.buyerId = sellerId
	// let seller = deal.seller
	// deal.seller = deal.buyer
	// deal.buyer = seller

	if (hasGoods(deal) && ((deal.counterpart && deal.counterpart.goods && deal.counterpart.goods.length>0) || (loggedUser && loggedUser.goods && loggedUser.goods.length>0))) {
		setNoGoodsDeal(deal)
		// if (!deal.creator_is_seller && deal.counterpart && deal.counterpart.goods && deal.counterpart.goods.length > 0) {
		// 	setNoGoodsDeal(deal)
		// } else if (deal.creator_is_seller && loggedUser && loggedUser.goods && loggedUser.goods.length > 0) {
		// 	setNoGoodsDeal(deal)
		// }
	}
}
