<template lang="pug">
	.cropper.f-16
		.wrapper
			.modal
				.close(@click='$emit("close")') ✕
				.img
					img#crop-image
				.tal
					#preview-crop.preview
					button.btn(@click='$emit("close")') Отмена
					button.btn(@click='$emit("done")') Готово
</template>

<script>
export default {
	name: 'ImgCropper',
}
</script>


<style scoped>
.cropper {
	position: fixed;
	top: 0; left: 0;
	right: 0; bottom: 0;
	overflow-y: auto;
	background-color: rgba(0,0,0,.5);
	z-index: 1000;
}
.close {
	position: absolute;
	top: -1.2em;
	right: -1em;
	color: darkgray;
	font-size: 2em;
	font-weight: bold;
	user-select: none;
	cursor: pointer;
}
.close:hover {
	color: lightgray;
}
.wrapper {
	margin: 0 auto;
	text-align: center;
}
.modal {
	background-color: white;
	padding: 2em;
	border-radius: 2em;
	margin: 4em 0 2em 0;
	max-width: 100%;
	box-sizing: border-box;
	width: 60%;
	max-height: 60rem;
	display: inline-block;
	position: relative;
}
.cropper .img {
	max-width: 100%;
	text-align: left;
}
.cropper .img img {
	width: 100%;
	max-height: 50rem;
}
.cropper .preview {
	height: 100px;
	width: 100px;
	display: inline-block;
	overflow: hidden;
}
</style>
