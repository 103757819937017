<template lang="pug">
  .scrollbox(style='padding-top:2%;scroll-behavior: smooth;')

    DealsView(v-if='dealsViewVersion==1')
    DealsView2(v-else-if='dealsViewVersion==2')
    DealsView3(v-else-if='dealsViewVersion==3')
    DealsView4(v-else-if='dealsViewVersion==4')

    //- deals-view switcher
    .prefs(
      @click='showmenu=!showmenu'
      @blur='showmenu=false'
      tabindex='0'
      style='background-image:url(/static/img/cogwheel.svg)'
    )
      Hovermenu(
        v-show='showmenu'
        style='right:0.2cm;top:120%;z-index:1000;'
      )
        .menuitem(
          @click='setViewVersion(1)'
          :class='{active: dealsViewVersion==1}'
        ) вид 1
        .menuitem(
          @click='setViewVersion(2)'
          :class='{active: dealsViewVersion==2}'
        ) вид 2
        .menuitem(
          @click='setViewVersion(3)'
          :class='{active: dealsViewVersion==3}'
        ) вид 3
        .menuitem(
          @click='setViewVersion(4)'
          :class='{active: dealsViewVersion==4}'
        ) вид 4

</template>








<script>
import DealsView from '@/views/DealsView.vue'
import DealsView2 from '@/views/DealsView2.vue'
import DealsView3 from '@/views/DealsView3.vue'
import DealsView4 from '@/views/DealsView4.vue'
import Hovermenu from '@/components/Hovermenu.vue'

import Bus from '@/bus.js'
// Bus.$on('open_deal', (deal)=>{ // gets called
//   console.log('Bus.$on!', deal)
// })

var thisVmElement = null
var thisVm = null
// var Scroll = new SmoothScroll()

const vm = {
  name: 'BalanceView',
  components: {
    DealsView,
    DealsView2,
    DealsView3,
    DealsView4,
    Hovermenu,
  },

  beforeCreate(){
    this.$store.dispatch('load_deals_details').then(res=>{
      // console.log('load_deals_details:done')
    })

    // const onOpenDeal = deal => {
    //   console.log('BalanceView.onOpenDeal')
    //   this.open_deal(deal)
    // }

    // this.onOpenDeal = onOpenDeal

    // console.log('setting up Bus.$on')
    // Bus.$on('open_deal', onOpenDeal)
  },

  mounted(){
    Bus.$emit('open_deal', this)
    thisVmElement = this.$el
    thisVm = this
  },

  beforeDestroy(){
    thisVmElement = null
    thisVm = null
  },

  data(){
    return {
      showmenu: false,
      dealsViewVersion: window.localStorage.preferences_dealsViewVersion || 1
    }
  },

  methods: {
    setViewVersion(ver){
      this.dealsViewVersion = ver
      window.localStorage.preferences_dealsViewVersion = ver
    },

    open_deal(deal){
      console.log('BalanceView.open_deal')
      this.$children[1].open_deal(deal)
    }
  }
}

Bus.$on('scrollTo', el => {

  // thisVm.$nextTick(()=>{
    thisVmElement.scrollTop = el.offsetTop - thisVmElement.clientHeight/2 + el.clientHeight
  // })

})

export default vm

</script>








<style scoped>
.prefs {
  user-select: none;
  height: .5cm;
  width: .5cm;
  /* margin-left: 2%; */
  /* position: relative; */
  position: absolute;
  right:.1cm;
  top:.1cm;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.prefs::after {
  position:absolute;
  content:'';
  background:#eee;
  opacity: .5;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}
.prefs:hover::after {
  display:none;
}
.prefs:focus {
  outline: none;
}
</style>
