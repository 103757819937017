<template>
  <Modal>
    <div slot="header">
      <span>Фильтр</span>
      <input type="text" v-model='filter' style='max-width:100%;'>
      <div v-if='filter.length' @click='filter=""' class="btn">Сбросить</div>
    </div>

    <div slot="body">
      <ul class="pricelist">
        <UserGood
          v-for='item in filteredGoods'
          :key='item.id'
          :item='item'
          :editable='editable'
          :addGoodButton='addGoodButton'
          @delete='delGood'
          @close-pricelist='$emit("close")'
        ></UserGood>
      </ul>
    </div>

    <div slot='footer' style='display:flex;'>
      <div v-if='pricelistChanged'>
        <div @click='saveGoods' class="btn">Сохранить</div>
        <div @click='resetGoods' class="btn">Отменить изменения</div>
      </div>

      <span style='flex:1;'>
        <div v-if='editable' @click='addGood' class="btn">+</div>
        <div @click='$emit("close")' class="btn">Закрыть</div>
      </span>
    </div>
  </Modal>
</template>




<script>
import Modal from '@/components/Modal2.vue'
import UserGood from '@/components/UserGood.vue'
import {mapState} from 'vuex'
import {clone, deepEq} from '@/funcs'


let newGoodId = 0


export default {
  components: {Modal, UserGood},

  // props: [
  //   'user',
  // ],

  props: {
    user: Object,
    addGoodButton: {type: Boolean, default: false},
  },

  data() {
    return {
      filter: "",
      goods: clone(this.user.goods),
    }
  },

  computed: {
    filteredGoods() {
      return this.filter
        ? this.goods.filter(
            i=>i.name.toLowerCase().includes(this.filter.toLowerCase())
          )
        : this.goods
    },

    pricelistChanged() {
      if (this.goods.length!=this.user.goods.length) return true
      if (this.goods.find(tempgood=>{
        const usergood = this.user.goods.find(ug=>ug.id==tempgood.id)
        if (!usergood) return true
        return !deepEq(tempgood,usergood,null,(a,b,tg,ug,k)=>{
          if (['price','q'].includes(k)){
            return Number(a.toString().replace(',','.'))==Number(b.toString().replace(',','.'))
          }
        })
      })) return true
      return false
    },

    log() {
      return this.$store.state.loggedInUser
    },

    editable() {
      return this.log == this.user
    },
  },

  methods: {
    addGood() {
      while (this.goods.find(tg=>tg.id=='new'+newGoodId)) newGoodId++
      while (this.user.goods.find(ug=>ug.id=='new'+newGoodId)) newGoodId++
      this.goods.push({
        id:'new'+newGoodId,
        imgs:[],
        name:'Новый Товар',
        price: 123,
        q: 10,
        quant: true,
        unit: 'шт'
      })
    },

    delGood(id) {
      this.goods = this.goods.filter(g=>g.id!=id)
    },

    saveGoods() {
      if (!this.editable) return;
      const remove = this.user.goods.filter(ug => !this.goods.find(tg => ug.id == tg.id))

      // filter goods that are either not present in this.user.goods
      // or goods that are different from this.user.goods
      const update = this.goods.filter(tempgood => {
        const usergood = this.user.goods.find(ug => ug.id == tempgood.id)

        // include the tempgood if there is not corresponding usergood from thes.user.goods
        // or if tempgood and usergood are not deeply equal
        return !usergood || !deepEq(usergood, tempgood, null, (a, b, ug, tg, k) => {
          if (['price', 'q'].includes(k))
            return Number(a.toString().replace(',', '.')) == Number(b.toString().replace(',', '.'))
        })
      })

      this.$store.dispatch('save_user_goods', {
        uid: this.user.id,
        goods: {remove, update}
      }).then(resp => {
        this.resetGoods()
      })
    },

    resetGoods() {
      this.goods = clone(this.user.goods)
    },
  }
}
</script>




<style lang="scss" scoped>
.pricelist {
  margin: 0;
  list-style: none;
  padding: 0;
}
</style>
