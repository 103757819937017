<template lang="pug">
  .scrollbox
    UserList(:uidlist='result')
</template>

<script>
import Vue from 'vue'
import UserList from '@/components/UserList.vue'
import {loadJs} from '@/funcs.js'

export default {
  name: 'SearchView',
  components: {UserList},

  created(){
    // console.log('CREATED')
    const users = this.$store.state.users.filter(user=>!user.deleted)
    this.fuse = new Fuse(users, {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 1000,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys:['lastName','firstName','slug','searched'],
      id: 'id'
    })
  },
  // created(){



  //   loadJs('/static/js/fuse.min.js', () => {
  //     let fuse = new window.Fuse(this.$store.state.users, {
  //       shouldSort: true,
  //       threshold: 0.6,
  //       location: 0,
  //       distance: 100,
  //       maxPatternLength: 32,
  //       minMatchCharLength: 1,
  //       keys:['lastName','firstName','userid'],
  //       id: 'id'
  //     })
  //     // this.result = fuse.search(this.$route.params.string)
  //     this.fuse = fuse
  //   }, window.Fuse)
  // },

  // beforeUpdate(){
  //   console.log('before update')
  //   this.search()
  // },

  // updated(){console.log('updated')},

  data(){

    // let result = []
    // let fuse

    // loadJs('/static/js/fuse.min.js', () => {
    //   fuse = new window.Fuse(this.$store.state.users, {
    //     shouldSort: true,
    //     threshold: 0.6,
    //     location: 0,
    //     distance: 100,
    //     maxPatternLength: 32,
    //     minMatchCharLength: 1,
    //     keys:['lastName','firstName','userid'],
    //     id: 'id'
    //   })
    //   // this.result = fuse.search(this.$route.params.string)
    //   Vue.set(this, 'result', fuse.search(this.$route.params.string))
    // }, window.Fuse)

    return {
      // result: [],
      fuse: null
    }
  },

  computed: {
    // searchString(){
    //   console.log('COMPUTE')
    //   return this.$route.params.string
    // },
    result(){
      return this.fuse.search(this.$route.params.string)
    }
  },

  // methods: {
    // search(){
      // let res = this.fuse.search(this.$route.params.string)
      // Vue.set(this, 'result', res)
      // return res
    // }
  // }
}
</script>
