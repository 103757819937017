export function getCssRule(querySelector){
	let rule
	const origin = document.location.origin
	Object.values(document.styleSheets).find(ss=>{
		if (ss.href && ss.href.startsWith(origin)){
			return Object.values(ss.cssRules).find(r=>{
				if (r.selectorText && r.selectorText.startsWith(querySelector)){
					rule = r
					return r
				}
			})
		}
	})
	return rule
}

export function getCssRules(selector) {
	const rules = []
	const origin = document.location.origin
	const skip = ['cropper', 'notie', 'quill', 'chunk-vendors']
	Array.from(document.styleSheets).filter(ss=>ss.href===null||(ss.href.startsWith(origin)&&!skip.includes(ss.href.split('/').slice(-1)[0].split('.')[0]))).forEach(ss=>{
		Array.from(ss.rules || ss.cssRules).forEach(r=>{
			if (r.selectorText&&r.selectorText==selector)
				rules.push(r)
		})
	})
	return rules
}

export function getCssRule2(querySelector){
	for (let styleSheet of document.styleSheets) {
		for (let cssRule of styleSheet.cssRules) {
			if (cssRule.selectorText==querySelector) {
				return cssRule
			}
		}
	}
}

function findCss(url){
	return Object.values(document.styleSheets).find(ss => {
		return ss.href==url
	})
}

export function ensureCss(url){
	if (findCss(url)) return
	const link = document.createElement('link')
	link.rel = 'stylesheet'
	link.href = url
	document.head.appendChild(link)
}

export function getCookie(name){
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length == 2)
		return parts.pop().split(";").shift();
}

export const lim = (min, x, max) => Math.min(Math.max(min, x), max)

export function someName(user){
	if (user === undefined) {return 'user is undefined'}
	return ((user.lastName||'')+' '+(user.firstName||'')).trim()||user.userid
}


export function score(user){
	return user.score ? user.score.reduce((total, level, idx, score)=>total+level.length*(idx+1), 0) : 0
}

// correct object cloning
export function clone(obj, fields) {
	if (null == obj || "object" != typeof obj) return obj;
	if (obj.constructor == File && obj.size)
		return obj
	var copy = obj.constructor();

	if (fields){
		for (let i of fields)
			if (obj.hasOwnProperty(i))
				copy[i] = clone(obj[i])
	} else {
		for (let attr in obj)
			if (obj.hasOwnProperty(attr))
				copy[attr] = clone(obj[attr]);
	}

	return copy;
}

export function everyObj(obj, cb){
	for (let k in obj){
		if (!cb(obj[k], k, obj))
			return false
	}
	return true
}

export function deepEq( x, y, propertyList, pred ) {
  if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

  if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

	if (propertyList){
		for (var p in propertyList){
			if (!x.hasOwnProperty(p)^!y.hasOwnProperty(p)) return false
			// if (!y.hasOwnProperty(p)) return false
			if (x[p]===y[p]) continue
			if (typeof(x[p]!=='object')) return false
			if (!deepEq(x[p], y[p])) return false
		}
	} else {
		var pred_tmp;
		for ( var p in x ) {

			if (pred){
				pred_tmp = pred(x[p],y[p],x,y,p)
				if (pred_tmp===false) return false;
				else if (pred_tmp===true) continue;
			}

			if ( ! x.hasOwnProperty( p ) ) continue;
				// other properties were tested using x.constructor === y.constructor

			if ( ! y.hasOwnProperty( p ) ) return false;
				// allows to compare x[ p ] and y[ p ] when set to undefined

			if ( x[ p ] === y[ p ] ) continue;
				// if they have the same strict value or identity then they are equal

			if ( typeof( x[ p ] ) !== "object" ) return false;
				// Numbers, Strings, Functions, Booleans must be strictly equal

			if ( ! deepEq( x[ p ],  y[ p ] ) ) return false;
				// Objects and Arrays must be tested recursively
		}

		for ( p in y ) {
			if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
				// allows x[ p ] to be set to undefined
		}
	}

  return true;
}

export function deltaObj(a, b, opts={}){
	// returns delta object that is:
	// a + delta = b
	// opts.include::[str] -- list of keys to be included in the resulting diff anyway
	if (a.constructor!==b.constructor) {

		if ([String, Number, Boolean].includes(b.constructor)) {
			return b
		}

		console.log('a.constructor=', a.constructor, a)
		console.log('b.constructor=', b.constructor, b)
		throw 'deltaObj arguments must be of the same type'
		// ??? maybe just return b ???
	}

	// cannot remember what the heck is the next line!!!
	if (deepEq(a,b)) return a.constructor()

	// wtf???
	if (typeof b == 'string') return b;

	// constructing the delta object
	let d = clone(b)
	Object.keys(b).forEach(k=>{
		if (opts.include && opts.include.includes(k)) return;
		if (k in a){
			// compare
			if (deepEq(a[k], b[k]))
				delete d[k]
			else
				d[k] = deltaObj(a[k], b[k])
		} else {
			// do nothing
		}
	})

	// Object.keys(a).forEach(k=>{
	// 	if (!(k in b))
	// 		d[k] = clone(a[k])
	// })

	if (d.constructor == Array) {
		d = d.filter(Boolean)
	}

	return d
}

function emptyArr(arr){
	return Array.isArray(arr)&&!arr.length
}

export function listsEq(a,b){
	if ((emptyArr(a)||a===undefined)&&(emptyArr(b)||b===undefined)) return true
	if (a===undefined||b===undefined) return false
	if (a.length!=b.length) return false
	return a.every((i, idx)=>{
		if (typeof(i)=='object')
			return deepEq(i, b[idx])
		return b.includes(i)
	})
}

export function scoreEq(a,b){
	if (a===undefined&&b===undefined) return true
	if (a===undefined||b===undefined) return false
	if (a.length!=b.length) return false
	return a.every((i,index)=>listsEq(i, b[index]))
}

const telRe = /(\d+)/g
export function normTel(s){
	const m = s.match(telRe)
	if (m) {
		const out = m.join('')
		if (out.length==11 && out.startsWith('7'))
			return '+' + out
		return out
	}
}

export function faviconSrc(url){

	if (url.startsWith('tel:'))
		return "/static/img/254080.svg"

	if (url.startsWith('mailto:'))
		return "/static/img/321817.svg"

	// var domain = url.split('//').slice(-1)[0].split('/').slice(0,1)[0]
	const _domain = domain(url)
	if (_domain=='vk.com')
		return '/static/img/145813.svg'

	if (_domain=='facebook.com')
		return '/static/img/145802.svg'

	return "http://www.google.com/s2/favicons?domain="+_domain
}

export function ensureScheme(u){
	// if (u.startsWith('tel:')) return u
	// if (u.startsWith('mailto:')) return u
	if (validatePhone(u)) return 'tel:'+u
	if (validateEmail(u)) return 'mailto:'+u
	if (u.indexOf(':')>=0) return u
	if (!(u.startsWith('http://')||u.startsWith('https://'))){
		return 'https://' + u
	}
	return u
}

export function validatePhone(phone){
	let norm = normTel(phone)
	if (!norm) return false
	if (norm.length==10) return true
	if (norm.length==11 && norm.startsWith('8')) return true
	if (norm.length==12 && norm.startsWith('+7')) return true
}

export function validateEmail(email){
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	return re.test(email.trim().toLowerCase())
}

export function loadJs2(document, test, url){
	return new Promise((done, fail) => {
		if (test) return done();

		let scripts = document.querySelectorAll('script')
		for (let script of scripts){
			if (script.src && script.src.endsWith(url)){
				return done();
			}
		}

		let script = document.createElement('script')
		script.src = url
		script.type = 'text/javascript'
		script.onload = function(e){
			done()
		}
		document.head.appendChild(script)
	})
}

export function loadJs(url, cb, test){

	if (test) {
		if (cb) cb()
		return
	}

	let scripts = document.querySelectorAll('script')
	for (let script of scripts)
		if (script.src && script.src.endsWith(url)){
			if (cb) cb()
			return
		}

	let script = document.createElement('script')
	script.src = url
	script.type = 'text/javascript' // older, but more support
	// script.type = 'application/javascript' // newer, less support
	script.onload = function(e){
		if (cb) cb()
	}
	document.head.appendChild(script)
}

export function loadJs3(url) {
	return new Promise((done, fail) => {
		if (Array.from(document.querySelectorAll('script')).find(s => s.src.endsWith(url))) return done()
		const script = document.createElement('script')
		script.src = url
		script.type = 'text/javascript'
		script.onload = done
		document.head.appendChild(script)
	})
}

// <orientation value> || -1(not set) || -2(not jpeg)
export function orientationFromBuffer(buffer){
	var view = new DataView(buffer)
	if (view.getUint16(0, false)!=0xFFD8){ // if not jpeg
		return -2
	}
	var length = view.byteLength,
			offset = 2
	while (offset < length){
		var marker = view.getUint16(offset, false)
		offset += 2
		if (marker==0xFFE1){
			if (view.getUint32(offset += 2, false)!=0x45786966){
				return -1 // value not set
			}

			var little = view.getUint16(offset+=6,false)==0x4949
			offset += view.getUint32(offset+4, little)
			var tags = view.getUint16(offset, little)
			offset += 2
			for (var i = 0; i < tags; i++){
				if (view.getUint16(offset+(i*12),little)==0x0112){
					return view.getUint16(offset+(i*12)+8,little)
				}
			}
		} else if ((marker & 0xFF00)!=0xFF00){
			break;
		} else {
			offset += view.getUint16(offset,false)
		}
	}
	return -1 // value not found
}

export function orientationFromBase64(base64data){
	var view = new Base64DataView(base64data)
	if (view.getUint16(0, false)!=0xFFD8){
		return -2
	}
	var length = view.byteLength,
			offset = 2
	while (offset < length){
		var marker = view.getUint16(offset, false)
		offset += 2
		if (marker==0xFFE1){
			if (view.getUint32(offset += 2, false)!=0x45786966){
				return -1
			}

			var little = view.getUint16(offset+=6,false)==0x4949
			offset += view.getUint32(offset+4, little)
			var tag_count = view.getUint16(offset, little)
			// console.log('tags count', tag_count)
			offset += 2
			for (var i = 0; i < tag_count; i++){
				if (view.getUint16(offset+i*12,little)==0x0112){
					// console.log('offset', offset+i*12+8)
					// console.log('byteCache', view.byteCache)
					return view.getUint16(offset+i*12+8,little)
				}
			}
		} else if ((marker & 0xFF00)!=0xFF00){
			break;
		} else {
			offset += view.getUint16(offset,false)
		}
	}
	return -1
}

// given base64 encoded image data and source orientation
// transforms the image to make it oriented properly
// result is given to the callback function
// example: fixOrientation(img.src, 8, data=>{resImg.src=data})
export function fixOrientation(srcBase64, srcOrientation, cb){
	var img = new Image()
	img.onload = function(){
		var width   = img.width,
				height  = img.height,
				canvas  = document.createElement('canvas'),
				context = canvas.getContext('2d')

		// set proper canvas dimensions before transform
		if (4<srcOrientation&&srcOrientation<9){
			canvas.width  = height
			canvas.height = width
		} else {
			canvas.width  = width
			canvas.height = height
		}

		// transform context before drawing image
		switch (srcOrientation){
			case 2: context.transform(-1, 0, 0, 1, width, 0); break;
			case 3: context.transform(-1, 0, 0,-1, width, height); break;
			case 4: context.transform( 1, 0, 0,-1, 0, height); break;
			case 5: context.transform( 0, 1, 1, 0, 0, 0); break;
			case 6: context.transform( 0, 1,-1, 0, height, 0); break;
			case 7: context.transform( 0,-1,-1, 0, height, width); break;
			case 8: context.transform( 0,-1, 1, 0, 0, width); break;
			default: break;
		}

		// draw image
		context.drawImage(img, 0, 0)

		// export base64
		cb(canvas.toDataURL('image/jpeg'))
	}
	img.src = srcBase64
}

// scales canvas to square with targetSide pixels
// returns a new canvas containing the scaled image.
// does not upscale
export function downScaleCanvas(cv, targetSide){
	if (Math.min(cv.width, cv.height)<=100) return cv
	var scale = targetSide/cv.width
	if (!(scale < 1) || !(scale > 0)) throw ('scale must be a positive number <1 ');
	var sqScale = scale * scale; // square scale = area of source pixel within target
	var sw = cv.width; // source image width
	var sh = cv.height; // source image height

	// var tw = Math.floor(sw * scale); // target image width
	// var th = Math.floor(sh * scale); // target image height
	var tw = targetSide
	var th = targetSide

	var sx = 0, sy = 0, sIndex = 0; // source x,y, index within source array
	var tx = 0, ty = 0, yIndex = 0, tIndex = 0; // target x,y, x,y index within target array
	var tX = 0, tY = 0; // rounded tx, ty
	var w = 0, nw = 0, wx = 0, nwx = 0, wy = 0, nwy = 0; // weight / next weight x / y
	// weight is weight of current source point within target.
	// next weight is weight of current source point within next target's point.
	var crossX = false; // does scaled px cross its current px right border ?
	var crossY = false; // does scaled px cross its current px bottom border ?
	var sBuffer = cv.getContext('2d').
	getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
	var tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
	var sR = 0, sG = 0,  sB = 0; // source's current point r,g,b
	/* untested !
	var sA = 0;  //source alpha  */

	for (sy = 0; sy < sh; sy++) {
		ty = sy * scale; // y src position within target
		tY = 0 | ty;     // rounded : target pixel's y
		yIndex = 3 * tY * tw;  // line index within target array
		crossY = (tY != (0 | ty + scale));
		if (crossY) { // if pixel is crossing botton target pixel
			wy = (tY + 1 - ty); // weight of point within target pixel
			nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
		}
		for (sx = 0; sx < sw; sx++, sIndex += 4) {
			tx = sx * scale; // x src position within target
			tX = 0 |  tx;    // rounded : target pixel's x
			tIndex = yIndex + tX * 3; // target pixel index within target array
			crossX = (tX != (0 | tx + scale));
			if (crossX) { // if pixel is crossing target pixel's right
				wx = (tX + 1 - tx); // weight of point within target pixel
				nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
			}
			sR = sBuffer[sIndex    ];   // retrieving r,g,b for curr src px.
			sG = sBuffer[sIndex + 1];
			sB = sBuffer[sIndex + 2];

			/* !! untested : handling alpha !!
				sA = sBuffer[sIndex + 3];
				if (!sA) continue;
				if (sA != 0xFF) {
						sR = (sR * sA) >> 8;  // or use /256 instead ??
						sG = (sG * sA) >> 8;
						sB = (sB * sA) >> 8;
				}
			*/
			if (!crossX && !crossY) { // pixel does not cross
				// just add components weighted by squared scale.
				tBuffer[tIndex    ] += sR * sqScale;
				tBuffer[tIndex + 1] += sG * sqScale;
				tBuffer[tIndex + 2] += sB * sqScale;
			} else if (crossX && !crossY) { // cross on X only
				w = wx * scale;
				// add weighted component for current px
				tBuffer[tIndex    ] += sR * w;
				tBuffer[tIndex + 1] += sG * w;
				tBuffer[tIndex + 2] += sB * w;
				// add weighted component for next (tX+1) px
				nw = nwx * scale
				tBuffer[tIndex + 3] += sR * nw;
				tBuffer[tIndex + 4] += sG * nw;
				tBuffer[tIndex + 5] += sB * nw;
			} else if (crossY && !crossX) { // cross on Y only
				w = wy * scale;
				// add weighted component for current px
				tBuffer[tIndex    ] += sR * w;
				tBuffer[tIndex + 1] += sG * w;
				tBuffer[tIndex + 2] += sB * w;
				// add weighted component for next (tY+1) px
				nw = nwy * scale
				tBuffer[tIndex + 3 * tw    ] += sR * nw;
				tBuffer[tIndex + 3 * tw + 1] += sG * nw;
				tBuffer[tIndex + 3 * tw + 2] += sB * nw;
			} else { // crosses both x and y : four target points involved
				// add weighted component for current px
				w = wx * wy;
				tBuffer[tIndex    ] += sR * w;
				tBuffer[tIndex + 1] += sG * w;
				tBuffer[tIndex + 2] += sB * w;
				// for tX + 1; tY px
				nw = nwx * wy;
				tBuffer[tIndex + 3] += sR * nw;
				tBuffer[tIndex + 4] += sG * nw;
				tBuffer[tIndex + 5] += sB * nw;
				// for tX ; tY + 1 px
				nw = wx * nwy;
				tBuffer[tIndex + 3 * tw    ] += sR * nw;
				tBuffer[tIndex + 3 * tw + 1] += sG * nw;
				tBuffer[tIndex + 3 * tw + 2] += sB * nw;
				// for tX + 1 ; tY +1 px
				nw = nwx * nwy;
				tBuffer[tIndex + 3 * tw + 3] += sR * nw;
				tBuffer[tIndex + 3 * tw + 4] += sG * nw;
				tBuffer[tIndex + 3 * tw + 5] += sB * nw;
			}
		} // end for sx
	} // end for sy

	// create result canvas
	var resCV = document.createElement('canvas');
	resCV.width = tw;
	resCV.height = th;
	var resCtx = resCV.getContext('2d');
	var imgRes = resCtx.getImageData(0, 0, tw, th);
	var tByteBuffer = imgRes.data;
	// convert float32 array into a UInt8Clamped Array
	var pxIndex = 0; //
	for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 3, tIndex += 4, pxIndex++) {
		tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
		tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
		tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
		tByteBuffer[tIndex + 3] = 255;
	}
	// writing result to canvas.
	resCtx.putImageData(imgRes, 0, 0);
	return resCV;
}

// fileDescriptor(from <input type='file'>) -> Promise(image/jpeg base64data)
// use:
// fileInput.onchange = function(){loadAndCropSquare(this.files[0]).then(data=>{var img = new Image();img.src=date;document.body.appendChild(img)})}
export function loadAndCropSquare(file,targetSide=200){
	return new Promise((done,fail)=>{
		if (!file) return fail('no file')
		const reader = new FileReader()
		reader.onload = function(){
			const img = new Image()
			img.onload = function(){
				const width = this.naturalWidth
				const height = this.naturalHeight
				const side = Math.min(width,height)
				const [sx,sy] = width>height?[(width-height)/2,0]:[0,(height-width)/2]
				const canv = document.createElement('canvas')
				canv.width = canv.height = side
				const context = canv.getContext('2d')
				context.drawImage(this,sx,sy,side,side,0,0,side,side)
				const canv1 = downScaleCanvas(canv,targetSide)
				done(canv1.toDataURL('image/jpeg',1.0))
			}
			img.src = this.result
		}
		reader.readAsDataURL(file)
	})
}

export function loadImg(file) {
	return new Promise((done, fail) => {
		if (!file) return fail('no file')
		const reader = new FileReader()
		reader.onload = function() {
			done(this.result)
		}
		reader.readAsDataURL(file)
	})
}

export function loadImgURL(file) {
	return new Promise((done, fail) => {
		if (!file) return fail('no file')
		const URL = window.webkitURL || window.URL
		const url = URL.createObjectURL(file)
		done(url)
	})
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
	var timeout
	return function() {
		var context = this, args = arguments
		var later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/*
	0 дней
	1 день
	2-4 дня
	5-10 дней
	11-14 дней
	15-20 дней
*/
//						1				2			 5
// forms = ["день", "дня", "дней"]
function plural(forms){
	return function(n){
			var r = n%100
			if (r>10 && r<15) return forms[2]
			r = r%10
			if (r==1) return forms[0]
			if (r==0 || (r>4 && r<10)) return forms[2]
			if (r>1 && r<5) return forms[1]
	}
}

export const days = plural(["день", "дня", "дней"])
export const rubs = plural(['рубль', 'рубля', 'рублей'])


export const scoreGroup = n => {
	if (n>18) return 0
	if (n>6) return 1
	if (n>0) return 2
}


export function defragment(list){
	for (let i = list.length-1; i>=0; i--)
		if (!list[i]) list.splice(i,1)
	return list
}

export function check(what, where){
	var res
	what = what.split(' ')
	for (let word of new Set(where.split(' '))){
		for (let wh of what){
			if (wh.length>word.length) continue
			res = fuzzysort.single(wh, word)
			if (res && res.score>-3500)
				return true
		}
	}
	return false
}

export function stripHtml(html){
	var doc = new DOMParser().parseFromString(html, 'text/html')
	return doc.body.textContent || ""
}

export function mapCircleFromN(n){
	//// in map circle 3
	if (n>18) return 3

	//// in map circel 2
	if (n>6) return 2

	//// in map circle 1
	if (n>0) return 1

	//// in map center
	if (n==0) return 0

	//// in the bag
	if (n==-1) return -1

	//// in user list
	if (n==-2) return -2

	if (n==-3) return -3 // blacklist
}

export function inRange(min, val, max, inclusive=true){
	if (val > min && val < max) return true
	if (inclusive && (val==min||val==max)) return true
	return false
}

// export function stripHtml(s){
// 	return s.replace(/<[^>]+>/g,' ').trim().replace(/\s\s/g,' ')
// }

export function text2html(text){
	return text.split('\n').map(l=>'<p>'+l+'</p>').join('')
}

const html_re = /^<\w+>.*<\/\w+>$/
export function isHtml(text){
	return text.match(html_re)
}

const site_uri = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
export function is_web_site(uri){
	const m = uri.match(site_uri)
	return Boolean(m)
}

export function ensureHtml(s){
  if (!isHtml(s)) return text2html(s)
  return s
}

export const inMap = (loggedInUser, user) => {
	if (!loggedInUser.map) return -1
	return loggedInUser.map.findIndex(i=>i==user.id)
	// loggedInUser.map&&loggedInUser.map.findIndex(i=>i==user.id)>=0
}

export const inBag = (loggedInUser, user) => {
	if (!loggedInUser.bag) return -1
	return loggedInUser.bag.findIndex(i=>i==user.id)
	// loggedInUser.bag&&loggedInUser.bag.findIndex(i=>i==user.id)>=0
}

export const inMapOrBag = (loggedInUser, user) => {
	const bag_index = inBag(loggedInUser, user)
	if (bag_index>=0) return -1
	const map_index = inMap(loggedInUser, user)
	if (map_index>=0) return map_index+1
	if (loggedInUser===user) return 0
	return -2
	// inMap(loggedInUser, user) || inBag(loggedInUser, user)
}

export const isArray = value => typeof value==='object' && value.constructor===Array

export const eraseArray = arr => arr.splice(0, arr.length)

export const copyArray = (srcArr, trgArr) => {
	for (let i in srcArr){
		trgArr.push(clone(srcArr[i]))
	}
	return trgArr
}

export const reversedArray = a => [].concat(a).reverse()

export const getPath = params => o => params.reduce((m,p)=>m!==null&&m[p]!==undefined?m=m[p]:null,o)

export function oneOf(predicate, ls){
	if (ls) return run(ls)
	return function run(list){
		let gotOne = false
		list.forEach((i, index, arr, self)=>{
			if (predicate(i, index, arr, self)){
				if (gotOne) return false;
				else gotOne = true
			}
		})
		return gotOne
	}
}

export function domain(url){
	return url.split('//').slice(-1)[0].split('/')[0].split('www.').slice(-1)[0]
}

export const eq = a => b => a===b
export const compose = (...fs) => val => fs.reduce((acc,f)=>f(acc),val)
