<template lang="pug">
  .scrollbox
    UserList(:uidlist="uidlist()")
</template>




<script>
import UserList from '@/components/UserList.vue'
import {score} from '@/funcs.js'

export default {
  name: 'AllListView',
  components: {UserList},
  methods: {
    uidlist(){
      return this.$store.getters.topUsers.map(u=>u.id)
    }
  }
}
</script>
