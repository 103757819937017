<template lang="pug">
	.scrollbox(style='padding-top:0;')

		.userrow
			.content.tal
				Cell(:u='log' :n=-2 style='margin-left:1em;')
				.f-22(style='display:inline-block;')
					.total.gray(style='background:none;') Общий баланс:
						span(:class='{debt: totalBalance<0}') &nbsp;{{totalBalance}}

		.buttons-row

			VDropdown()
				template(v-slot:trigger)
					span {{curTab.ru}}&nbsp;
					span.badge {{countDeals(curTab.name)}}
				.tabs-drop
					.tabs-drop__item(
						v-for='tab in tabs'
						:key='tab.name'
						:class='{"tabs-drop__item--selected": tab.name==curTab.name}'
						@click='setTab(tab)'
					)
						span {{tab.ru}}&nbsp;
						span.badge(v-if='countDeals(tab.name)') {{countDeals(tab.name)}}

			button(@click='createDraft') + Новый договор

			.input.f-22
				input.f-22(v-model='filter' placeholder='фильтр')
				.btn(v-show='filter.length' @click='filter=""') ✕

		.list
			//- div
				span.input.f-22
					input.f-22(v-model='filter' placeholder='фильтр')
					span.btn(v-show='filter.length' @click='filter=""') ✕
				a.btn.f-22(v-if="tab=='drafts'" @click='createDraft') новый договор
			div
				UserDeals(
					v-for='(userDeals, index) in list'
					:key='userDeals.user.id'
					:user_deals='userDeals'
					:tab='tab'
					:filter='filter'
					@setUser='setUser'
					@delete='del(index)'
				)
</template>












<script>
import UserDeals from '@/components/UserDeals.vue'
import Cell from '@/components/Cell.vue'
// import Hovermenu from '@/components/Hovermenu.vue'
import {mapState, mapGetters} from 'vuex'

// import { VDropdown } from 'vuetensils/src/components'

import {
	daysLeft,
	State,
	total,
	tabs,
	nonBalanceDeals,
	balance,
	ensureCounterpartId
} from '@/deals.js'









export default {
	name: 'DealsView',
	components: {
		UserDeals,
		Cell,
		// VDropdown
	},

	data(){
		return {
			tab: 'all',
			tabs,
			list: [],
			filter: '',
			// showprefs: false
		}
	},

	created(){

		const list = this.list
		const myId = this.$store.state.loggedInUser.id

		this.$store.state.deals.forEach(deal=>{
			ensureCounterpartId(deal, myId)

			const inlineDeal = {deal, open: false}

			let userDeals = list.find(ud=>
				ud.user.id==deal.counterpartId)

			if (!userDeals){
				userDeals = {
					user: deal.counterpartId
						? this.$store.getters.userById(deal.counterpartId)
						: {id: null},
					open: false,
					deals: []
				}
				list.push(userDeals)
			}
			userDeals.deals.push(inlineDeal)
		})

		// byUserDeals.forEach(userDeals=>{
		// 	tab_names.forEach(tabName=>{
		// 		this.sortInlineDeals(userDeals[tabName])
		// 	})
		// })
	},

	computed: {
		...mapState({log:'loggedInUser'}),
		...mapGetters(['totalBalance']),
		curTab(){
			return this.tabs.find(t=>t.name==this.tab)
		},
	},

	methods: {
		// ensureCounterpartId(deal){
		// 	if (deal.counterpartId) return
		// 	const myId = this.log.id
		// 	if (myId==deal.sellerId)
		// 		if (deal.buyerId)
		// 			deal.counterpartId = deal.buyerId
		// 		else
		// 			deal.counterpartId = null
		// 	else if (myId==deal.buyerId)
		// 		if (deal.sellerId)
		// 			deal.counterpartId = deal.sellerId
		// 		else
		// 			deal.counterpartId = null
		// 	else
		// 		deal.counterpartId = null
		// },
		setTab(tab){
			this.tab = tab.name
		},



		sortInlineDeals(inlineDealsList, field='date', order=-1){
			switch (field){
				case 'date':
					if (order>0) inlineDealsList.sort((a,b)=>
						(a.deal.accepted||a.deal.created)>(b.deal.accepted||b.deal.created))
					else
						inlineDealsList.sort((a,b)=>
							(a.deal.accepted||a.deal.created)<(b.deal.accepted||b.deal.created))
					break;

				case 'days':
					if (order>0)
						inlineDealsList.sort((a,b)=>a.deal.days>b.deal.days)
					else
						inlineDealsList.sort((a,b)=>a.deal.days<b.deal.days)
					break;

				case 'total':
					if (order>0)
						inlineDealsList.sort((a,b)=>
							total(a.deal) > total(b.deal)
						)
					else
						inlineDealsList.sort((a,b)=>
							total(a.deal) < total(b.deal)
						)
					break;

				case 'state':
					if (order>0)
						inlineDealsList.sort((a,b)=>{
							const sa = State[a.deal.state]
							const sb = State[b.deal.state]
							if (sa==State.actual&&sb==State.actual)
								return daysLeft(a.deal)<daysLeft(b.deal)
							return sa>sb
						})
					else
						inlineDealsList.sort((a,b)=>{
							const sa = State[a.deal.state]
							const sb = State[b.deal.state]
							if (sa==State.actual&&sb==State.actual)
								return daysLeft(a.deal)>daysLeft(b.deal)
							return sa<sb
						})
					break;
			}
		},
		countDeals(tabName){
			// console.log('DealsView:countDeals():', tabName)
			const tab = this.tabs.find(t=>t.name==tabName)
			// console.log('DealsView:countDeals():tab:', tab.name)
			if (!tab) return -1
			const myId = this.log.id
			const res = this.list.reduce((m, userDeals)=>{
				return m + userDeals.deals.filter(idl=>tab.test(idl.deal, myId)).length
			}, 0)
			// console.log('DealsView:countDeals():res:', res)
			return res
		},
		setUser({user, inlinedeal, userDeals}){
			console.log('DealsView:setUser()')
			// if there's already a userDeals with ther user
			let trgUserDeals = this.list.find(ud=>ud.user.id==user.id)

			if (!trgUserDeals){
				trgUserDeals = {
					user,
					open: true,
					deals: [],
				}
				this.list.push(trgUserDeals)
			}

			trgUserDeals.deals.push(inlinedeal)
			trgUserDeals.open = true


			if (userDeals.deals.length==1){
				console.log('length==1')
				const remIndex = this.list.findIndex(ud=>ud===userDeals)
				console.log('remIndex', remIndex)
				this.list.splice(remIndex, 1)
			} else {
				let _index = userDeals.deals.findIndex(id=>id===inlinedeal)
				userDeals.deals.splice(_index,1)
			}
		},
		createDraft(){
			let nullUserDeals = this.list.find(ud=>ud.user.id==null)
			if (!nullUserDeals){
				nullUserDeals = {
					user: {id:null},
					open: true,
					deals: []
				}
				this.list.push(nullUserDeals)
			}
			const deal = {
				seller: this.log,
				sellerId: this.log.id,
				creator_is_seller: true,
				buyer: null,
				buyerId: null,
				dealgoods: [],
				state: 'draft',
				created: (new Date()).toISOString().slice(0,10),
				days: 30,
				details: ''
			}
			const inlineDeal = {
				open: true,
				deal
			}
			nullUserDeals.deals.push(inlineDeal)
			nullUserDeals.open = true
		},
		del(index){
			this.list.splice(index,1)
		},

		open_deal(deal){
			this.list.forEach(ud=>{
				ud.open=false
				ud.deals.forEach(id=>{
					if (id.deal===deal){
						id.open=true
						ud.open=true
						this.tab = tabs.find(tab=>tab.test(deal)).name
					} else {
						id.open=false
					}
				})
			})
		}
	}
}
</script>












<style scoped>

.userrow {
	position: relative;
	margin: 1% 2%;
}

.userrow:before {
	content: '';
	padding-top: 15.54%;
	display: block;
}

.userrow .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.tabs {
	text-align: center;
	font-size: 0;
	margin-top: 4%;
	filter: drop-shadow(0 0 1px rgba(0,0,0,.5));
}
a.tab {
	background: linear-gradient(#eee, #ccc);
	padding: .3em .3em;
	cursor:pointer;
	user-select: none;
	/* font-weight: bold; */
	border-right: .5px solid #bbb;
}
/* a.tab:after {
	content: '';
	border-right: 1px solid lightgray;
	height: 100%;
} */
a.tab:first-child {
	border-top-left-radius: .4em;
	border-bottom-left-radius: .4em;
}
a.tab:last-child {
	border-top-right-radius: .4em;
	border-bottom-right-radius: .4em;
	border-right: none;
}
a.tab.active {
	background: linear-gradient(#40a1ec, #0070c9);
	color: white;
}

a.btn {
	margin: 0 .3em;
}

.list {
	margin-top:1%;
}
.list:last-child {
  padding-bottom:25%;
}

.total {
	font-size: 2em;
}
.total span {
	color: darkgreen;
}
.debt {
	color: darkred !important;
}

/* span.input {
	padding: .2em .4em;
	border-radius: 1.5em;
	background-color: lightgray;
}
span.input input {
	border: none;
	background: none;
	text-align: center;
} */
/* span.input input:focus {
	outline: none;
} */
</style>

<style lang="scss">

.buttons-row {
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 10px 20px;
	& > * {
		margin-right: 8px;
		&:last-child {
			margin-right: 0;
		}
	}

	& button {
		font-size: 20px;
		border-radius: 4px;
		padding: 3px 8px 5px;
		background: linear-gradient(#eee, #ccc);
		cursor: pointer;
		border: none;
		filter: drop-shadow(0 0 1px rgba(0,0,0,.5));
		line-height: calc(1.2em + 8px);
	}

	& input {
		transition: all .2s;
		background-color: lightgray;
		color: gray;
		border-radius: 4px;
		text-align: center;
		line-height: calc(1.2em + 8px);
		border: none;

		&:focus{
			background-color: white;
			color: black;
			outline: none;
		}
	}
}

.vts-dropdown {
	&__trigger {


		& span.badge {
			text-align: center;
			line-height: 1.1em;
		}
	}
}



.tabs-drop {
	background-color: whitesmoke;
	border: solid .5 #f5f5f5;
	border-radius:4px;
	padding: .4em 0;
	box-shadow: 0 2px 4px rgba(0,0,0,.5);
	overflow: hidden;

	&__item {
		width: 100%;
		padding: 4px 12px;
		white-space: nowrap;
		font-size: 18px;
		text-align: left;
		cursor: pointer;
		line-height: calc(1.2em + 8px);

		& span.badge {
			text-align: center;
		}

		&--selected {
			background: linear-gradient(#40a1ec, #0070c9);
			color: white;
		}

		&:hover {
			background: linear-gradient(#eee, #ccc);
		}
	}
}
</style>
