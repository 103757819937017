<template lang="pug">
	.scrollbox
		//- TODO
		.f-16 доверители круг {{4-$route.params.group}}
		UserList(:uidlist='uidlist()')
</template>



<script>
import UserList from '@/components/UserList.vue'

export default {
	name: 'ScoreList',
	components: {UserList},

	methods: {
		uidlist(){
			return (this.$store.getters.currentUser.score||[])[this.$route.params.group-1]||[]
		}
	}
}
</script>
