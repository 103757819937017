<template>
  <li class='usergood'>

    <div>
      <div v-for='img in item.imgs' :key='img.id' class="image">
        <img :src="img.url" class='image__img'>
        <div v-if='editable' @click='delImg(img)' class="image__del"></div>
      </div>

      <label v-if='editable && item.imgs.length < 7' class="image__add">
        +
        <input @change='loadImages($event)' type="file" accept='image/*' multiple id='file-select' class='abs wh-0 hidden'>
      </label>
    </div>

    <div>
      <h3>Название</h3>
      <input type="text" v-if='editable' v-model='item.name'>
      <span v-else>{{item.name}}</span>
    </div>

    <div>
      <h3>Цена</h3>
      <input type="text" v-if='editable' v-model='item.price'>
      <span v-else>{{item.price}}</span>
    </div>

    <div>
      <h3>Описание</h3>
      <textarea v-if='editable' v-model='item.details' class='usergood__details-input' rows='2'></textarea>
      <p v-else>{{item.details}}</p>
    </div>

    <div v-if='addGoodButton'>
      <div class='warning' v-if='isNewUsergood'>Чтобы добавить товар в сделку - сначала сохраните его</div>
      <button v-else @click='addUsergoodToDeal'>Добавить в сделку</button>
    </div>

    <div>
      <h3>Ограниченное Количество</h3>
      <input v-if='editable' type="checkbox" id="quant" @change='quantChange' :checked='item.q>=0' style='width: 30px;height: 30px;'>
    </div>

    <div v-if='quant'>
      <h3>Количество</h3>
      <input v-if='editable' type="number" min=-1 v-model='item.q'>
      <span v-else>{{item.q}}</span>
    </div>

    <div v-if='quant'>
      <h3>Единицы</h3>
      <input v-if='editable' v-model='item.unit' type="text">
      <span v-else>{{item.unit}}</span>
    </div>

    <div class='buttons'>
      <button v-if='editable' @click='$emit("delete",item.id)' class="button">Удалить Товар</button>
    </div>

  </li>
</template>







<script>
import {loadAndCropSquare} from '@/funcs'
import Bus from '@/bus'

function moneyFormat(num){
  num = num.toString().split('.')
  return [[num[0].slice(-9,-6),num[0].slice(-6,-3),num[0].slice(-3)].join(' ').trim(),num[1]].filter(Boolean).join('.')
}

let mem_q = {}


var newImgsCount = 0



export default {

  props: {
    editable: Boolean,
    item: Object, // {id,name(goodid),imgs::[url],price,q,unit,details::str}
    addGoodButton: {type: Boolean, default: false,},
  },

  computed: {
    price(){return moneyFormat(this.item.price)},
    // finite(){return this.item.q>=0},
    quant(){return this.item.q>=0},
    // is_user_img(){return this.editable && this.item.img}
    is_user_img(){return this.editable && this.item.imgs.length},
    isNewUsergood() {
      return typeof(this.item.id) == 'string' && this.item.id.startsWith('new')
    },
  },

  methods: {
    quantChange(e) {
      if (e.target.checked) {
        this.item.q = mem_q[this.item.id] || '1'
      } else {
        mem_q[this.item.id] = this.item.q
        this.item.q = '-1'
      }
    },

    loadImages(e) {
      if (!(e.target.files && e.target.files.length)) {return}

      Array.from(e.target.files).slice(0, 7 - this.item.imgs.length).forEach(file => {
        const newId = 'newImgId:' + newImgsCount++
        loadAndCropSquare(file).then(data => {
          this.item.imgs.push({id:newId, url:data})
        })
      })
    },

    delImg(img) {
      this.item.imgs = this.item.imgs.filter(i => i != img)
    },

    addUsergoodToDeal() {
      Bus.$emit("add-usergood-to-deal", this.item)
      this.$emit("close-pricelist")
    },
  }
}
</script>







<style lang="scss" scoped>
.usergood {
  padding: 1em;
  box-shadow: 0 1px 6px rgba(0,0,0,.5);
  border-radius: 6px;
  font-size: 24px;
  margin: 10px;

  button {
    font-size: 24px;
  }

  input {
    text-align: center;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
  }


  &__img {
    width: 100px;
    border: 1px solid lightgray;
    vertical-align: middle;
  }

  &__details-input {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
  }


  .image {
    $this: &;

    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px solid lightgray;
    vertical-align: middle;
    position: relative;

    &__img {
      height: 100%;
      width: 100%;
    }

    &__del::after {
      content: '✕';
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      right: 0;
      line-height: 20px;
      font-size: 20px;
      text-align: center;
      color: crimson;
      background-color: rgba(255,255,255,.5);
    }

    &__add {
      width: 100px;
      height: 100px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      line-height: 100px;
      font-size: 50px;
      border:1px solid darkgray;
      color: darkgray;
    }
  }

}

.warning {
  border: 1px solid tan;
  border-radius: .3em;
  background-color: wheat;
  padding: .5em;
  padding-left: 2em;
  position: relative;

  &::before {
    content: '!';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: firebrick;
    height: 100%;
    width: 1em;
    top: 0;
    left: 0;
    line-height: 100%;
    font-size: 1.5em;
    border-right: 1px solid tan;
    background-color: rgba(0,0,0,.1);
  }
}
</style>
