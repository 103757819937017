<template>
	<div
		class="menu"
		:style="{'visibility': visible?'visible':'hidden'}">
			<div class="content">
				<slot></slot>
			</div>
		</div>
</template>

<script>
export default {
	name: 'Hovermenu',
	data(){
		return {
			visible: true
		}
	},
	methods: {
		show(){
			this.visible = true
		},
		hide(){
			this.visible = false
		},
		toggle(){
			this.visible = !this.visible
		}
	}
}
</script>


<style scoped>
.menu {
	position: absolute;
	background: white;
	border: 1px solid lightgray;
	border-radius: 8px;
	font-size: 18px;
	box-shadow: 0 0 20px rgba(0,0,0,.2);
	z-index: 9999;
}

.content {
	margin: .2cm 0;
}

.content > div {
	margin: 0;
}
</style>
