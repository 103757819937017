<template lang="pug">
	.f-22.tac
		input(v-model='pass' type='password' placeholder='новый пароль')
		span(v-if='!valid&&pass.length>0') Пароль должен быть длиной не менее 8 символов и содержать цифры и специальные значки, такие как !@#$%^...
		input(v-model='more' type='password' placeholder='еще раз')
		span(v-if='!same&&more.length>0') Повтороно введеный пароль не совпадает
		button.btn(v-if='valid&&same' @click='done') Готово
</template>





<script>
// const sp_chars = new RegExp('[~!@#$%^&*()_=+\[\]\'"\\|\/-,.;:`]')
const spChars = /[~!@#$%\^&*()_\-=+\[\]{};:'"\\|,<.>/?]/


export default {
	name: 'EnterNewPassword',
	data(){
		return {
			pass: '',
			more: ''
		}
	},
	computed: {
		valid(){
			if (this.pass.length<8) return false
			if (!/\d/.test(this.pass)) return false
			if (!spChars.test(this.pass)) return false
			return true
		},
		same(){
			return this.pass==this.more
		}
	},
	methods: {
		done(){
			this.$store.dispatch('set_new_pass', this.pass).then(data=>{
				notie.alert({text:'пароль успешно изменен.',stay:true})
			})
		}
	}
}
</script>





<style scoped>
	input {
		display: block;
		margin: 1em auto;
	}
</style>
