<template lang="pug">
	.scrollbox
		ImgCropper(
			v-show='showImgCropper'
			@close='closeImgCropper'
			@done='doneImgCropper'
		)
		.userrow
			.content.tal
				Cell.ml-1e(:u="temp", :n='-2')
				span.btn.hover.f-22(
					v-if='editable'
					@click.stop='showmenu=!showmenu'
					tabindex='0'
					@blur='showmenu=false'
					:style='{background:showmenu?"#036db7":"",color:showmenu?"#f0f8ff":""}'
				) управление аккаунтом
					Hovermenu(v-show="showmenu")
						.menuitem Сменить пароль
						.menuitem Удалить аккаунт

				//- Создать новую сделку
				.btn.f-22(
					v-else-if='log'
					@click='create_draft'
				) {{new_deal_name()}}

				//- Прайс лист
				span.btn.hover.f-22(
					v-if='user_has_price'
					@click='showPrice=true'
				) Прайс-лист

				Pricelist(v-if='showPrice' :user='user' @close='showPrice=false')


		.f-16.tal
			div(style="border-bottom: 1px solid gray; padding-left:2%" v-show="editable")
				label.pointer.pv_1cm.ph_2cm.rel Загрузить фото
					input#file-select.abs.wh-0.hidden(
						type='file'
						accept='image/*'
						@change='loadImg($event)'
					)
			TextEdit(
				:value='temp.lastName',
				@change='set("lastName", $event)'
				:editable='editable'
			) Фамилия
			TextEdit(
				:value='temp.firstName',
				@change='set("firstName", $event)'
				:editable='editable'
			) Имя
			TextEdit(
				:value='temp.slug||""'
				@change='set("slug", $event)'
				:editable='editable'
				sublabel='https://sot.name/'
				:default='"id"+user.id'
				valid_re='^(?!id\\d+$)[a-zA-Z0-9_-]+$'
				:illigal_values="['api','media','bag','profile','score','balance','all','search','static','resetpassword']"
			) Страница
			TextEdit(
				:value='temp.city||""',
				@change='set("city", $event)'
				:editable='editable'
			) Город
			TextEdit(
				v-if='editable'
				:value='temp.email||""',
				@change='set("email", $event)'
				:editable='editable'
			) Email
			Contacts2(
				:list='temp.contacts',
				@change='set("contacts", $event)'
				:editable='editable'
			) Контакты
			WordsEdit(
				:list='temp.interests'
				listname='interests'
				@change='set("interests", $event)'
				:editable='editable'
			) Интересы
			WordsEdit(
				:list='temp.offers'
				listname='goods'
				@change='set("offers", $event)'
				:editable='editable'
			) Предлагаю
			WordsEdit(
				:list='temp.bids'
				listname='goods'
				@change='set("bids", $event)'
				:editable='editable'
			) Принимаю

		//- TRADE MATCHES
		.f-22(v-if='editable')
			div
				.col-50
					h3 Предлагаю

				.col-50
					h3 Принимаю

			div(v-if='loadingGoods')
				h2 Loading...

			div(v-else)
				//- OFFERS MATCHES
				.col-50
					div(
						v-for='(buyers, offer_id) in tradeMatches.offers'
						:key='offer_id'
					)
						.no-select.pointer(
							@click='toggleOpenTradeMatchesOffers(offer_id)'
							style='background-color: lightgray;border-radius:.3em;margin:.1em;'
						) {{$store.getters.goodById(offer_id).name}} ({{buyers.length}})

						div(v-if='openTradeMatchesOffers == offer_id')
							.userrow(v-for='user_id in buyers' :key='user_id')
								.content.tal
									Cell.vam(:u='$store.getters.userById(user_id)' :n='-2')
									.vam.ib() {{someName($store.getters.userById(user_id))}}

				//- BIDS MATCES
				.col-50
					div(
						v-for='(sellers, bid_id) in tradeMatches.bids'
						:key='bid_id'
					)
						.no-select.pointer(
							@click='toggleOpenTradeMatchesBids(bid_id)'
							style='background-color: lightgray;border-radius:.3em;margin:.1em;'
						) {{$store.getters.goodById(bid_id).name}} ({{sellers.length}})

						div(v-if='openTradeMatchesBids == bid_id')
							.userrow(v-for='user_id in sellers' :key='user_id')
								.content.tal
									Cell.vam(:u='$store.getters.userById(user_id)' :n='-2')
									.vam.ib() {{someName($store.getters.userById(user_id))}}




		.f-22.pt-1e
			.btn(v-if="changed", @click='save') Сохранить
</template>









<script>
import {mapGetters, mapState} from 'vuex'

// import Vue from 'vue'
import Bus from '@/bus'

import {
	clone,
	deepEq,
	listsEq,
	deltaObj,
	// loadJs,
	loadJs2,
	orientationFromBase64,
	fixOrientation,
	downScaleCanvas,
	someName,
} from '@/funcs'

import {isActual} from '@/deals'
import Cell from '@/components/Cell.vue'
import TextEdit from '@/components/TextEdit.vue'
import WordsEdit from '@/components/WordsEdit.vue'
import Hovermenu from '@/components/Hovermenu.vue'
import ImgCropper from '@/components/ImgCropper.vue'
// import SlideOpen from '@/components/SlideOpen.vue'
import Contacts2 from '@/components/Contacts2.vue'
import Pricelist from "@/components/Pricelist.vue"


//// user fields to clone to vm.temp
const simpleFields = [
	// 'userid',
	'id',
	'lastName',
	'firstName',
	'slug',
	'email', // FIXME not given by server
	'city',
	'img'
]

const listFields = [
	'contacts',
	'interests',
	'offers',
	'bids',
	'score'
]


function genCropperOptions(previewEl){
	return {
		preview: previewEl,
		aspectRatio: 1,
		viewMode: 2,
		autoCrop: true,
		autoCropArea: 0.4,
		modal: true,
		guides: false,
		highlight: false,
		background: false,
		movable: false,
		rotatable: false,
		scalable: false,
		zoomable: false,
		toggleDragModeOnDblclick: false,
	}
}








export default {
	name: 'Profile',

	components: {
		Cell,
		TextEdit,
		WordsEdit,
		Hovermenu,
		ImgCropper,
		Contacts2,
		Pricelist,
	},

	data(){
		this.$store.dispatch(
			'get_user_goods',
			this.$store.getters.currentUser.id
		).then(goods => {
			this.goods = clone(goods)
			this.loadingGoods = false
		})

		return {
			temp: clone(
				this.$store.getters.currentUser,
				simpleFields.concat(listFields)
			),
			showmenu: false,
			showImgCropper: false,
			cropper: null,
			readyToCrop: false,

			showPrice: false,
			goods: [],
			loadingGoods: true,
			openTradeMatchesBids: null,
			openTradeMatchesOffers: null,
		}
	},

	computed: {
		...mapGetters({user:'currentUser'}),
		...mapState({log:'loggedInUser', tradeMatches:'tradeMatches'}),
		editable(){
			return this.user==this.log
		},
		changed(){
			if (!this.editable)
				return false
			if (simpleFields.some(k=>this.temp[k]!=this.user[k]))
				return true
			if (listFields.some(l=>!listsEq(this.temp[l], this.user[l])))
				return true
		},
		user_has_price(){ // if user has price list or if it's his own profile
			if (this.goods.length) return true
			if (this.editable) return true
			return false
		}
	},

	methods: {
		set(attr, value){
			this.$set(this.temp, attr, value)
			if (this.changed) {this.save()}
		},

		someName,

		// resetGoods(){
		// 	// this.goods = clone(goods)
		// 	this.goods = clone(this.user.goods)
		// },

		// delGood(id){
		// 	this.goods = this.goods.filter(g=>g.id!=id)
		// },

		// addGood(){
		// 	while (this.goods.find(tg=>tg.id=='new'+newGoodId)) newGoodId++
		// 	while (this.user.goods.find(ug=>ug.id=='new'+newGoodId)) newGoodId++
		// 	this.goods.push({
		// 		id:'new'+newGoodId,
		// 		img:'',
		// 		name:'Новый Товар',
		// 		price: 123,
		// 		q: 10,
		// 		quant: true,
		// 		unit: 'шт'
		// 	})
		// },

		// saveGoods(){
		// 	if (!this.editable) return;
		// 	const remove = this.user.goods.filter(ug=>!this.goods.find(tg=>ug.id==tg.id))
		// 	const update = this.goods.filter(tempgood=>{
		// 		const usergood = this.user.goods.find(ug=>ug.id==tempgood.id)
		// 		return !usergood || !deepEq(usergood,tempgood,null,(a,b,ug,tg,k)=>{
		// 			if (['price','q'].includes(k))
		// 				return Number(a.toString().replace(',','.'))==Number(b.toString().replace(',','.'))
		// 		})
		// 	})

		// 	this.$store.dispatch('save_user_goods', {
		// 		uid: this.user.id,
		// 		goods: {remove,update}
		// 	}).then(resp=>{
		// 		// console.log('ProfileView.saveGoods server response:',resp)
		// 		this.resetGoods()
		// 	})
		// },

		loadImg(event){
			if (!(event.target.files && event.target.files.length)) return

			loadJs2(document, window.Cropper!=undefined, '/static/js/cropper.min.js').then(()=>{

				if (window.Cropper==undefined){
					console.log('Cropper.js loading failed!')
					return;
				}

				if (this.cropper){
					this.cropper.destroy()
					this.cropper = null
					this.readyToCrop = false
				}

				const CROPIMAGE = this.$el.querySelector('#crop-image')
				const PREVIEW = this.$el.querySelector('#preview-crop')
				const file = event.target.files[0]
				const reader = new FileReader()

				reader.onload = e => {

					CROPIMAGE.onload = () => {
						this.cropper = new window.Cropper(
							CROPIMAGE,
							genCropperOptions(PREVIEW)
						)
						this.readyToCrop = true
					}

					const base64data = e.target.result
					if (base64data.startsWith('data:image/jpeg;base64,')){
						const orientation = orientationFromBase64(base64data.slice(23))
						if (orientation>1){
							fixOrientation(base64data, orientation, b64data => {
								CROPIMAGE.src = b64data
							})
						} else CROPIMAGE.src = base64data
					} else CROPIMAGE.src = base64data
				}

				reader.readAsDataURL(file)
				this.showImgCropper = true
				this.$set(this.temp, "img", event)
			})
		},

		closeImgCropper(){
			if (this.cropper){
				this.cropper.destroy()
				this.cropper = null
				this.readyToCrop = false
			}
			this.showImgCropper = false
			let imgInput = this.$el.querySelector('#file-select')
			imgInput.value = ''
		},

		doneImgCropper(){
			if (!this.cropper) {
				console.log('no vm.cropper in ProfileView')
				return
			}

			let canvas = this.cropper.getCroppedCanvas()
			let canvas100 = downScaleCanvas(canvas, 100)

			let pic = canvas100.toDataURL('image/jpeg', 1.0)

			// set img in Cell in Profile to pic
			this.temp.img = pic

			this.cropper.destroy()
			this.cropper = null
			this.readyToCrop = false
			this.showImgCropper = false
			this.$el.querySelector('#file-select').value = ''
		},

		changes(){
			const delta = {}

			for (let f of simpleFields)
				if (this.temp[f]!=this.user[f])
					delta[f] = this.temp[f]

			for (let f of listFields)
				if (!listsEq(this.temp[f], this.user[f]))
					delta[f] = this.temp[f].slice()

			return delta
		},

		save(){
			let delta = this.changes()
			delta.id = this.user.id
			console.log('delta', delta)
			this.$store.dispatch('update_profile', delta).then(data=>{
				if (data.update=='OK')
					this.temp = clone(
						this.$store.getters.currentUser,
						simpleFields.concat(listFields)
					)
				if (data.msg){
					console.log('save profile msg:', data.msg)
				}
			}).catch(data => {
				console.log('CATCH2', data)
				this.temp = clone(
					this.$store.getters.currentUser,
					simpleFields.concat(listFields)
				)
			})
		},

		delete(){
			if (this.$store.state.deals.find(d=>isActual(d))){
				notie.alert({
					type:'warning',
					text:'Нельзя удалить аккаунт, если у Вас есть еще не завешенные сделки'
				})
				return
			}

			notie.confirm({
				text: 'Ваш аккаунт будет безвозвратно удалён из базы данных сайта. Продолжить?',
				submitText: 'Удалить',
				cancelText: 'Отмена',
				submitCallback: () => {
					this.$store.dispatch('delete_account').then(data=>{
						notie.alert({text:'Аккаунт удален.',stay:true})
					})
				}
			})
		},

		new_deal_name(){
			const deals_view_ver = window.localStorage.preferences_dealsViewVersion || 1
			if (deals_view_ver==2) return 'Новый шаг'
			if (deals_view_ver==3) return 'Новый баланс'
			return 'Новая сделка'
		},

		create_draft(){
			const deal = {
				seller: this.log,
				sellerId: this.log.id,
				creator_is_seller: true,
				buyer: this.user,
				buyerId: this.user.id,
				counterpartId: this.user.id,
				dealgoods: [{goodId:1, q:1, price:''}], // noGoodsDeal by default
				state: 'new',
				created: (new Date()).toISOString().slice(0,10),
				days: 30,
				details: ''
			}

			// this.$store.state.deals.push(deal)
			this.$store.commit('ADD_DEAL', deal)

			this.$router.push({name:"deals"}, function(route){
				// had to do it like that cause emiting event here
				// makes it emited before the BalanceView vm is created
				// and the event listnere is hooked up
				Bus.$once('open_deal', vm => vm.open_deal(deal, 'new'))
			})
		},

		toggleOpenTradeMatchesBids(bid_id) {
			if (bid_id == this.openTradeMatchesBids) {
				this.openTradeMatchesBids = null
			} else {
				this.openTradeMatchesBids = bid_id
			}
		},

		toggleOpenTradeMatchesOffers(offer_id) {
			if (offer_id == this.openTradeMatchesOffers) {
				this.openTradeMatchesOffers = null
			} else {
				this.openTradeMatchesOffers = offer_id
			}
		},
	}
}
</script>









<style scoped>
.userrow {
	position: relative;
	margin: 1% 2%;
}

.userrow:before {
	content: '';
	padding-top: 15.54%;
	display: block;
}

.userrow .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}




ul.pricelist {
	list-style: none;
	padding: 0;
	margin: 0;
}

</style>
