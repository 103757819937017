<template lang="pug">
  .contacts.smooth-color(:style="{background:editing?'lightgoldenrodyellow':''}")
    .contacts-title
      .col-20.f-22.dimgray.vat
        slot label

      .col-80.rel
        .f-22.col-80
          .icon(v-for='link in list')
            a(
              :href='ensureScheme(link)'
              target='_blank'
              rel='noopener noreferrer'
            )
              img.img(
                :src='faviconSrc(ensureScheme(link))'
                style='background-image:url(/static/img/www_r.png);'
              )

        .abs.top.r(v-if='editable')
          .f-16.tar.vat.lh-1_9e
            span.btn.ml-1e.mdblue.hov-under.hov-mdblue(v-if='!editing' @click.stop='edit') изменить
            template(v-else)
              span.btn.ml-1e.mdblue.hov-under.hov-mdblue(@mousedown.prevent @click.stop='save') готово
              span.btn.ml-1e.mdblue.hov-under.hov-mdblue(@mousedown.prevent @click.stop='quit') отмена



    .contacts-hider(ref='hider' style='height:0px;')
      .contacts-body(ref='body')
        .col-20
        .col-80

          //- .f-22.just.mb-1e Добавьте адрес своей электронной почты, номер своего телефона или ссылку на свою страницу в соцсети. Или свяжите свой аккаунт на sot.name со своим аккаунтом в соцсети или на другом сайте для входа на sot.name без авторизации (без введения логина и пароля).


          .f-22.mv_2e.hover-hi.smooth-color(v-for='phone in phones')
            .col-10
              img.vam.img(src='/static/img/254080.svg')
            .col-80
              span.lh-2e.vam.ml_5e.w-95p {{phone}}
            ButtonHoverMenu.col-10.lh-2e.tac
              .menuitem(@click='del(phone)') удалить

          .f-22.mv_2e.hover-hi.smooth-color
            .col-10
              img.vam.img(src='/static/img/254080.svg')
            .col-80
              span.lh-2e.vam.ml_5e
                input.f-22.m-0.tac.w-95p(placeholder='добавьте номер телефона' v-model='new_phone')
            span.col-10.lh-2e.tac.f-22
              img.vam.lh-2e.w-1e(src='/static/img/plus.svg' @click='add_phone')


          .f-22.mv_2e.hover-hi.smooth-color(v-for='email in emails')
            .col-10
              img.vam.img(src='/static/img/321817.svg')
            .col-80
              span.lh-2e.vam.ml_5e.w-95p {{email}}
            ButtonHoverMenu.col-10.lh-2e.tac
              .menuitem(@click='del(email)') удалить

          .f-22.mv_2e.hover-hi.smooth-color
            .col-10
              img.vam.img(src='/static/img/321817.svg')
            .col-80
              span.lh-2e.vam.ml_5e
                input.f-22.m-0.tac.w-95p(placeholder='добавьте эл. почту' v-model="new_email")
            span.col-10.lh-2e.tac.f-22
              img.vam.lh-2e.w-1e(src='/static/img/plus.svg' @click='add_email')


          .f-22.mv_2e.hover-hi.smooth-color(
            v-for='(logo, site, index) in domains'
            :key='site'
            v-if='log'
          )
            .col-10
              img.vam.img(:src='"/static/img/"+ logo+".svg"')
            .col-80(v-if='check_oauth(site)||check_contact(site)')
              span.lh-2e.vam.ml_5e.w-95p(v-text='get_link(site)')
            .col-80(v-else)
              span.lh-2e.vam.ml_5e
                input.f-22.m-0.tac.w-95p(
                  placeholder='добавьте ссылку на свою страницу'
                  :ref='site'
                  @keyup.enter='add_site(site)'
                )
            ButtonHoverMenu.col-10.lh-2e.tac
              template(v-if='check_oauth(site)')
                .menuitem удалить
              template(v-else-if="check_contact(site)")
                .menuitem(
                  @click='del_site(site)'
                ) удалить ссылку
                .menuitem связать с аккаунтом &nbsp;
                  img(
                    src='/static/img/question.svg'
                    style='width:1em;'
                    @click.stop='info'
                  )
              template(v-else)
                .menuitem(
                  @click='add_site(site)'
                ) добавить ссылку
                .menuitem связать с аккаунтом &nbsp;
                  img(
                    src='/static/img/question.svg'
                    style='width:1em;'
                    @click.stop='info'
                  )

          .f-22.mv_2e.hover-hi.smooth-color(
            v-for='link in other_sites'
            :key='link'
          )
            .col-10
              img.vam.img(src='/static/img/www.png')
            .col-80
              span.lh-2e.vam.ml_5e.w-95p {{link}}
            span.col-10.lh-2e.tac.f-22
              img.vam.lh-2e.w-1e(src='/static/img/trash.svg' @click='del(link)')

          .f-22.mv_2e.hover-hi.smooth-color
            .col-10
              img.vam.img(src='/static/img/www.png')
            .col-80
              span.lh-2e.vam.ml_5e
                input.f-22.m-0.tac.w-95p(
                  placeholder='добавьте ссылку на свой сайт'
                  ref='another_site'
                  @keyup.enter='add_site()'
                )
            span.col-10.lh-2e.tac.f-22
              img.vam.lh-2e.w-1e(src='/static/img/plus.svg' @click='add_site()')


</template>












<script>
import {mapState} from 'vuex'
import {
  ensureScheme,
  faviconSrc,
  listsEq,
  domain,
  validateEmail,
  validatePhone,
  is_web_site,
  normTel,
  eq,compose,
} from '@/funcs.js'


import ButtonHoverMenu from '@/components/ButtonHoverMenu.vue'


const site_and_logo = {
  'vk.com':       '145813',
  'facebook.com': '145802',
  'google.com':   '145804',
  'ok.ru':        '1458020',
}

const validateSocialSite = url => domain(url) in site_and_logo

export default {
  name: 'Contacts2',
  components: {ButtonHoverMenu},
  props: ['list', 'editable'],

  data(){
    return {
      editing: false,
      temp: this.list ? this.list.slice() : [],
      before_edit: null,
      domains: Object.freeze(site_and_logo),
      new_email: '',
      new_phone: '',
    }
  },

  directives: {
		focus: {
			inserted(el){
				el.focus()
			}
		}
	},

  computed: {
    ...mapState({log:'loggedInUser'}),

    emails(){
      return this.temp.filter(validateEmail)
    },

    phones(){
      return this.temp.filter(validatePhone)
    },

    other_sites(){
      return this.temp.filter(l =>
        !validateSocialSite(l)
        && !validatePhone(l)
        && !validateEmail(l)
      )
    },
  },

  methods: {
    ensureScheme,
    faviconSrc,

    check_oauth(site){
      return Object.keys(this.log.oauth).includes(site)
    },

    check_contact(site){
      return this.temp.find(compose(domain, eq(site)))
    },

    get_link(site){
      return this.temp.find(l=>domain(l)==site)
    },

    add_email(){
      const val = this.new_email.trim()
      if (!val) return
      if (!validateEmail(val)) return
      if (this.emails.includes(val)) return
      this.temp.push(val)
      this.new_email = ''
      this.$nextTick(this.show)
    },

    add_phone(){
      const val = normTel(this.new_phone.trim())
      if (!val) return
      if (!validatePhone(val)) return
      if (this.phones.find( compose(normTel,eq(val)) )) return
      this.temp.push(val)
      this.new_phone = ''
      this.$nextTick(this.show)
    },

    add_site(site){
      if (!site && this.$refs.another_site.value.trim()) {
        const val = this.$refs.another_site.value.trim()
        if (!is_web_site(val)) {return}
        if (this.temp.find( eq(val) )) {return}
        this.temp.push(val)
        this.$refs.another_site.value = ''
        this.$nextTick(this.show)
      } else if (site) {
        const val = this.$refs[site][0].value.trim()
        if (!val) {return}
        if (!is_web_site(val)) {return}
        if (domain(val)!=site) {return}
        if (this.temp.find( eq(val) )) {return}
        this.temp.push(val)
        this.$refs[site][0].value = ''
        this.$nextTick(this.show)
      }
    },

    attach_site(site){
      if (site=='vk.com'){
        window.location.replace('https://oauth.vk.com/authorize?client_id=6490715&display=popup&redirect_uri=http://sot.name/api/oauth/vk&scope=email&response_type=code&state=add_contact:'+this.log.id)
      }
    },

    del(link){
      let index = this.temp.findIndex(eq(link))
      if (index >= 0) {
        this.temp.splice(index, 1)
        this.$nextTick(this.show)
      }
    },

    del_site(site){
      let index = this.temp.findIndex( compose(domain,eq(site)) )
      if (index >= 0) {
        this.temp.splice(index, 1)
        this.$nextTick(this.show)
      }
    },

    edit(){
      this.before_edit = this.temp.slice()
      this.new_email = ''
      this.new_phone = ''
      this.show()
    },

    show(){
      this.editing = true
      this.$refs.hider.style.height = this.$refs.body.clientHeight + 'px'
    },

    hide(){
      this.editing = false
      this.$refs.hider.style.height = '0px'
    },

    save(){
      this.hide()
      if (!listsEq(this.list, this.temp))
        this.$emit('change', this.temp.slice())
    },

    quit(){
      this.hide()
      this.temp = this.before_edit
      this.new_email = ''
      this.new_phone = ''
    },

    info(){
      notie.alert({
        type: 'info',
        text: 'Свяжите свой аккаунт на sot.name со своим аккаунтом в соцсети или на другом сайте для входа на sot.name без авторизации (без введения логина и пароля).',
        stay: true
      })
    }
  }
}
</script>













<style scoped>
.contacts-title {
  padding: 1% 2%;
  font-size: 0;
  user-select: none;
}

.contacts-hider {
  border-bottom: 1px solid darkgray;
  overflow: hidden;
  transition: height .25s;
}

.contacts-body {
  padding: 1% 2%;
}

.icon {
  display:inline-block;
}
.icon a{
  display:inline;
}
img.img {
  width: 2em;
  margin: 0 .1em;
  background-size: cover;
  background-repeat: no-repeat;
}
.hover-hi:hover {
  background-color: rgba(0,0,0,.1);
}
</style>
