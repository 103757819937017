<template>
  <div class="user_deals f-16">
    <div class="userrow">
      <div class="userrow__content">
        <Cell :u='log' :n='0' style='position:absolute;left:0;top:1%;'></Cell>
        <div class="f-22">
          <div class="userrow__balance">
            {{balance}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Cell from '@/components/Cell.vue'
import {mapState} from 'vuex'
import {
  loadJs,
  check,
} from '@/funcs'
import {
  tabs,
  dealsBalance,
} from '@/deals'


export default {
  beforeCreate() {
    loadJs('/static/js/fuzzysort.js', null, window.fuzzball)
  },

  components: {
    Cell,
  },

  props: ['user','tab','filter','sort','order'],

  data() {
    if (!('dealsOpen' in this.user)) {this.user.dealsOpen = false}

    return {
      open: this.user.dealsOpen,
    }
  },

  computed: {
    ...mapState({log:'loggedInUser'}),
    deals(){
      const tab = tabs.find(tab=>tab.name==this.tab)
      if (!tab) return []
      // const myId = this.$store.state.loggedInUser.id
      const myId = this.log.id
      const vm = this
      const deals = this.$store.state.deals.filter(deal=>{
        if (deal.counterpartId!=vm.user.id) return false
        if (!tab.test(deal, myId)) return false
        if (vm.filter.length>1&&fuzzysort){
          if (!check(vm.filter, deal.dealgoods.map(dg=>{
            return vm.$store.getters.goodById(dg.goodId).name
          }).join(' ')+' '+deal.details)){return false}
        }
        if (deal.inlineDealOpen==void 0)
          deal.inlineDealOpen=false
        return true
      })
      deals.sort(sortDealsBy(this.sort))
      if (this.order<0) deals.reverse
      return deals
    },
    balance(){
      return dealsBalance(
        this.deals,
        this.log.id
      )
    }
  },

  methods: {}
}
</script>



<style lang="scss" scoped>
.userrow {
  position: relative;
  text-align: left;
  margin: 1% 2%;
  font-size: 0;

  &:before {
    content: '';
    padding-top: 15.54%;
    display: block;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    text-align: center;
  }

  &__balance {
    font-size: 4em;
    line-height: 1em;
  }
}
</style>
