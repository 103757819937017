<template lang="pug">
	.bag( @click="bagclick" )
		.bag-content(v-if="log")
			.bag-scrollbox
				.bag-scrollbox-content
					.cell-shadow(v-for="uid in bag" :key="uid")
						Cell(:u="$store.getters.userById(uid)" :n='-1')
				.bag-scrollbox-track
					span.bag-scrollbox-bar

		.bag-content.f-16(v-else)
			h3 Друзья!
			div(style='width:100%;word-break:break-word;')
				p(style='padding:0 1em;margin:0;') Здесь мы представляем вам тестовую версию нашего проекта Круг Доверия!
				a.btn(@click.stop='show_welcome') читать полностью...

			Modal(v-if='show_welcome_modal' @close='show_welcome_modal=false')
				h2(slot='header') О нас
				About(slot='body')
				.tac(slot='footer' style='padding:1em;')
					button(@click='show_welcome_modal=false') Закрыть

		.count.f-16(
			@click.stop="clickAll"
			style="left:0%;"
		)
			div(style="font-size:2.5em;") {{$store.state.users.length}}

		//- .count.f-16(
		//- 	@click.stop='edit_mode'
		//- 	style='left:11%;'
		//- 	:style='{ background: map_edit_mode ? "red" : "white" }'
		//- )
		//- 	div(style="font-size:2.5em") $

		.count.f-16(
			v-if='log'
			@click.stop="clickMysot"
			style="right:0%;"
		)
			div(style="font-size:2.5em;") {{bag.length}}
</template>






<script>
import Cell from '@/components/Cell.vue'
import Modal from '@/components/Modal2.vue'
import About from '@/components/About.vue'
import {mapState, mapGetters} from 'vuex'


var box = null
var cnt = null
var trk = null
var bar = null
var x, left = 0, hide
var scroll_connected = false

function update(){
	if (hide) {
		bar.style.display = 'none'
		return
	} else {
		bar.style.display = 'block'
	}
	bar.style.width = trk.clientWidth*box.clientWidth/cnt.scrollWidth + 'px'
	bar.style.left = trk.clientWidth*cnt.scrollLeft/cnt.scrollWidth + 'px'
}

function onWheel(e){
	cnt.scrollLeft-=e.wheelDelta
	update()
}

function lim(down, x, up){
	return Math.min(Math.max(down, x), up)
}

function start(e){
	if (e.button!==0) return
	e.preventDefault()
	x = e.clientX
	left = parseFloat(bar.style.left)
	document.addEventListener('mouseup', end)
	document.addEventListener('mousemove', drag)
}

function drag(e){
	e.preventDefault()
	left = lim(0, left + e.clientX - x, trk.clientWidth-bar.clientWidth)
	x = e.clientX
	bar.style.left = left + 'px'
	cnt.scrollLeft = cnt.scrollWidth*left/trk.clientWidth
}

function end(e){
	document.removeEventListener('mouseup', end)
	document.removeEventListener('mousemove', drag)
}

// TOUCH EVENTS
let touchdragging, touchdata, lastTap
function touchstart(e){
	// e.preventDefault()
	// console.log('touchstart', e)
	touchdragging = true
	touchdata = {
		time: e.timeStamp,
		x: cnt.scrollLeft + e.changedTouches[0].pageX
	}
}

function touchmove(e){
	if (touchdragging){
		// e.preventDefault()
		cnt.scrollLeft = touchdata.x - e.changedTouches[0].pageX
		update()
	}
}

function touchend(e){
	// console.log('touchend', e)
	touchdragging = false
	// console.log(
	// 	'touch time:', e.timeStamp-touchdata.time,
	// 	'dist:', e.changedTouches[0].pageX-touchdata.x
	// )



	// if ((e.timeStamp-touchdata.time)*(e.changedTouches[0].pageX-(touchdata.x-cnt.scrollLeft))<1000){
	// 	// tap
	// 	const tap = Date.now()
	// 	if (lastTap && tap-lastTap<500){
	// 		console.log('DblClick')
	// 	} else {
	// 		lastTap = tap
	// 		console.log('tap')
	// 	}
	// } else {
	// 	console.log('no tap')
	// }
}

function touchcancel(e){
	// console.log('touchcancel', e)
	touchdragging = false
}



export default {
	components: {Cell, Modal, About},

	data(){
		return {
			show_welcome_modal: false,
		}
	},

	mounted(){
		this.connect_scroll()
		// box = document.querySelector('.bag-scrollbox')
		// cnt = document.querySelector('.bag-scrollbox-content')
		// trk = document.querySelector('.bag-scrollbox-track')
		// bar = document.querySelector('.bag-scrollbox-bar')

		// hide = this.bag.length<8
		// update()

		// box.addEventListener('wheel', onWheel)
		// box.addEventListener('touchstart', touchstart)
		// box.addEventListener('touchmove', touchmove)
		// box.addEventListener('touchend', touchend)
		// box.addEventListener('touchcancel', touchcancel)
		// bar.addEventListener('mousedown', start)
		// window.addEventListener('resize', update)
	},

	updated(){
		this.connect_scroll()
		// hide = this.bag.length<8
		// update()
	},

	beforeDestroy(){
		this.disconnect_scroll()
	},


	computed: {
		...mapState({
			log: 'loggedInUser',
			sel: 'selectedCell',
			// map_edit_mode: 'map_edit_mode'
		}),
		...mapGetters({
			cur: 'currentUser'
		}),
		bag(){
			return this.log
				? this.log.bag || []
				: []
		}
	},

	methods: {
		connect_scroll() {
			if (!this.log) {return}
			if (scroll_connected) {
				hide = this.bag.length < 8
				update()
			} else {
				box = document.querySelector('.bag-scrollbox')
				cnt = document.querySelector('.bag-scrollbox-content')
				trk = document.querySelector('.bag-scrollbox-track')
				bar = document.querySelector('.bag-scrollbox-bar')

				hide = this.bag.length < 8
				update()

				box.addEventListener('wheel', onWheel)
				box.addEventListener('touchstart', touchstart)
				box.addEventListener('touchmove', touchmove)
				box.addEventListener('touchend', touchend)
				box.addEventListener('touchcancel', touchcancel)
				bar.addEventListener('mousedown', start)
				window.addEventListener('resize', update)
				scroll_connected = true
			}
		},

		disconnect_scroll() {
			if (!scroll_connected) {return}
			box.removeEventListener('wheel', onWheel)
			box.removeEventListener('touchstart', touchstart)
			box.removeEventListener('touchmove', touchmove)
			box.removeEventListener('touchend', touchend)
			box.removeEventListener('touchcancel', touchcancel)
			bar.removeEventListener('mousedown', start)
			window.removeEventListener('resize', update)

			box = null
			cnt = null
			trk = null
			bar = null
			scroll_connected = false
		},

		bagclick() {
			// check if the user can change anything
			if (!this.log) return

			const logUser = this.log

			// check if there is someone to add to bag
			if (!this.sel) return
			if (!this.sel.user) return
			// check if selected cell/user is on the map
			if (this.sel.n<0) return

			const selUser = this.sel.user

			// check if selectedCell.user is already in logUser.bag
			if (logUser.bag.find(id=>id==selUser.id)) return

			// check is selectedCell.user is logUser
			// (selected on someone else's map)
			if (logUser===selUser) return

			this.$store.dispatch('bag_click')
		},

		clickAll(){
			if (this.sel&&this.sel.user&&this.log&&this.cur&&this.log===this.cur){
				this.$store.dispatch('remove_selected_user').then(resp=>{

				}, err=>{
					notie.alert({type:'error', text:'server error'})
				}).catch(err=>{
					notie.alert({type:'error', text:'app error'})
				})
			} else {
				this.$store.commit('SELECT_CELL')
				this.$router.push({name: 'all'})
			}
		},

		clickMysot(){
			// if there is a selected cell in map
			if (this.sel && this.sel.user && this.sel.n>=0){
				// log('ADD')
				// this.$store.commit('BAGCLICK')
				this.bagclick()
			} else {
				// log('OPEN LIST')
				this.$store.commit('SELECT_CELL')
				this.$router.push({name:'bag'})
			}
		},

		// edit_mode(){
		// 	let val
		// 	if (this.map_edit_mode){
		// 		notie.hideAlerts()
		// 		val = false
		// 	} else {
		// 		notie.alert({text:'Редактирование круга доверия',stay:true})
		// 		val = true
		// 	}
		// 	this.$store.commit('EDIT_MAP', val)
		// },

		show_welcome() {
			this.show_welcome_modal = true
		},
	}
}
</script>





<style scoped>
	.bag {
		/* background: lightseagreen; */
		background: #ddd;
		position: relative;
		white-space: nowrap;
		/* overflow: hidden; */
		/* overflow-y: hidden; */
		/* overflow-x: hidden; */
		text-align: center;
		box-shadow: 0 0 4px rgba(0,0,0,.5);
	}
	.bag:before {
		content: '';
		display: block;
		padding-top: 15.5%;
	}
	.bag-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		top:20%;
		left:10%;
		right:10%;
	}

	.bag-scrollbox {
		overflow: hidden;
		box-sizing: border-box;
		margin: 0px;
		border: 0px;
		width: 100%;
		position: relative;
	}

	.bag-scrollbox-content {
		min-width: 100%;
		overflow: hidden;
	}

	.bag-scrollbox-track {
		position: absolute;
		left: 2px;
		right: 2px;
		bottom: 2px;
		height: 0;
	}

	.bag-scrollbox-bar {
		display:block;
		position: absolute;
		bottom: 0;
		height: 8px;
		background: gray;
		opacity: 0.5;
		left: 0px;
		width: 10px;
		border-radius: 4px;
	}

	.count {
		position: absolute;
		/* right: 1%; */
		top: -70.5%;
		width: 10%;
		height: 64.5%;
		line-height: 482%;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		box-shadow: 0 0 4px rgba(0,0,0,.5);
		top:28%;
	}
</style>
