<template lang="pug">
	.userDeals.f-16(v-if='deals&&deals.length')
		.userrow
			.content(@click='user_deals.open=!user_deals.open')
				Cell(
					:u='user_deals.user'
					:n='-2'
				)
				.buttons.f-22
					span.dealsCount(
						:class='{debt:balance<0}'
					) {{balance}} ({{deals.length}})

		.deals(v-if='user_deals.open')
			.sort
				a.btn(@click='order*=-1') {{order>0?'↓':'↑'}}
				a.btn(@click='sort="date"' :class='{active:sort=="date"}') по дате
				a.btn(@click='sort="left"' :class='{active:sort=="left"}') по сроку
				a.btn(@click='sort="state"' :class='{active:sort=="state"}') по статусу
				a.btn(@click='sort="total"' :class='{active:sort=="total"}') по сумме
				a.btn(@click='sort="balance"' :class='{active:sort=="balance"}') по балансу
			template(v-for='(deal, index) in deals')
				InlineDeal(
					v-if='deal.deal.state=="draft"'
					:key='deal.deal.id'
					:inlinedeal='deal'
					@setUser='setUser'
					@delete='del(index)'
				)
				InlineDeal(
					v-else-if='deal.deal.state=="offered"'
					:key='deal.deal.id'
					:inlinedeal='deal'
					@delete='del(index)'
				)
				InlineDeal(
					v-else
					:key='deal.deal.id'
					:inlinedeal='deal'
				)
</template>










<script>
import Cell from '@/components/Cell.vue'
import InlineDeal from '@/components/InlineDeal.vue'
import {
	tabs, balance, nonBalanceDeals,
	daysLeft, dealDate, State, dealState,
	total, dealsBalance
} from '@/deals.js'
import {loadJs, check} from '@/funcs.js'



function merge(){
	const out = []
	for (let a of arguments)
		for (let i of a)
			if (!out.includes(i))
				out.push(i)
	return out
}

function sortBy(prop){
	switch(prop){
		case 'date':
			return (a,b)=>(new Date(dealDate(a.deal)))-(new Date(dealDate(b.deal)))
		case 'left':
			return (a,b)=>daysLeft(a.deal)-daysLeft(b.deal)
		case 'state':
			return (a,b)=>State[dealState(a.deal)]-State[dealState(b.deal)]
			return (a,b)=>State[a.deal.state]-State[b.deal.state]
		case 'total':
			return (a,b)=>total(a.deal)-total(b.deal)
		case 'balance':
			return (a,b)=>balance(a.deal)-balance(b.deal)
	}
}




export default {
	name: 'UserDeals',
	components: {Cell, InlineDeal},
	props: ['user_deals', 'tab', 'filter'],
	data(){
		return {
			sort: 'left', // date|left|state|total|balance
			order: 1 // 1 - string | -1 - reversed
		}
	},

	beforeCreate(){
		loadJs('/static/js/fuzzysort.js', null, window.fuzzball)
	},

	computed: {
		deals(){
			const tab = tabs.find(tab=>tab.name==this.tab)
			if (!tab) return []
			const myId = this.$store.state.loggedInUser.id
			const vm = this
			const deals = this.user_deals.deals.filter(idl=>{
				if (!tab.test(idl.deal, myId)) return false
				if (vm.filter.length>1&&fuzzysort){
					if (!check(vm.filter, idl.deal.dealgoods.map(dg=>{
						return vm.$store.getters.goodById(dg.goodId).name
					}).join(' ')+' '+idl.deal.details))
						return false
				}
				return true
			})
			deals.sort(sortBy(this.sort))
			if (this.order<0) deals.reverse()
			return deals
		},
		balance(){
			return dealsBalance(
				this.user_deals.deals.map(idl=>idl.deal),
				this.$store.state.loggedInUser.id
			)
		}
	},

	methods: {
		setUser({user, inlinedeal}){
			console.log('UserDeals:setUser()', user)

			this.$emit('setUser', {user, inlinedeal, userDeals:this.user_deals})
		},
		del(index){
			if (this.user_deals.deals.length>1){
				this.user_deals.deals.splice(index,1)
			} else {
				this.$emit('delete')
			}
		}
	},

}
</script>











<style scoped>
.userDeals {
	/* border: 2px solid darkred; */

	/* margin: 2%;
	box-shadow: 0 0 4px gray;
	padding-top: .3%;
	padding-bottom: .3%;
	border-radius: 1em; */

	border-top: 1px solid #a9a9a9;
	padding-top: .3%;
	padding-bottom: .3%;
}
.userDeals:last-child {
	border-bottom: 1px solid #a9a9a9;
}


.userrow {
	text-align: left;
	/* padding-left: 2%; */
	padding: 0.3% 0 0.3% 2%;
	position: relative;
	font-size: 0;
}
.userrow:before {
	content: '';
	padding-top: 15.54%;
	display: block;
}
.userrow .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: inline-flex;
	align-items: center;
	padding-left: 3%;
	cursor: pointer;
}
.buttons {
	position:absolute;
	top: 50%;
	right: 5%;
	transform: translateY(-50%);
	user-select: none;
}
.dealsCount {
	font-size: 3em;
	color: darkgreen;
}
.debt {
	color: darkred;
}
a.btn {
	margin: 0 .3em;
}
a.btn.active {
	text-decoration: underline;
}

</style>
