<template lang="pug">
	.user-deals
		.userDeals(
			@click.stop='toggleOpen'
		)

			.col-50.border-r.border-lg.box-border
				.userrow2.flex.f-sp-btween.user_deal_2_left
					.content

						.btn.f-22(
							@click.stop='showMenu=!showMenu'
							@blur='showMenu=false'
							tabindex='0'
						) {{showMenu?'▼':'►'}}
							Hovermenu(v-show='showMenu')
								.menuitem.f-rem.tal(
									v-for='t in tabs'
									v-if='count_tab(t)'
									:key='t.name'
									:class='{"active": deals_tab==t.name}'
									@click='internalTab=t.name'
								) {{t.ru}}{{count_tab(t)}}

								.menuitem.f-rem.tal(
									@click='internalTab=""'
								) - сбросить

								.menuitem.f-rem.tal(
									@click='create_draft'
									style='color:white;background-color:rgb(3,109,183);'
								) + Новый Шаг




			.col-50
				.userrow2
					.content
						.f-22
							span(style='font-size:2em;') {{balance}}
						cell(:u='user_deals.user' :n='-2')


		InlineDeal(
			v-if='user_deals.open'
			v-for='idl in deals_sorted'
			:key='idl.deal.id'
			:inlinedeal='idl'
			:u='user_deals.user'
			@delete='delInlineDeal(idl.deal)'
		)

</template>




<script>
import {mapState} from 'vuex'

// import Bus from '@/bus.js'

import Cell from '@/components/Cell.vue'
import InlineDeal from '@/components/DealsView2/InlineDeal.vue'
import Hovermenu from '@/components/Hovermenu.vue'

import {
	loadJs,
	check,
	stripHtml,
} from '@/funcs'

import {
	dealsBalance,
	balance,
	isNonBalance,
	short,
	sortBy,
	// stateRu,
	tabs,
} from '@/deals'



export default {
	name: 'UserDeals2',

	components: {Cell, InlineDeal, Hovermenu},

	props: {
		user_deals: Array,
		half_row: {type: Boolean, default: false},
		right: {type: Boolean, default: false},
		tab: {type: String, default: 'actual'},
		filter: String,
		sort: {type: String, default: 'date'},
		order: {type: Number, default: -1}
	},

	data(){
		return {
			showMenu: false,
			tabs,
			internalTab: ''
		}
	},

	beforeCreate(){
		loadJs('/static/js/fuzzysort.js', null, window.fuzzball)
	},

	//// COMPUTED ////
	computed: {
		...mapState({log:'loggedInUser'}),

		deals_tab(){
			return this.internalTab || this.tab
		},

		deals_filtered(){
			const tab_name = this.deals_tab
			const tab = tabs.find(tab=>tab.name==tab_name)
			if (!tab) return []
			const myId = this.log.id
			return this.user_deals.deals.filter(idl=>{

				if (!tab.test(idl.deal, myId)) return false

				if (this.filter.length>1 && fuzzysort){
					if(!check(this.filter, idl.deal.dealgoods.map(dg=>{
						return this.$store.getters.goodById(dg.goodId).name
					}).join(' ') + ' ' + idl.deal.details))
						return false
				}

				return true
			})
		},

		deals_sorted(){
			const deals = this.deals_filtered
			deals.sort(sortBy(this.sort))
			if (this.order<0) deals.reverse()
			return deals
		},



		/* deals(){
			// const len = this.user_deals.deals.length
			// console.log('comp deals.length', len)
			const tab = tabs.find(tab=>tab.name==this.tab)
			if (!tab) return []
			const myId = this.log.id
			const vm = this
			const deals = this.user_deals.deals.filter(idl=>{
				if (!tab.test(idl.deal, myId)) return false
				if (vm.filter.length>1&&fuzzysort){
					if (!check(vm.filter, idl.deal.dealgoods.map(dg=>{
						return vm.$store.getters.goodById(dg.goodId).name
					}).join(' ')+' '+idl.deal.details))
						return false
				}
				return true
			})
			deals.sort(sortBy(this.sort))
			if (this.order<0) deals.reverse()
			return deals
		}, */

		lastDeal(){
			return this.deals_sorted.length ? this.deals_sorted[0].deal : null
		},

		balance(){
			return dealsBalance(
				this.user_deals.deals.map(idl=>idl.deal),
				this.log.id
			)
			// return dealsBalance(
			// 	this.user_deals.deals.map(idl=>idl.deal),
			// 	this.log.id
			// )
		},

		lastDealSum(){
			return this.lastDeal ? balance(this.lastDeal) : 0
		}
	},

	methods: {
		short(){
			return this.lastDeal ? short(this.lastDeal) : ''
		},

		toggleOpen(){
			console.log('UserDeals2 toggleOpen')
			this.user_deals.open=!this.user_deals.open
		},

		delInlineDeal(deal){

			// !!! can not delete by index !!!
			// 		 index is from computed deals list
			//		 if we want to delete from this.user_deals.deals
			//		 we need to get deal index from that deals list
			// console.log('delInlineDeal', index)
			// let deal = this.user_deals.deals[index]
			// console.log('deal', deal)
			// NB: this.user_deals.deals :: [inlinedeal]
			const index = this.user_deals.deals.findIndex(idl=>idl.deal===deal)

			if (index<0){
				notie({
					type: 'error',
					text: 'the deal to delete is not found'
				})
				return
			}

			if (this.user_deals.deals.length>1){
				this.user_deals.deals.splice(index, 1)
			} else {
				this.user_deals.deals.splice(index, 1)
				this.$emit('delete')
			}
		},

		create_draft(){
			console.log('UserDeals2.create_draft')
			const deal = {
				seller: this.log,
				sellerId: this.log.id,
				creator_is_seller: true,
				buyer: this.user_deals.user,
				buyerId: this.user_deals.user.id,
				counterpartId: this.user_deals.user.id,
				dealgoods: [{goodId:1, q:1, price:''}], // noGoodsDeal by default
				state: 'new',
				created: (new Date()).toISOString().slice(0,10),
				days: 30,
				details: ''
			}

			// this.$store.state.deals.push(deal)
			this.$store.commit('ADD_DEAL', deal)

			// not working
			// this.user_deals.push({
			// 	open: true,
			// 	deal
			// })
			this.$emit('adddeal', deal)



			// not firing cb 'cause we are already at 'deals' endpoint
			/* this.$router.push({name:"deals"}, function(route){
				// had to do it like that cause emiting event here
				// makes it emited before the BalanceView vm is created
				// and the event listnere is hooked up
				Bus.$once('open_deal', vm => {
					console.log('UserDeals2 Bus.$once', vm)
					vm.open_deal(deal, 'new')
				})
			}) */
			// need to call open_deal right here
			this.$emit('open_deal', deal)
		},

		count_tab(tab){

			// console.log('tab', tab)

			// return '++'




			if (!tab) return ""
			const myId = this.log.id

			// console.log('tab', tab)
			// const the_tab = tabs.find(t=>t.name==tab)

			// if (!the_tab) return ""

			const res = this.user_deals.deals.filter(idl=>tab.test(idl.deal, myId)).length

			if (!res) return ""

			return ` (${res})`
		},

		/* deals_x_tab(tab, deals, myId){
			if (!tab || !deals || !deals.length){
				return 0
			}
			return deals.filter(dl=>tab.test(dl,myId)).length
		} */
	}
}
</script>

<style src='@/components/DealsView2/style.css' scoped></style>
