<template lang="pug">
  .userlist
    UserRow(
      v-for='uid in uidlist'
      :key='uid'
      :user='$store.getters.userById(uid)'
    )
</template>





<script>
import UserRow from '@/components/UserRow.vue'

export default {
  name: 'UserList',
  components: {UserRow},
  props: ['uidlist']

}
</script>
