<template lang="pug">
	div

		//- LOGGED USER VS SOT ROW WITH TOTAL
		UserFullRow(style='border-bottom:1px solid lightgray;padding-top:10px;padding-bottom: 10px;')
			cell(:u='log' :n='-2' :hidescore='true' style='width:18.65%;position:absolute;top:50%;left:2%;transform:translateY(-50%);')
			img(src='/static/img/sot.png' style='height:calc(100% - 20px);position:absolute;right:4%;top:50%;transform:translateY(-50%);')
			.abs.f-22(style='left:50%;top:50%;transform:translate(-50%,-50%);')
				span(style='font-size:2em;') {{totalBalance}}

		//- FILTERING, SORTING, ORDERING CONTROLS
		.f-16(style='border-bottom:1px solid lightgray;padding:1%;display:flex;justify-content:space-evenly;')
			span.nowrap.usn.ph_4e.rel(
				@click='showTabsMenu=!showTabsMenu'
				tabindex='0'
				@blur='showTabsMenu=false'
				style='border-radius:4px;border:2px solid royalblue;display:inline-block;line-height:100%;'
			) {{tabRuName(tab).toLowerCase()}}

				Hovermenu.z-top.abs(v-show='showTabsMenu')
					.menuitem.f-rem.tal(
						v-for='t in tabs'
						:key='t.name'
						:class='{"active": tab==t.name}'
						@click='tab=t.name'
					) {{t.ru}}{{count_deals(t)}}

			span.nowrap.usn.ph_4e.rel(
				@click='showSortMenu=!showSortMenu'
				tabindex='0'
				@blur='showSortMenu=false'
				style='white-space:nowrap;border-radius:4px;border:2px solid royalblue;display:inline-block;line-height:100%;'
			) {{sortRuName(sort)}}{{order==-1?' (уб)':''}}

				Hovermenu.z-top.abs.r(v-show='showSortMenu')
					.menuitem.f-rem.tal(
						v-for="term in ['date','left','state','total','balance']"
						:key='term'
					)
						span(
							:class="{'active': sort==term && order==1}"
							@click="setSort(term,1)"
						) {{sortRuName(term)}}
						span(
							:class="{'active': sort==term && order==-1}"
								@click="setSort(term,-1)"
						) &nbsp;(уб)

			span.nowrap.usn.ph_4e.rel(style='border-radius:4px;border:2px solid royalblue;display:inline-block;line-height:100%;') новый

			input.f-16(placeholder='фильтр' v-model='filter' style='padding:0;margin:0;text-align:center;border:none;border-radius:4px;')


		//- DEALS
		div(v-for='deal in orderedDeals' :key='deal.id')
			UserFullRow(style='border-bottom:1px solid lightgray;padding:10px 2%;')
				div(style='position:absolute;top:0;left:50%;height:100%;border-right:1px solid lightgray;')

				.f-16(style='position:absolute;top:-4px;left:2px;')
					//- span(style='font-size:50%;') {{dealDate(deal.deal)}}
					span(style='font-size:50%;') {{dealDate(deal)}}

				.f-16(style='position:absolute;bottom:0px;left:2px;')
					//- span(style='font-size:50%;') № {{deal.deal.id}}
					span(style='font-size:50%;') № {{deal.id}}

				//- .f-16(style='position: absolute; bottom: 2%;' :style='dealStatusStyle(deal.deal)')
				.f-16(style='position: absolute; bottom: 2%;' :style='dealStatusStyle(deal)')
					//- span(style='font-size:60%;') {{dealStateRu(deal.deal)}}
					span(style='font-size:60%;') {{dealStateRu(deal)}}

				//- OPEN/CLOSE DEAL
				.f-16.abs.pointer.no-select(
					style='bottom: 2%;left:18%;color:#279AEE;'
					@click='deal.open = !deal.open'
				)
					img(
						:src='deal.open ? "static/img/close.svg" : "static/img/open.svg"'
						style='height:.6em;'
					)
					span(style='font-size:60%;') детали

				cell(:u='log' :n='-2' :hidescore='true', style='width:18.65%;position:absolute;top:50%;left:2%;transform:translateY(-50%);')

				//- .f-16(style='position:absolute;height:50%;width:30%;left: 20%;text-align:right;padding-right:1%;box-sizing:border-box;' :style='{"top":leftSizeInitiative(deal.deal)?"0":"50%"}')
				.f-16(style='position:absolute;height:50%;width:30%;left: 20%;text-align:right;padding-right:1%;box-sizing:border-box;' :style='{"top":leftSizeInitiative(deal)?"0":"50%"}')

					//- span(style='white-space:nowrap;max-width:85%;overflow:hidden;display:inline-block;text-overflow:ellipsis;vertical-align:middle;') {{ logIsSeller(deal.deal) ? dealShort(deal.deal) : ""}}
					span(style='white-space:nowrap;max-width:85%;overflow:hidden;display:inline-block;text-overflow:ellipsis;vertical-align:middle;') {{ logIsSeller(deal) ? dealShort(deal) : ""}}
					img(src='/static/img/arrow.svg' style='height: 20%;margin-left:5%;vertical-align:middle;')
					//- div(style='font-size:1.5em;') {{logIsSeller(deal.deal) ? "("+total(deal.deal)+")" : total(deal.deal)}}
					div(style='font-size:1.5em;') {{logIsSeller(deal) ? "("+total(deal)+")" : total(deal)}}



				//- cell(:u='deal.user' :n='-2' :hidescore='true' style='width:18.65%;position:absolute;top:50%;right:2%;transform:translateY(-50%);')
				cell(:u='deal.counterpart' :n='-2' :hidescore='true' style='width:18.65%;position:absolute;top:50%;right:2%;transform:translateY(-50%);')

				//- .f-16(style='position:absolute;height:50%;width:30%;left: 50%;text-align:left;padding-left:1%;' :style='{"top":leftSizeInitiative(deal.deal)?"50%":"0"}')
				.f-16(style='position:absolute;height:50%;width:30%;left: 50%;text-align:left;padding-left:1%;' :style='{"top":leftSizeInitiative(deal)?"50%":"0"}')

					img(src='/static/img/arrow.svg' style='height:20%;margin-right:5%;transform:scaleX(-1);vertical-align:middle;')
					//- span(style='white-space:nowrap;max-width:85%;overflow:hidden;display:inline-block;text-overflow:ellipsis;vertical-align:middle;') {{logIsSeller(deal.deal)? "":dealShort(deal.deal)}}
					span(style='white-space:nowrap;max-width:85%;overflow:hidden;display:inline-block;text-overflow:ellipsis;vertical-align:middle;') {{logIsSeller(deal)? "":dealShort(deal)}}
					//- div(style='font-size:1.5em;') {{logIsSeller(deal.deal) ? total(deal.deal) : "(" + total(deal.deal) + ")"}}
					div(style='font-size:1.5em;') {{logIsSeller(deal) ? total(deal) : "(" + total(deal) + ")"}}

			OpenDeal2(v-if='deal.open' :deal='deal')




		//-
			.userrow.border-b.border-lg
				.content
				//-
					div(style='width:119px;')
						cell(:u='log' :n='-2' style='width:100%;')

				//- .col-50.f-22.tac
					.f-22 {{totalBalance}}

				//- tabs button
					span.nowrap.f-22.bg-gray-d.rad-1e.usn.pv_1e.ph_4e.rel(
						@click='showTabsMenu=!showTabsMenu'
						tabindex='0'
						@blur='showTabsMenu=false'
					) {{tabRuName(tab)}}

						Hovermenu.z-top.abs.r(v-show='showTabsMenu')
							.menuitem.f-rem.tal(
								v-for='t in tabs'
								:key='t.name'
								:class='{"active": tab==t.name}'
								@click='tab=t.name'
							) {{t.ru}}{{count_deals(t)}}

				//- sort button
					span.nowrap.f-22.bg-gray-d.rad-1e.usn.pv_1e.ph_4e.rel(
						@click='showSortMenu=!showSortMenu'
						tabindex='0'
						@blur='showSortMenu=false'
						style='white-space:nowrap;'
					) {{sortRuName(sort)}}{{order==-1?' (уб)':''}}

						Hovermenu.z-top.abs.r(v-show='showSortMenu')
							.menuitem.f-rem.tal(
								v-for="term in ['date','left','state','total','balance']"
								:key='term'
							)
								span(
									:class="{'active': sort==term && order==1}"
									@click="setSort(term,1)"
								) {{sortRuName(term)}}
								span(
									:class="{'active': sort==term && order==-1}"
										@click="setSort(term,-1)"
								) &nbsp;(уб)

				//- filter
					input.border-0.rad_5e.p_5e.no-outline.tac.bg-gray-d.smooth-color.bg-focus-white(
						placeholder='фильтр'
						v-model='filter'
					)



		//- SORT
			.p-1e.border-b.border-lg
				.tac.f-22
					a.btn.mh_2e.active(@click='order*=-1') {{order>0?'по возр.':'по уб.'}}
					a.btn.mh_2e(:class='{"active": sort=="date"}' @click='sort="date"') по дате
					a.btn.mh_2e(:class='{"active": sort=="left"}' @click='sort="left"') по сроку
					a.btn.mh_2e(:class='{"active": sort=="state"}' @click='sort="state"') по статусу
					a.btn.mh_2e(:class='{"active": sort=="total"}' @click='sort="total"') по сумме
					a.btn.mh_2e(:class='{"active": sort=="balance"}' @click='sort="balance"') по балансу

		//- TABS
			.f-rem.p-6.border-b.border-lg

				template(v-if='!showFilters')
					.tal.f-2e
						.tab(
							@click='showFilters=true'
						) {{tabRuName(tab)}}

				template(v-else)
					.tal.f-2e(
						v-for='t in tabs'
						:key='t.name'
						@click='showFilters=false'
					)
						.tab(
							:class='{"active": tab==t.name}'
							@click='tab=t.name'
						) {{t.ru}}{{count_deals(t)}}


		//-
			.list
				div(
					v-for='(ud, index) in list'
					:key='ud.user.id'
					@click='ud.open=!ud.open'
				)
					UserDeals(
						:user_deals='ud'
						:tab='tab'
						:filter='filter'
						:sort='sort'
						:order='order'
						@delete='delUserDeals(index)'
						@adddeal='addDeal(index, $event)'
						@open_deal='open_deal'
					)



</template>









<script>
import {mapState, mapGetters} from 'vuex'

import Cell from '@/components/Cell.vue'
import UserDeals from '@/components/UserDeals2.vue'
// import UserDealsHalf from '@/components/UserDeals2HalfRow.vue'
// import InlineDeal from '@/components/InlineDeal2.vue'
import Hovermenu from '@/components/Hovermenu.vue'
import UserFullRow from '@/components/UserFullRow.vue'
// import GoodsList from '@/components/GoodsList.vue'
import OpenDeal2 from '@/components/OpenDeal2.vue'

import {
	ensureCounterpartId,
	tabs,
	total,
	dealState,
	stateRu,
	sortDealsBy,
	short,
  isNoGoodsDeal,
} from '@/deals'





export default {
	name: 'DealsView2',

	components: {
		UserDeals,
		Cell,
		// InlineDeal,
		Hovermenu,
		UserFullRow,
		// GoodsList,
		OpenDeal2,
	},

	data(){
		return {
			// selectedUserDeals: null,
			// list: [],
			// allDeals: [],
			tabs,
			tab: 'all',
			filter: '',
			sort: 'date',
			order: -1,
			showFilters: false,
			showTabsMenu: false,
			showSortMenu: false,
		}
	},

	created(){
		// const list = this.list
		// const allDeals = this.allDeals
		// console.log('DealsView2.created')
		// console.log('store.loggedInUser', this.$store.state.loggedInUser)
		// const myId = this.$store.state.loggedInUser.id

		// this.$store.state.deals.forEach(deal=>{
		// 	ensureCounterpartId(deal, myId)

		// 	const inlineDeal = {deal, open: false}

		// 	let userDeals = list.find(ud=>
		// 		ud.user.id==deal.counterpartId)

		// 	if (!userDeals){
		// 		userDeals = {
		// 			user: deal.counterpartId
		// 				? this.$store.getters.userById(deal.counterpartId)
		// 				: {id: null},
		// 			open: false,
		// 			deals: []
		// 		}
		// 		list.push(userDeals)
		// 	}
		// 	userDeals.deals.push(inlineDeal)
		// })
	},

	computed: {
		...mapState({log:'loggedInUser'}),

		...mapGetters(['totalBalance']),

		/* listMinusSelected(){
			if (!this.selectedUserDeals) return this.list
			return this.list.filter(uds => this.selectedUserDeals!==uds)
		} */

		curTab(){
			return tabs.find(item => item.name == this.tab)
		},

		// deals(){
		// 	const sortFunc = sortDealsBy(this.sort)
		// 	const deals = this.list.flatMap(userDeals => {
		// 		return userDeals.deals.map(deal => {
		// 			return {...deal, user:userDeals.user}
		// 		})
		// 	}).filter(item => this.curTab.test(item.deal)).sort((a,b)=>{
		// 		return sortFunc(a.deal,b.deal)
		// 	})
		// 	if (this.order<0){
		// 		deals.reverse()
		// 	}
		// 	return deals
		// },

		filteredDeals(){
			return this.$store.state.deals.filter(this.curTab.test)
		},

		sortedDeals(){
			return this.filteredDeals.sort(sortDealsBy(this.sort))
		},

		orderedDeals(){
			if (this.order < 0){
				return [].concat(this.sortedDeals).reverse()
			}
			return this.sortedDeals
		},

	},

	methods: {
		isNoGoodsDeal,

		/* select(uds){
			// console.log('select', uds)
			this.selectedUserDeals = uds
		}, */

		setSort(term, ord){
			this.order = ord || 1
			this.sort = term
		},

		// delUserDeals(index){
		// 	this.list.splice(index, 1)
		// },

		// addDeal(index, deal){
		// 	console.log('list', this.list)

		// 	const ud = this.list[index]
		// 	console.log('ud', ud)

		// 	ud.deals.push({
		// 		deal,
		// 		open: true
		// 	})
		// },

		// open_deal(deal, tab){
		// 	console.log('DealsView2 open_deal', tab)
		// 	if (tab) this.tab = tab
		// 	else this.tab = 'new'

		// 	this.list.forEach(ud=>{

		// 		ud.deals.forEach(idl=>{
		// 			if (idl.deal===deal){
		// 				this.selectedUserDeals = ud
		// 				ud.open=true
		// 				idl.open=true
		// 			} else {
		// 				idl.open=false
		// 			}
		// 		})

		// 	})
		// },

		count_deals(tab){
			if (!tab) return ""
			const myId = this.log.id
			// const res = this.list.reduce((m, userDeals)=>{
			// 	return m + userDeals.deals.filter(idl=>tab.test(idl.deal, myId)).length
			// }, 0)
			const res = this.$store.state.deals.filter(deal => tab.test(deal, myId)).length
			return ` (${res})`
		},

		tabRuName(tab){
			return tabs.find(t=>t.name==tab).ru
		},

		sortRuName(sort){
			return {
				date: 'по дате',
				left: 'по сроку',
				state: 'по статусу',
				total: 'по сумме',
				balance: 'по балансу',
			}[sort]
		},

		dealDate(deal) {
			const date = deal.accepted || deal.created || "0000-00-00"
			return date.split('-').slice(1).reverse().join('.')
		},

		leftSizeInitiative(deal) {
			return deal.creator_is_seller == (deal.sellerId == this.log.id)
		},

		dealShort: short,

		// dealShort(deal){
		// 	return deal.dealgoods.map(dg => {
		// 		const good = this.$store.getters.goodById(dg.goodId)
		// 		if (!good) return "good "+dg.goodId
		// 		return dg.q + " " + (dg.unit || "шт") + " " + good.name
		// 	}).join(', ') || deal.details
		// },

		logIsSeller(deal){
			return deal.sellerId == this.log.id
		},

		total,

		dealStatusStyle(deal){
			return this.leftSizeInitiative(deal)
				? {right: "51%"}
				: {left: "51%"}
		},

		dealStateRu(deal){
			return stateRu(dealState(deal))
		}
	}
}
</script>








<style scoped>

.userrow {
	/* text-align: left; */
	/* padding-left: 2%; */
	/* padding: 0.3% 0 0.3% 2%; */
	position: relative;
	font-size: 0;
}
.userrow:before {
	content: '';
	padding-top: 15.54%;
	display: block;
}
.userrow .content,
.userrow2 .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* display: inline-flex; */
	align-items: center;
	/* padding-left: 3%; */
	padding: 0 3% 0 3%;
	cursor: pointer;

	display: flex;
	justify-content: space-between;
}
.userrow2 .content {
	padding-left: 6%;
	/* padding-right: 3%; */
}
.userrow2 .content.content-right {
	padding-left: 3%;
	padding-right: 6%;
}

.userrow2 {
	/* text-align: left; */
	/* padding-left: 2%; */
	/* padding: 0.3% 0 0.3% 2%; */
	position: relative;
	font-size: 0;
}
.userrow2:before {
	content: '';
	padding-top: 31.08%;
	display: block;
}



.tab {
	display: inline-block;
	cursor: pointer;
	color: blue;
}
.tab:hover {
	color: lightblue;
}
.tab.active {
	text-decoration: underline;
}

.list:last-child {
	padding-bottom:25%;
}

a.btn.active {
	text-decoration: underline;
}

.menuitem span:hover {
	background-color: #ddd;
}

.menuitem span.active {
	background-color: #87cefa;
}


</style>
