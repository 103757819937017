<template lang="pug">
	.user-deals.f-16(v-if='deals&&deals.length')
		.userrow
			.content(@click='toggleOpen')
				Cell(:u='log' :n='0' style='position:absolute;left:0;top:1%;')
				.f-22
					.balance {{balance}}
					.deals-count {{deals.length}} из {{deals.length}}
				Cell(:u='user' :n='-2' style='position:absolute;right:0;top:1%;')

		transition(name='slide')
			.deals(v-if='open')
				.f-22(style='margin:1% 2%;')
					.newdeal_row
						.export_arrow(@click='createDraft') экспорт

						//- div
							.deal-color(
								v-for='(aTab, i) in ["drafts", "myoffers", "actual", "outdated", "fulfilled"]'
								:key='aTab'
								:style='{"background-color": stateColors[tabs.find(t=>t.name==aTab).state].title }'
								:class='{"deal-color--selected": aTab == tab }'
							) {{i+1}}

						.import_arrow(@click='createImportDraft') импорт

				div(v-for='deal in deals' :key='deal.id')
					InlineDeal(:deal='deal')
					.inline-deal-date {{dateStr(deal)}}
					OpenDeal32(v-if='deal.open' :deal='deal' @delete='del(deal.id)')
					//- OpenDeal(v-if='deal.open' :deal='deal' @delete='del(deal.id)')

</template>








<script>
import Cell from '@/components/Cell.vue'
// import InlineDeal3 from '@/components/InlineDeal3.vue'
// import InlineDeal from '@/components/InlineDeal3/index.vue'
import InlineDeal from '@/components/InlineDeal32.vue'
// import OpenDeal from '@/components/OpenDeal3.vue'
import OpenDeal32 from '@/components/OpenDeal32.vue'

import {
	tabs,
	// balance,
	// nonBalanceDeals,
	// daysLeft,
	// dealDate,
	// State,
	// dealState,
	// total,
	dealsBalance,
	sortBy,
	sortDealsBy,
	stateColors,
	dealDate2,
} from '../deals'

import {
	loadJs,
	check
} from '../funcs'


export default {
	name: 'UserDeals3',

	components:{
		Cell,
		InlineDeal,
		// OpenDeal,
		OpenDeal32,
	},

	props: [
		'user',
		'tab',
		'filter',
		'sort',
		'order'
	],

	beforeCreate(){
		loadJs('/static/js/fuzzysort.js', null, window.fuzzball)
	},

	data(){
		return {
			// sort: 'left', // date|left|state|total|balance
			// order: 1 // 1 - stright | -1 - reversed
			update: 0,
			open: this.user.dealsOpen===true
		}
	},

	computed: {
		log(){
			return this.$store.state.loggedInUser
		},

		deals(){
			const tab = tabs.find(tab=>tab.name==this.tab)
			if (!tab) return []
			const myId = this.$store.state.loggedInUser.id
			const vm = this
			const deals = this.$store.state.deals.filter(deal=>{
				if (deal.counterpartId!=vm.user.id) return false
				if (!tab.test(deal, myId)) return false
				if (vm.filter.length>1&&fuzzysort){
					if (!check(vm.filter, deal.dealgoods.map(dg=>{
						return vm.$store.getters.goodById(dg.goodId).name
					}).join(' ')+' '+deal.details)){return false}
				}
				if (deal.inlineDealOpen==void 0)
					deal.inlineDealOpen=false
				return true
			})
			deals.sort(sortDealsBy(this.sort))
			if (this.order<0) deals.reverse
			return deals
		},

		/* deals_(){
			const update = this.update
			const tab = tabs.find(tab=>tab.name==this.tab)
			if (!tab) return []
			const myId = this.$store.state.loggedInUser.id
			const vm = this
			const deals = this.user_deals.deals.filter(idl=>{
				if (!tab.test(idl.deal, myId)) return false
				if (vm.filter.length>1&&fuzzysort){
					if (!check(vm.filter, idl.deal.dealgoods.map(dg=>{
						return vm.$store.getters.goodById(dg.goodId).name
					}).join(' ')+' '+idl.deal.details))
						return false
				}
				return true
			})
			deals.sort(sortBy(this.sort))
			if (this.order<0) deals.reverse()
			return deals
		}, */

		balance(){
			return dealsBalance(
				this.deals,
				this.log.id
			)
		},

		stateColors() {return stateColors},

		tabs() {return tabs},
	},

	methods: {
		dateStr(deal) {return dealDate2(deal)},

		toggleOpen(){
			this.user.dealsOpen = !this.user.dealsOpen
			this.open = this.user.dealsOpen
		},

		createDraft(){

			this.$store.dispatch('get_user_goods', this.log.id).then(() => {
				const deal = {
					id: Date.now(),
					seller: this.log,
					sellerId: this.log.id,
					creator_is_seller: true,
					buyer: this.user,
					buyerId: this.user.id,
					creator: this.log,
					creatorId: this.log.id,
					counterpart: this.user,
					counterpartId: this.user.id,
					dealgoods: [{goodId:1, q:1, price:0}],
					state: 'new',
					created: (new Date()).toISOString().slice(0,10),
					days: 30,
					details: '',
					inlineDealOpen: true,
					open: true,
					imgs: [],
				}
				this.$store.commit('ADD_NEW_DEAL', deal)
				this.$emit('settab','new')
			})

		},

		createImportDraft() {
			const deal = {
				id: Date.now(),
				buyer: this.log,
				buyerId: this.log.id,
				creator_is_seller: false,
				seller: this.user,
				sellerId: this.user.id,
				creator: this.log,
				creatorId: this.log.id,
				counterpart: this.user,
				counterpartId: this.user.id,
				dealgoods: [{goodId:1, q:1, price:0}],
				state: 'new',
				created: (new Date()).toISOString().slice(0,10),
				days: 30,
				details: '',
				inlineDealOpen: true,
				open: true,
				imgs: [],
			}
			this.$store.commit('ADD_NEW_DEAL', deal)
			this.$emit('settab', 'new')
		},

		can_be_deleted(deal){
			return ['new','draft','offered'].includes(deal.state)
		},

		del(deal_id){
			if (this.user_deals.deals.length>1){
				this.update = this.update + 1
				const index = this.user_deals.deals.findIndex(d=>d.id==deal_id)
				if (index>=0)
					this.user_deals.deals.splice(index,1)
				else
					console.log('delete deal error:deal not found, deal_id:',deal_id)
			} else {
				this.$emit('delete')
			}
		},

		wrong_del(index){
			console.log('this is a wrong place to be')
		},
	}
}
</script>








<style scoped>
.user-deals {
	/* background: lightblue; */
	/* border-bottom: 1px solid #a9a9a9; */
	border-top: 1px solid #a9a9a9;
}

.userrow {
	position: relative;
	text-align: left;
	margin: 1% 2%;
	font-size: 0;

	/* background: lightgoldenrodyellow; */
}
.userrow:before {
	content: '';
	padding-top: 15.54%;
	display: block;
}
.userrow .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* display: inline-flex; */
	/* align-items: center; */
	/* padding-left: 3%; */
	cursor: pointer;
	/* justify-content: space-between; */
	text-align: center;
}
.userrow .content .balance {
	font-size: 4em;
	line-height: 1em;
}

.deals {
	overflow: hidden;
}

.deals-count {
	font-size: 80%;
	color: #a9a9a9;
}


.inline {
	display:flex;
	align-items: center;
	justify-content: space-between;
	background: #ddd;
	border-radius: 1em;
	overflow: hidden;
}
.inline div {
	padding: .1em .5em;
	font-size: 150%;
	min-width: 1em;
	position: relative;
}
.inline .left {
	background: #f24537;
	color: white;
}
.inline .mid {
	margin: 0 auto;
	/* background: gray; */
	/* color: lightgray; */
	border-radius: 1em;
	/* cursor: pointer; */
	/* user-select: none; */
	font-size: 110%;
}

.inline .right {
	background: #1abc9c;
	color: white;
}
.inline .left:after {
	content: '';
	display: block;
	position: absolute;
	left: 100%;
	top: 50%;
	width: 0;
	border: 50% solid transparent;
	border-left-color: red;
}



.slide-enter-active,
.slide-leave-active {
	/* transition: max-height 2s ease-out; */
	transition: transform .1s linear, opacity .1s ease-out;
}
.slide-enter,
.slide-leave-to {
	/* max-height: 0; */
	transform: scaleY(.9);
	opacity: 0;
}
.slide-enter-to,
.slide-leave {
	/* max-height: auto; */
	transform: scaleY(1);
	opacity: 1;
}

.newdeal_row {
	display: flex;
	justify-content: space-between;
}

.export_arrow,.import_arrow {
	display: inline-block;
	background-color: forestgreen;
	color: white;
	position: relative;
	padding: 0 .5em;
	line-height: 2em;
	height: 2em;
	user-select: none;
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(0,0,0,.5);
}

.export_arrow::after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	left: 100%;
	height: 0;
	border-top: transparent solid 1em;
	border-bottom: transparent solid 1em;
	border-left: 1em solid forestgreen;
}

.import_arrow::after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	right: 100%;
	height: 0;
	border-top: 1em solid transparent;
	border-bottom: 1em solid transparent;
	border-right: 1em solid forestgreen;
}

.deal-color {
	display: inline-block;
	height: 2em;
	width: 2em;
	border-radius: .5em;
	box-shadow: 0 2px 4px rgba(0,0,0,.25);
	line-height: 2em;
	border: 2px solid transparent;
	box-sizing: border-box;
}
.deal-color + .deal-color {
	margin-left: .5em;
}

.deal-color--selected {
	border-color: green;
}

.inline-deal-date {
	color: dimgray;
	/* margin-top: -.8em; */
	/* margin-bottom: 1em; */
	line-height: 1em;
	margin-bottom: 1em;
}
</style>
