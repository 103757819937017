export function allowOnlyNums(input) {
  let old, start, end
  const re = /^\d*[,.]*\d*$/

  function test(e) {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      ",",
      ".",
    ]

    if ((e.type == "input" && !re.test(e.data)) || (["keydown", "keyup"].includes(e.type) && !allowedKeys.includes(e.key) && !re.test(e.key))) {
      e.preventDefault()
      return
    }

    if (re.test(this.value)) {
      old = this.value
      start = this.selectionStart
      end = this.selectionEnd
    } else if (old) {
      this.value = old
      this.setSelectionRange(start, end)
    } else {
      this.value = ""
    }
  }

  const events = [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop"
  ]

  events.forEach(event => {
    input.addEventListener(event, test)
  })

  return () => {
    events.forEach(event => {
      input.removeEventListener(event, test)
    })
  }
}
