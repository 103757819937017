<template lang="pug">
  .field(:style="{background:editing?'lightgoldenrodyellow':''}")

    .col-20.f-22.dimgray.vat
      slot label

    //- JUST SHOWING
    .col-80(v-if='!editing')
      .col-80.f-16(style='padding-top:0.35em;')
        span.comma-sep(v-for='id in list') {{itemName(id)}}

      //- BUTTONS
      .col-20.f-16.tar.vat(
        v-if='editable'
        style='line-height:1.9em;'
      )
        span.btn(@click.stop='edit') изменить

    //- EDITING
    .col-80(v-else-if='editable')
      .col-70.f-16(@blur='quit', style='line-height:1.8em;white-space:wrap;overflow-x:hidden;')
        //- LIST
        div(v-for='(id, index) in temp', style='line-height:2.2em;')
          span.item_edit {{itemName(id)}}
          span.item_del(@click.prevent='del(index)') ✕
        //- NEW ITEM
        div
          input.f-16(
            v-focus,
            style='width:80%',
            @keydown.enter.prevent='add',
            v-model='draft',
            :list='listname')
          datalist(:id='listname')
            option(v-for='i in $store.state[listname]', :value='i.name')
          span.item_plus(@click='add') +

      //- BUTTONS
      .col-30.f-16.tar(style='vertical-align:top;line-height:1.9em;')
        span.btn(@mousedown.prevent @click.stop='save') готово
        span.btn(@mousedown.prevent @click.stop='quit') отмена

</template>



<script>
import Vue from 'vue'
import {listsEq, clone} from '@/funcs.js'

export default {
  name: 'WordsEdit',
  props: ['list', 'listname', 'editable'],

  data(){
    return {
      editing: false,
      temp: clone(this.list)||[],
      draft: '',
      before_edit: null
    }
  },

  directives: {
    focus: {
      inserted(el){
        el.focus()
      }
    }
  },

  methods: {
    edit(){
      this.before_edit = clone(this.temp)
      this.editing = true
      this.draft = ''
    },
    save(){
      this.add()
      this.editing = false
      if (!listsEq(this.temp, this.list)) {
        this.$emit('change', clone(this.temp))
      }
    },
    quit(){
      this.editing = false
      this.draft = ''
      this.temp = this.before_edit
    },
    del(i){
      this.temp.splice(i, 1)
    },
    add(){
      this.draft = this.draft.trim()
      if (this.draft=='') return

      // check if we are trying to add smth that is already there
      if (this.temp.find(id=>this.itemName(id).toLowerCase()==this.draft.toLowerCase())) return

      // this.$store.dispatch('add_to_list', {listname:this.listname, name:this.draft}).then(id=>{this.temp.push(id)})
      const item = this.$store.state[this.listname].find(i=>i.name==this.draft)
      if (item)
        this.temp.push(item.id)
      else
        this.temp.push(this.draft)

      this.draft = ''
    },
    itemName(id){
      if (typeof id=='number')
        return this.$store.state[this.listname].find(i=>i.id==id).name
      if (typeof id=='string'&&!/^\d+$/.test(id))
        return id
    }
  }
}
</script>




<style scoped>
.field {
  border-bottom: 1px solid darkgray;
  padding: 1% 2%;
  font-size: 0;
  user-select: none;
  /* height: 1em; */
}
.field input {
  margin: 0;
  padding: 0;
  border: none;
  /* width: 100%; */
  background: transparent;
  background: lightblue;
  border-radius: .4em 0 0 .4em;
  /* padding: .15em 0; */
  text-align: center;
  line-height: 1.8em;
}
.field input:focus {
  outline: none;
  /* background: #f8f8f8; */
}

span.input:focus {
  outline: none;
}

.item_edit {
  background: lightgray;
  border-radius: .4em 0 0 .4em;
  padding: .2em .3em .2em .5em;
}
.item_del {
  background: darkgray;
  color: white;
  cursor: pointer;
  user-select: none;
  margin-right: .5em;
  padding: .2em .3em .2em .2em;
  border-radius: 0 .4em .4em 0;
}
.item_del:hover {
  background: gray;
}

.item_plus {
  background: lightgreen;
  color: darkgreen;
  cursor: pointer;
  user-select: none;
  padding: .2em .4em .2em .2em;
  border-radius: 0 .4em .4em 0;
  font-weight: bold;
}
.item_plus:hover {
  background: green;
  color: lightgreen;
}

span.btn {
  cursor: pointer;
  color: mediumblue;
  margin-left: 1em;
}
span.btn:hover {
  text-decoration: underline;
}
</style>
