import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import {
  scoreGroup,
  score,
  getCookie,
  defragment,
  someName,
  getPath,
  oneOf,
  deltaObj,
  clone
} from '@/funcs'
import {
	// daysLeft,
	// State,
	// total,
	// tabs,
	nonBalanceDeals,
  balance,
  // ensureCounterpartId,
  isEditable,
} from '@/deals'
// import Deal from '@/models/Deal'


Vue.config.devtools = true
Vue.use(Vuex)

// const fuse = new Fuse(users, {
//   shouldSort: true,
//   threshold: 0.6,
//   location: 0,
//   distance: 100,
//   maxPatternLength: 32,
//   minMatchCharLength: 1,
//   keys:['lastName','firstName','userid'],
//   id: 'id'
// })



/** POST data object to url
 * post payload dict object to url
 * payload is json.stringiryed
 * if response status is 200(OK)
 * cb function is called with response data as argument
 * !!! for cb to be called on response data:
 *     server side view must include reqId key-value pair
 *     to the response json object
 */
const Q = []
// this one returns a Promise
function post(url, payload, cb){
  return new Promise((done, fail)=>{
    const reqId = Date.now()
    Q.push({reqId, cb})
    const r = new XMLHttpRequest()
    r.open('POST', url, true)
    r.setRequestHeader('Content-Type', 'application/json')
    r.onreadystatechange = function(){
      if (r.readyState==4){
        if (r.status==200){
          const data = JSON.parse(r.responseText)
          const reqIndex = Q.findIndex(i=>i.reqId==data.reqId)
          if (reqIndex>=0){
            if (Q[reqIndex].cb && typeof(Q[reqIndex].cb)=='function')
              Q[reqIndex].cb(data);
            Q.splice(reqIndex, 1)
          }
          if (data.status=='OK'){
            return done(data)
          } else {
            return fail(data)
          }
        } else {
          return fail(r)
        }
      }
    }
    r.setRequestHeader('X-CSRFToken', getCookie('csrftoken'))
    payload.reqId = reqId
    r.send(JSON.stringify(payload))
  })
}

/*
  data :: {deal, action: 'save'}
  deal :: {
    'id' :: Int,
    'sellerId' :: Int,
    'buyerId' :: Int,
    'creator_is_seller' :: Bool,
    'state' :: Str,
    'created' :: Str,
    'dealgoods' :: [{id, price, q, goodId, usergoodId}],
    'details' :: Str,
    'days' :: Int,
    'imgs' :: [{id, url}],
  }
*/
function postData(url, data) {
  return new Promise((done, fail) => {
    const r = new XMLHttpRequest()
    const f = new FormData()
    toFormDataField(f, '', data)
    r.addEventListener('load', e => {
      const data = JSON.parse(r.responseText)
      if (data.status == 'OK') {
        return done(data)
      } else {
        return fail(data)
      }
    })
    r.addEventListener('error', fail)
    r.open('POST', url)
    r.setRequestHeader('X-CSRFToken', getCookie('csrftoken'))
    r.send(f)
  })
}

function toFormDataField(fd, name, value) {
  if (Array.isArray(value)) {
    value.forEach((el, i) => toFormDataField(fd, name + ':' + i, el))
  } else if (typeof value == 'object' && value !== null && value.constructor === Object) {
    Object.entries(value).forEach(([k,v]) => toFormDataField(fd, name + ':' + k, v))
  } else {
    fd.set(name, value)
  }
  return fd
}


const changeHistory = []
const undoHistory = function(commit, tilTimestamp){
  const toUndo = changeHistory.filter(i=>i.timestamp>=tilTimestamp)
  toUndo.sort((a,b)=>a.timestamp<b.timestamp)
  toUndo.forEach(change=>clearTimeout(change.timer))
  toUndo.forEach(change=>{
    commit('APPLY_CHANGE', change.undo)
    change.applied = false
  })
}
const clearHistory = function(tilTimestamp){
  const toClear = changeHistory.filter(i=>i.timestamp<=tilTimestamp)
  if (toClear.every(e => e.accepted)){
    let i
    while (i = toClear.pop()){
      changeHistory.splice(changeHistory.findIndex(c=>c.timestamp==i.timestamp), 1)
    }
  }
}
const redoHistory = function(commit, tilTimestamp){
  const til = changeHistory.filter(i=>i.timestamp<=tilTimestamp&&i.applied==false)
  til.sort((a,b)=>a.timestamp>b.timestamp)
  if (til.every(i=>i.accepted&&!i.rejected)){
    til.forEach(i=>{
      commit('APPLY_CHANGE', i.change)
      i.applied = true
    })
  }

}





function pop_error(msg){
  notie.alert({
    type:'error',
    text: msg
  })
}
function pop_ok(msg){
  notie.alert({
    type:'success',
    text: msg
  })
}
function pop_info(msg){
  notie.alert({
    type:'info',
    text: msg
  })
}
function pop_warn(msg){
  notie.alert({
    type:'warning',
    text: msg
  })
}

// users.forEach(user=>{
//   user.searched = [
//     user.interests ? user.interests.map(i=>interests.find(ii=>ii.id==i).name).join(' ') : '',
//     user.offers ? user.offers.map(i=>goods.find(g=>g.id==i).name).join(' ') : ''
//   ].join(' ')
// })





const store = new Vuex.Store({
  state: {
    users:[],
    interests:[],
    goods:[],
    // usergoods:[],
    deals:[],
    loggedInUser: null,
    selectedCell: null,
    deals_details_loaded: false,
    map_edit_mode: false,
    tradeMatches: {offers:[], bids:[]},
  },

  getters: {
    // USER
    // userById: state => id => state.users.find(u=>u.id==id),
    userById: findInState('id', 'users'),

    // userBySlug: state => slug => state.users.find(u=>u.slug==slug),
    userBySlug: findInState('slug', 'users'),

    topUsers: state => {
      const users = state.users.filter(u=>!u.deleted)
      users.sort((a,b) => score(b)-score(a))
      return users
    },

    currentUser(state, getters){
      const routeName = router.app.$route.name

      if (['home','bag','all','profile','score','deals'].includes(routeName)){
        if (state.loggedInUser)
          return state.loggedInUser
        else
          return {
            lastName: 'Sot',
            firstName: '',
            city: 'Красноярск',
            score: [[state.users.length],[],[]], // score contains lists of ids, users.length woun't work
            map: getters.topUsers.slice(0,36).map(u=>u.id)
          }
      }

      let user = {}

      if (['map', 'other_profile', 'other_score'].includes(routeName)){
        user = getters.userBySlug(router.app.$route.params.slug)
        if (user && user.deleted)
          user = {
            lastName: 'Аккаунт',
            firstName: 'Удален',
            city: '--',
            score: [[],[],[]],
            map: getters.topUsers.slice(0,36).map(u=>u.id)
          }
      }

      if (['map_by_id', 'profile_by_id', 'score_by_id'].includes(routeName)){
        user = getters.userById(router.app.$route.params.id.slice(2))
        if (user && user.deleted)
          user = {
            lastName: 'Аккаунт',
            firstName: 'Удален',
            city: '--',
            score: [[],[],[]],
            map: getters.topUsers.slice(0,36).map(u=>u.id)
          }
      }

      return user
    },

    // searchUsers: state => searchString => fuse.search(searchString),



    // MAP
    currentMap(state, getters){
      return getters.currentUser.map ? getters.currentUser.map : []
    },

    canAddToBag: state => uid => {
      if (!state.loggedInUser) return false
      if (!uid) return false
      if (state.loggedInUser.id==uid) return false
      // check bag
      if (state.loggedInUser.bag.includes(parseInt(uid)))
        return false

      // check map
      if (state.loggedInUser.map.includes(parseInt(uid)))
        return false

      return true
    },



    // GOOD
    // goodById: state => id => state.goods.find(g=>g.id==id),
    goodById: findInState('id', 'goods'),

    goodByName: state => name => state.goods.find(g=>g.name.toLowerCase()==name.trim().toLowerCase()),

    // usergoodById: state => id => state.usergoods.find(ug=>ug.id==id),
    usergoodById: findInState('id', 'usergoods'),



    // DEAL
    // dealById: state => id => state.deals.find(d=>d.id==id),
    dealById: findInState('id', 'deals'),

    totalBalance(state){
      if (!state.loggedInUser) return 0
      const logId = state.loggedInUser.id
      return state.deals.reduce((m, deal) => {
        if (nonBalanceDeals.includes(deal.state)) return m
        const sign = logId==deal.sellerId?-1:1
        return m + sign*balance(deal)
      }, 0)
    },
  },

  mutations: {
    // USER
    ADDUSER(state, user){
      state.users.push(user)
    },

    LOGINUSER(state, user){
      // state.loggedInUser = user
      state.loggedInUser = state.users.find(u=>u.id==user.id)
    },

    UPDATEUSERS({users}, _users){
      _users.forEach(_user => {
        const user = users.find(u=>u.id==_user.id)
        if (user)
          for (let k in _user) user[k] = _user[k]
        else
          users.push(_user)
      })
    },

    UPDATE_PROFILE({loggedInUser}, params){
      for (var k in params)
        loggedInUser[k] = params[k]
    },

    LOGOUTUSER(state){
      const user = state.loggedInUser
      Vue.set(state, 'loggedInUser', null)
      Vue.set(state, 'deals', [])
      Vue.delete(user,'oauth')
    },

    // ADD_TO_LIST(state, {listname, item}){
    //   state[listname].push(item)
    // },

    /**
     * change = {
     *    users: {
     *      "1": {
     *        map: {"0":null, "1":2, ... , "35":3},
     *        bag: {"5":null, "9":4},
     *        score: {"0":[6,-2],"1":[4],"2":[-8]},
     *        blist: 123 | '123' | null
     *      }
     *    }
     * }
     * users:
     *  {"<userid>":
     *    {
     *      map/bag:{"<map/bag index>":<userid to add or null to clear>},
     *      score:{"<group index>":<userid to add or -userid to remove from group>},
     *      blist:<userid to set as blacklisted by the current user> or null to unset
     *  }}
     */
    APPLY_CHANGE(state, change){
      Object.keys(change).forEach(stateFieldName=>{
        switch(stateFieldName){
          case 'goods':
          case 'interests':
            Object.keys(change[stateFieldName]).forEach(id=>{
              if (id>-1)
                state[stateFieldName].push({[id]:change[stateFieldName].id})
              else
                state[stateFieldName].splice(state[stateFieldName].findIndex(i=>i.id==-id),1)
            })
            break

          case 'users':

            Object.keys(change.users).forEach(id=>{
              const userChange = change.users[id]
              const user = state.users.find(u=>u.id==id)

              Object.keys(userChange).forEach(userFieldName=>{
                const userFieldChanges = userChange[userFieldName]

                if (userFieldName=='blist') {
                  Vue.set(user, 'blist', userFieldChanges)
                  return
                }

                switch (userChange[userFieldName].constructor){
                  case Object:
                    const userFieldIndexes = Object.keys(userFieldChanges)
                    switch(userFieldName){
                      case 'bag':
                        if (!user.bag) Vue.set(user, 'bag', [])
                        const _bag = user.bag.slice()
                        userFieldIndexes.forEach(i=>_bag[i] = userFieldChanges[i])
                        Vue.set(user, 'bag', defragment(_bag))
                        break
                      case 'map':
                        if (!user.map) Vue.set(user, 'map', [])
                        userFieldIndexes.forEach(i=>Vue.set(user.map, i, userFieldChanges[i]))
                        break
                      case 'score':
                        if (!user.score) Vue.set(user, 'score', [[],[],[]])
                        userFieldIndexes.forEach(i=>{
                          userFieldChanges[i].forEach(j=>{
                            if (j>0){
                              user.score[i].push(j)
                            } else if (j<0){
                              const index = user.score[i].indexOf(-j)
                              if (index>-1) user.score[i].splice(index,1)
                            }
                          })
                        })
                        break
                      // case 'blist':
                      //   Vue.set(user, 'blist', )
                      //   break
                    }
                    break

                  case Array:

                    switch (userFieldName){
                      case 'map':
                        if (!user.map) Vue.set(user, 'map', [])
                        for (let _id of userFieldChanges){
                          if (_id < 0){
                            Vue.set(user.map, -_id, null)
                          }
                        }
                        break
                      case 'score':
                        if (!user.score) Vue.set(user, 'score', [[],[],[]])
                        for (let _id of userFieldChanges){
                          if (_id < 0){
                            user.score.forEach(gr=>{
                              let index = gr.findIndex(i=>i==-_id)
                              if (index>=0){
                                gr.splice(index,1)
                              }
                            })
                          }
                        }
                        break
                      case 'bag':
                        if (!user.bag) Vue.set(user, 'bag', [])
                        for (let _id of userFieldChanges){
                          if (_id < 0){
                            let index = user.bag.findIndex(i=>i==-_id)
                            if (index>=0){
                              user.bag.splice(index,1)
                            }
                          } else {
                            user.bag.push(_id)
                          }
                        }
                        break
                    }
                    break
                }

              })
            })
            break
        }
      })
    },

    SELECT_CELL(state, cell){
      if (cell){
        if (state.selectedCell)
          state.selectedCell.selected = false
        cell.selected = true
        Vue.set(state, 'selectedCell', cell)
      } else {
        if (state.selectedCell)
          state.selectedCell.selected = false
        Vue.set(state, 'selectedCell', null)
      }
    },

    DELETE_DEAL(state, id){
      const index = state.deals.findIndex(d=>d.id==id)
      if (index>=0)
        state.deals.splice(index,1)
      else
        notie.alert({
          type:'error',
          text:'failed to delete deal with id '+id
        })
    },

    DELETE_DEAL2(state, deal){
      const index = state.deals.findIndex(d=>d===deal)
      if (index>=0)
        state.deals.splice(index, 1)
      else
        notie.alert({
          type: 'error',
          text: 'failed to delete a deal. not found in store'
        })
    },

    EDIT_MAP(state, newval){
      state.map_edit_mode = newval
    },

    // DEALS
    ADD_DEAL(state, deal){
      console.log('store.ADD_DEAL')
      state.deals.push(deal)
    },

    ADD_NEW_DEAL(state, deal){
      state.deals.push(deal)
    },

    UPDATE_DEAL(state, {id, dealData}){
      const deal = state.deals.find(d=>d.id==id)
      if (deal) {
        for (let p in dealData)
          deal[p] = dealData[p]
      } else {
        console.warn(`UPDATE DEAL. deal with id=${id} not found`)
      }
    },

    LOAD_DEALS(state, deals_data) {
      const loggedInUser = state.loggedInUser
      state.deals = deals_data.map(deal => {

        const counterpartId = deal.sellerId == loggedInUser.id
          ? deal.buyerId
          : deal.sellerId

        deal.counterpartId = counterpartId
        deal.counterpart = state.users.find(user => user.id == counterpartId)
        deal.open = false

        // deal.user = state.loggedinUser // all deals are user's (loggedInUser's) deals
        deal.seller = state.users.find(u => u.id == deal.sellerId)
        deal.buyer = state.users.find(u => u.id == deal.buyerId)
        deal.creator = deal.creator_is_seller ? deal.seller : deal.buyer

        return deal
      })
    },
  },

  actions: {
    initState({state, commit}){
      return fetch('/api/init_state').then(res => res.json())
        .then(res => {
          console.log('init_state res.json():', res)
          state.users = res.users
          state.interests = res.interests
          state.goods = res.goods
          state.loggedInUser = res.loggedInUser
            ? state.users.find(u => u.id == res.loggedInUser)
            : null

          commit('LOAD_DEALS', res.deals)

          // state.deals = res.deals.map(deal => {

          //   const counterpartId = deal.sellerId == res.loggedInUser
          //     ? deal.buyerId
          //     : deal.sellerId

          //   deal.counterpartId = counterpartId
          //   deal.counterpart = state.users.find(user => user.id == counterpartId)
          //   deal.open = false

          //   // deal.user = state.loggedinUser // all deals are user's (loggedInUser's) deals
          //   deal.seller = state.users.find(u => u.id == deal.sellerId)
          //   deal.buyer = state.users.find(u => u.id == deal.buyerId)
          //   deal.creator = deal.creator_is_seller ? deal.seller : deal.buyer

          //   return deal
          // })

          // state.deals2 = res.deals.map(deal => new Deal(deal, state.users, state.loggedInUser, state.goods))
          // state.usergoods = res.usergoods
        })
    },

    // AUTH
    register({commit}, user){
      return post('/api/register', {register:true, user}).then(data=>{
        if (data.register=='OK'){
          // commit('ADDUSER', data.user)
          commit('UPDATEUSERS', [data.user])
          commit('LOGINUSER', data.user)
          // router.replace({name:'map', params:{slug:data.user.slug}})
          notie.alert({text:'Registration complete!'})
        } else if (data.register=='EXISTS'){
          notie.alert({
            type:'error',
            text:`Аккаунт ${user.id} уже существует! Выберите другой адрес электронной почты или номер телефона.`,
            stay: true
          })
        }
        return data
      })
    },

    login({state, commit}, {userid, password}){
      return post('/api/login', {id:userid, pass:password, login:true}).then(data=>{
        if (data.login=='OK'){
          // Vue.set(state, 'deals', data.deals)
          commit('UPDATEUSERS', data.users)
          commit('LOGINUSER', data.user)
          commit('LOAD_DEALS', data.deals)
        } else if (data.login=='FAIL'){
          notie.alert({
            type:'error',
            text:'телефон/email или пароль указан неверно',
            stay:true
          })
        } else {
          notie.alert({
            type:'error',
            text:'не получилось'
          })
        }
        return data
      })
    },

    logout({commit}){
      notie.alert({text:'logging out...'})
      return post('/api/logout', {logout: true}).then(data=>{
        if (data.logout=='OK'){
          notie.alert({text:'logged out!'})
          commit('LOGOUTUSER')
          router.push({name:'home'})
        }
        return data
      })
    },

    async resetpass({}, email){
      const data = await post('/api/resetpassword', {action:'send_reset_url',email})
      return data
    },

    async set_new_pass({}, pass){
      const data = await post('/api/resetpassword', {action:'set_new_pass',new:pass})
      router.push({name:'home'})
      return data
    },


    // PROFILE
    update_profile({commit}, delta){
      return post('/api/updateprofile', delta).then(data=>{
        if (data.change){
          commit('APPLY_CHANGE', data.change)
        }
        if (data.update=='OK'){
          // commit('UPDATE_PROFILE', delta)
          commit('UPDATE_PROFILE', data.user)
        } else {
          // notie.alert({type:'error', text:'profile update failed'})
          pop_error('profile update failed')
          console.log('error! data:', data)
        }
        if (data.popups) {
          data.popups.forEach(i=>notie.alert(i))
        }
        return data
      }).catch(data => {
        if (data && data.status == 'FAIL' && data.popups) {
          data.popups.forEach(i=>notie.alert(i))
        }
      })
    },

    async delete_account({state}){
      const data = await post('/api/deleteaccount')

      if (!data.errors.length){
        const log = state.loggedInUser
        const delete_user_id = log.id

        if (data.change){
          commit('APPLY_CHANGE', data.change)
        }
        // we can not delete the user if he has deals
        // but we should remove him from lists/search
        // then mark him as deleted
        if (state.deals.length){
          // clear log user map bag score
          // set deleted flag
          log.map = []
          log.bag = []
          log.score = [[],[],[]]
          log.deleted = true
        } else {
          const delete_user_index = state.users.findIndex(u=>u.id==delete_user_id)
          state.users.splice(delete_user_index,1)
        }

        commit('LOGOUTUSER')
        router.push({name:'home'})
      }

      return data
    },

    // USERGOODS
    async get_user_goods({getters, state},uid){
      const user = getters.userById(uid)

      if (!user) {
        console.log('ERROR: store.getters.get_user_goods: user not found, id:',uid)
        return []
      }

      if (!user.goods) {
        const data = await post('/api/usergoods', {
          uid,
          action:'get',
          getTradeMatches:state.loggedInUser && state.loggedInUser.id == uid
        })

        if (data.errors.length){
          console.log('ERROR: store.getters.get_user_goods:', data.errors)
          return []
        }

        user.goods = data.usergoods.map(good => {
          good.price = parseFloat(good.price)
          good.q = parseFloat(good.q)
          return good
        })

        if (data.tradeMatches) {
          state.tradeMatches.offers = data.tradeMatches.offers
          state.tradeMatches.bids = data.tradeMatches.bids
        }
      }

      return user.goods
    },

    /*
      data :: {uid::int, goods::{remove::[good],update::[good]}}
      good :: {id::int, img::strUrl, name::str, price::strFloat, q::strFloat, unit::str}
    */
    async save_user_goods(context, data) {
      const user = context.state.loggedInUser
      if (user.id != data.uid) {
        throw 'only pricelist owner cant save'
      }

      // backup current usergoods before attempting to save to db and update
      const usergoods = clone(user.goods)

      // pre-applying usergoods remove
      user.goods = user.goods.filter(ug=>!data.goods.remove.find(rg=>rg.id==ug.id))

      // pre-applying usergoods updates
      data.goods.update.forEach(good=>{
        const idx = user.goods.findIndex(g=>g.id==good.id)

        // swap old usergood with a new one
        if (idx>-1) user.goods.splice(idx,1,good)
        // add new usergood
        else user.goods.push(good)
      })

      // send only usergood.id for a remove list
      data.goods.remove = data.goods.remove.map(g=>g.id)

      // send only diffs for usergoods updates list
      data.goods.update = data.goods.update.map(g=>{
        const usergood = usergoods.find(ug=>ug.id==g.id)

        if (usergood)
          return deltaObj(usergood, g, {include:['id', 'imgs']})
          // usergood.imgs are diffed on the server side
          // imgs that are missing from the update usergood
          // will be deleted from on the server side, so
          // we need to send full imgs list including
          // imgs that are not different from the original
          // in user.good[...].imgs

        return g
      })

      data.action = 'save'

      const resp = await post('/api/usergoods', data)

      if (resp.status=='OK' && resp.errors.length==0){
        // console.log('user goods saved in db OK')
        // update newId goods to id goods
        // resp.usergoods::[{id,db_id}]

        if (resp.usergoods && resp.usergoods.length){
          // resp.usergoods contain only `id` and `db_id` fields

          resp.usergoods.forEach(rg=>{
            const usergood = user.goods.find(ug=>ug.id==rg.id)
            if (usergood) {
              usergood.id = rg.db_id
            }

            const data_update_usergood = data.goods.update.find(up_ug => up_ug.id == rg.id)
            if (data_update_usergood) {
              data_update_usergood.id = rg.db_id
            }
          })
        }

        if (resp.update_images && resp.update_images.length) {
          resp.update_images.forEach(item => {
            const usergood = user.goods.find(ug => ug.id == item.usergoodId)
            if (usergood) {
              const img = usergood.imgs.find(i => i.id == item.localId)
              if (img) {
                img.id = item.dbId
                img.url = item.url
              }
            }
          })
        }

        // in all draft deals' dealgoods remove all dealgoods with usergoodId of usergoods that were deleted
        data.goods.remove.forEach(rem_usergood => {
          context.state.deals.forEach(deal => {
            if (isEditable(deal)) {
              deal.dealgoods = deal.dealgoods.filter(dealgood => {
                return !dealgood.usergoodId || dealgood.usergoodId != rem_usergood.id
              })
            }
          })
        })

        // update all dealgoods in all draft deals for all users
        data.goods.update.forEach(delta_usergood => {
          if (delta_usergood.price) {
            context.state.deals.forEach(deal => {
              if (isEditable(deal)) {
                deal.dealgoods.forEach(dealgood => {
                   if (dealgood.usergoodId) {
                     dealgood.price = delta_usergood.price
                   }
                })
              }
            })
          }
        })


      } else if (resp.errors.length){
        console.log('user goods save errors:',resp.errors)
      } else {
        console.log('user goods save fail')
      }

      return resp
    },


    // MAP
    add_user_to_bag({state, commit}, user){
      if (!state.loggedInUser) return Promise.resolve()

      const log = state.loggedInUser

      const timestamp = Date.now()
      const change = {users:{},timestamp}
      const undo = {users:{},timestamp}
      change.users[log.id]={bag:{[log.bag.length]:user.id}}
      undo.users[log.id]={bag:{[log.bag.length]:null}}

      commit('APPLY_CHANGE', change)
      commit('SELECT_CELL')

      const historyState = {
        timestamp,
        change,
        applied: true,
        undo,
        timer: setTimeout(()=>undoHistory(commit,timestamp),5000),
        accepted: false
      }
      changeHistory.push(historyState)
      return post('/api/change', change).then(data=>{
        if (data.errors.length){
          notie.alert({type:'error',text:'change errors, undoing'})
          pop_error('Ошибка! Изменение отменено!')
          console.log('errors', data.errors)
          undoHistory(commit, timestamp)
        } else {
          clearTimeout(historyState.timer)
          historyState.accepted = true
          notie.alert({text:'change accepted'})
          console.log('change accepted')
          console.log(historyState)
          clearHistory(timestamp)
        }
        return data
      })

    },

    cell_click({state, commit, getters}, cell){
      // check if the user can change anything
      if (!state.loggedInUser) return Promise.resolve()

      const log = state.loggedInUser,
            cur = getters.currentUser,
            editable = log===cur,
            sel = state.selectedCell

      // if there is no selected cell yet
      if (!sel){
        // click in map center?
        if (cell.n==0 && editable)
          return Promise.resolve()
        // set selected cell
        state.selectedCell = cell
        cell.selected = true
        return Promise.resolve()
      }

      // second click on selected cell?
      if (sel===cell){
        // sel.selected = false
        // state.selectedCell = null
        commit('SELECT_CELL')
        return Promise.resolve()
      }

      const srcId = sel.user?sel.user.id:null,
            trgId = cell.user?cell.user.id:null,
            timestamp = Date.now(),
            change = {timestamp, users:{}},
            undo = {users:{}}

      // selected and clicked cells are empty?
      if (!srcId&&!trgId){
        commit('SELECT_CELL')
        return Promise.resolve()
      }

      // trying to swop logged user from map to bag?
      if ( ( cell.n==-1 && srcId==log.id )
        || ( sel.n==-1 && trgId==log.id )
      ) {
        console.log('Nope!')
        pop_warn('так нельзя...')
        return Promise.resolve()
      }

      // check if swopping from someone else's map to bag
      // a user that is already on my map
      const getUserId = getPath(['user','id'])
      const isInMap = cell => cell.n>0
      const isInBag = cell => cell.n==-1
      const isInCenter = cell => cell.n==0
      const both = [sel, cell]
      const oneIsInBag = oneOf(isInBag)
      const oneIsInMap = oneOf(isInMap)
      const swopping = both.every(getUserId)
      if (swopping && !editable && oneIsInBag(both)){
        const theOtherUser = both.find(isInMap).user
        if (!getters.canAddToBag(getUserId(both.find(isInMap)))){
          console.log('cant do this!')
          pop_warn(`${someName(theOtherUser)} уже есть в Вашем Круге Доверия или Буфере!`)
          return Promise.resolve()
        }
      }


      if (isInMap(sel)){ // from map
        const srcIndex = sel.n-1
        const srcGroup = scoreGroup(sel.n)
        if (isInMap(cell) && editable){ // to map
          const trgIndex = cell.n-1
          const trgGroup = scoreGroup(cell.n)
          change.users[log.id]={
            map:{
              [srcIndex]:trgId,
              [trgIndex]:srcId
            },
          }
          undo.users[log.id]={
            map:{
              [srcIndex]:srcId,
              [trgIndex]:trgId
            }
          }
          if (srcGroup!=trgGroup){
            if (srcId)
              change.users[srcId]={
                score:{
                  [srcGroup]:[-log.id],
                  [trgGroup]:[log.id]
                }
              }
              undo.users[srcId]={
                score:{
                  [srcGroup]:[log.id],
                  [trgGroup]:[-log.id]
                }
              }
            if (trgId)
              change.users[trgId]={
                score:{
                  [srcGroup]:[log.id],
                  [trgGroup]:[-log.id]
                }
              }
              undo.users[trgId]={
                score:{
                  [srcGroup]:[-log.id],
                  [trgGroup]:[log.id]
                }
              }
          }

        } else if (isInBag(cell)){ // to bag
          const trgIndex = log.bag.findIndex(i=>i==trgId)
          if (editable){
            change.users[log.id]={
              map:{[srcIndex]:trgId},
              bag:{[trgIndex]:srcId}
            }
            undo.users[log.id]={
              map:{[srcIndex]:srcId},
              bag:{[trgIndex]:trgId}
            }
            if (trgId){
              change.users[trgId]={score:{[srcGroup]:[log.id]}}
              undo.users[trgId]={score:{[srcGroup]:[-log.id]}}
            }
            if (srcId){
              change.users[srcId]={score:{[srcGroup]:[-log.id]}}
              undo.users[trgId]={score:{[srcGroup]:[log.id]}}
            }
          } else { // not editable
            change.users[log.id]={bag:{[trgIndex]:srcId}}
            undo.users[log.id]={bag:{[trgIndex]:null}}
          }

        // } else if (cell.n == -2){ // to list or deal
          // nothing TODO
        // } else if (cell.n == 0){ // to map center
          // nothing TODO
        } else if (cell.n == -3 && editable) { // to blacklist
          change.users[log.id]={
            blist:srcId,  // set selected user from map to blacklist
            map:{[srcIndex]:trgId},  // swap user from blacklist to map
          }
          undo.users[log.id]={
            blist:trgId,
            map:{[srcIndex]:srcId},
          }
          if (srcId) {
            change.users[srcId] = {score:{[srcGroup]:[-log.id]}}  // unscore loged user from selected user score
            undo.users[srcId] = {score:{[srcGroup]:[log.id]}}
          }
          if (trgId) {
            change.users[trgId] = {score:{[srcGroup]:[log.id]}} // add logged user to clicked user score
            undo.users[trgId] = {score:{[srcGroup]:[-log.id]}}
          }
        }
      } else if (isInBag(sel)){ // from bag
        const srcIndex = log.bag.findIndex(i=>i==srcId)
        if (cell.n > 0){ // to map
          const trgIndex = cell.n-1
          const trgGroup = scoreGroup(cell.n)
          if (editable){
            change.users[log.id]={
              map:{[trgIndex]:srcId},
              bag:{[srcIndex]:trgId}
            }
            undo.users[log.id]={
              map:{[trgIndex]:trgId},
              bag:{[srcIndex]:srcId}
            }
            if (srcId){
              change.users[srcId]={score:{[trgGroup]:[log.id]}}
              undo.users[srcId]={score:{[trgGroup]:[-log.id]}}
            }
            if (trgId){
              change.users[trgId]={score:{[trgGroup]:[-log.id]}}
              undo.users[trgId]={score:{[trgGroup]:[log.id]}}
            }
          } else { // not editable
            change.users[log.id]={bag:{[srcIndex]:trgId}}
            undo.users[log.id]={bag:{[srcIndex]:null}}
          }

        } else if (cell.n == -1){ // to bag
          const trgIndex = log.bag.findIndex(i=>i==trgId)
          change.users[log.id]={
            bag:{
              [srcIndex]:trgId,
              [trgIndex]:srcId
            }
          }
          undo.users[log.id]={
            bag:{
              [srcIndex]:srcId,
              [trgIndex]:trgId
            }
          }
        // } else if (cell.n == -2){ // to list or deal
          // nothing TODO
        } else if (cell.n == 0){ // to map center
          if (!editable){
            change.users[log.id]={bag:{[srcIndex]:trgId}}
            undo.users[log.id]={bag:{[srcIndex]:null}}
          }
        }

      // } else if (sel.n == -2){ // from list or deal
        // if (cell.n > 0){ // to map
          // nothing TODO
        // } else if (cell.n == -1){ // to bag
          // nothing TODO
        // } else if (cell.n == -2){ // to list or deal
          // nothing TODO
        // } else if (cell.n == 0){ // to map center
          // nothing TODO
        // }
      } else if (isInCenter(sel)){ // from map center
        if (cell.n > 0){ // to map
          // nothing TODO
        } else if (cell.n == -1){ // to bag
          const trgIndex = log.bag.findIndex(i=>i==trgId)
          if (!editable){
            change.users[log.id]={bag:{[trgIndex]:srcId}}
            undo.users[log.id]={bag:{[trgIndex]:null}}
          }
        // } else if (cell.n == -2){ // to list or deal
          // nothing TODO
        // } else if (cell.n == 0){ // to map center
          // nothing TODO
        }
      } else if (sel.n==-3) { // from blacklist
        if (isInMap(cell) && editable) {
          const trgIndex = cell.n-1
          const trgGroup = scoreGroup(cell.n)
          change.users[log.id]={
            blist:trgId,
            map:{[trgIndex]:srcId}
          }
          undo.users[log.id]={
            blist:srcId,
            map:{[trgIndex]:trgId}
          }
          if (srcId) {
            change.users[srcId]={score:{[trgGroup]:[log.id]}}
            undo.users[srcId]={score:{[trgGroup]:[-log.id]}}
          }
          if (trgId) {
            change.users[trgId]={score:{[trgGroup]:[-log.id]}}
            undo.users[trgId]={score:{[trgGroup]:[log.id]}}
          }
        }
      }

      if (!Object.keys(change.users).length) return Promise.resolve()

      commit('APPLY_CHANGE', change)
      commit('SELECT_CELL')
      const hist = {
        timestamp,
        change,
        applied: true,
        undo,
        timer:setTimeout(()=>undoHistory(commit,timestamp),5000),
        accepted: false
      }
      changeHistory.push(hist)
      return post('/api/change', change).then(data=>{
        if (data.errors.length){
          pop_error('Ошибка! Изменение отменено!')
          console.log('errors', data.errors)
          undoHistory(commit, timestamp)
        } else {
          clearTimeout(hist.timer)
          hist.accepted = true
          pop_ok('сохранено...')
          clearHistory(timestamp)
        }
        return data
      })
    },

    bag_click({state, commit, getters}){
      if (!state.loggedInUser) return Promise.resolve()

      const log = state.loggedInUser,
            cur = getters.currentUser,
            editable = log===cur,
            sel = state.selectedCell

      if (!sel || sel.n<0)
        return Promise.resolve()

      const srcId = sel.user? sel.user.id : null

      if (!srcId) return Promise.resolve()

      // check if map of the loged user already
      // has srcId in it
      if (sel.n > 0 && !editable){
        // user is trying to add
        // another user from a map of some
        // another user to his(logged user) bag
        // while ???having??? the user which
        // logged user is trying to add to bag
        // already in the map
        if (log.map.find(id=>id==srcId)){
          pop_warn(`${someName(sel.user)} уже есть в Вашем Круге Доверия!`)
          return Promise.resolve()
        }

      }

      const timestamp = Date.now(),
            change = {timestamp, users:{}},
            undo = {users:{}}

      if (sel.n > 0){ // from map
        const srcIndex = sel.n-1
        if (editable){
          const scoreIndex = scoreGroup(sel.n)
          change.users[log.id]={
            map:{[srcIndex]:null},
            bag:{[log.bag.length]:srcId}
          }
          change.users[srcId]={
            score:{[scoreIndex]:[-log.id]}
          }
          undo.users[log.id]={
            map:{[srcIndex]:srcId},
            bag:{[log.bag.length]:null}
          }
          undo.users[srcId]={
            score:{[scoreIndex]:[log.id]}
          }
        } else { // not editable
          change.users[log.id]={
            bag:{[log.bag.length]:srcId}
          }
          undo.users[log.id]={
            bag:{[log.bag.length]:null}
          }
        }
      } else if (sel.n == 0) { // from map center
        if (!editable){
          change.users[log.id]={
            bag:{[log.bag.length]:srcId}
          }
          change.users[log.id]={
            bag:{[log.bag.length]:null}
          }
        }
      }

      if (!Object.keys(change.users).length) return Promise.resolve()

      commit('APPLY_CHANGE', change)
      commit('SELECT_CELL')

      const historyState = {
        timestamp,
        change,
        applied: true,
        undo,
        timer: setTimeout(()=>undoHistory(commit, timestamp), 5000),
        accepted: false
      }
      changeHistory.push(historyState)
      return post('/api/change', change).then(data=>{
        if (data.errors.length){
          pop_error('Ошибка! Изменение отменено!')
          console.log('errors', data.errors)
          undoHistory(commit, timestamp)
        } else {
          clearTimeout(historyState.timer)
          historyState.accepted = true
          pop_ok('сохранено...')
          clearHistory(timestamp)
        }
        return data
      })
    },

    remove_selected_user({state, commit, getters}, cell){
      const log = state.loggedInUser,
            editable = log===getters.currentUser,
            sel = state.selectedCell || cell,
            timestamp = Date.now(),
            change = {timestamp,users:{}},
            undo = {users:{}},
            srcId = sel.user?sel.user.id:null

      if (!srcId) return Promise.resolve()

      if (sel.n>0 && editable){ // REMOVE FROM MAP
        const srcIndex = sel.n-1
        change.users[log.id]={map:{[srcIndex]:null}}
        change.users[sel.user.id]={score:{[scoreGroup(sel.n)]:[-log.id]}}
        undo.users[log.id]={map:{[srcIndex]:srcId}}
        undo.users[sel.user.id]={score:{[scoreGroup(sel.n)]:[log.id]}}
      } else if (sel.n==-1){ // REMOVE FROM BAG
        const srcIndex = log.bag.findIndex(i=>i==sel.user.id)
        change.users[log.id] = {bag:{[srcIndex]:null}}
        undo.users[log.id] = {bag:{[srcIndex]:srcId}}
      }

      if (!Object.keys(change.users).length) return Promise.resolve()

      // 1. APPLY LOCALY
      commit('APPLY_CHANGE', change)
      commit('SELECT_CELL')

      // 2. STORE CHANGE AND UNDO AND SETUP TIMER
      const historyState = {
        timestamp,
        change,
        applied: true,
        undo,
        timer:setTimeout(()=>undoHistory(commit,timestamp), 5000),
        accepted: false
      }
      changeHistory.push(historyState)

      // 3. SEND CHANGE TO SERVER AND SETUP TO CLEAR TIMER
      return post('/api/change', change).then(data => {
        if (data.errors.length){
          // UNDO
          pop_error('Ошибка! Изменение отменено!')
          console.log('errors', data.errors)
          historyState.rejected = true
          // undoHandler()
          undoHistory(commit,timestamp)
          // what is some of the following changes has been accepted
          // by now???
        } else {
          // TODO: what if the timer has already gone off?
          // then the local change is undone!
          // need to check timer state
          // what about all the following local changes???
          // historyState.applied == false - means the timer
          // has triggered
          // historyState.accepted == true - the timer has been stopped

          historyState.accepted = true
          pop_ok('сохранено...')

          if (historyState.applied){
            // CLEAR TIMER
            clearTimeout(historyState.timer)
            clearHistory(timestamp)
          } else { // the timer has triggered, changes - undone
            // reapply this and all the following changes that
            // were undone
            // what if some of the following changes has already
            // been accepted???
            // what if some of the previous changes has not been
            // accepted yet???

            // check if there are some unapplied changes before
            // this one

            redoHistory(commit, timestamp)
          }

        }
        return data
      })

    },


    // DEALS
    async save_deal({commit, state, getters}, deal){
      // let new_deal_id = false
      // if (deal.state=='new'){
      //   new_deal_id = deal.id
      //   deal.state='draft'
      //   deal.id=''
      // }
      const deal_id = deal.id
      if (deal.state == 'new') {
        deal.state = 'draft'
        deal.id = ''
      }

      // let data = await postData('/api/deal', {deal,action:'save'})
      let data = await postData('/api/save_deal', deal)

      console.log('DATA', data)

      if (data.errors.length){
        pop_error('Ошибка! Не удалось сохранить сделку.')
        console.log('deal save failed:', data.errors)
        return data
      }

      pop_ok('Сделка сохранена!')

      if (data.change){
        commit('APPLY_CHANGE', data.change)
      }

      // const _deal = new_deal_id===false
      //   ? getters.dealById(data.deal.id)
      //   : getters.dealById(new_deal_id)

      // if (_deal){
      //   for (let p in data.deal)
      //     _deal[p] = data.deal[p]
      // } else {
      //   // state.deals.push(data.deal)
      //   console.warn('NOT SUPPOSED TO HAPPEN')
      // }
      commit('UPDATE_DEAL', {id:deal_id, dealData:data.deal})

      return data
    },

    async offer_deal({dispatch, getters}, {deal, dirty}){
      let data, saved_deal

      if (dirty){
        data = await dispatch('save_deal', deal)
        if (!data.errors.length){
          saved_deal = data.deal
        } else {
          pop_error('Не удалось сохранить сделку!')
          console.log('data.errors', data.errors)
          return data
        }
      }

      const dealId = saved_deal ? saved_deal.id : deal.id

      data = await post('/api/deal', {dealId, action:'offer'})

      if (!data.errors.length){
        pop_ok('Предложение отправлено!')
        const _deal = getters.dealById(dealId)
        if (_deal){
          _deal.state = data.deal.state
          Vue.set(_deal, 'offered', data.deal.offered)
        } else {
          pop_error('Ошибка при обновлении локальных данных сделки.')
          console.log('deal with id', deal.id, 'not found in state.deals')
        }
      } else {
        pop_error('Ошибка! Предложение не отправлено.')
        console.log('data.errors', data.errors)
      }

      data.deal.id = dealId
      return data
    },

    async delete_deal({commit}, deal){
      var dealId = deal.id

      if (!dealId || deal.state=='new'){
        // TODO: the deal is new => can be deleted right away
        // no need to send to server
        commit('DELETE_DEAL2', deal)
        pop_ok('Несохраненный черновик удален.')
        return {errors:[]}
      }

      const data = await post('/api/deal', {action:'delete',dealId})

      if (!data.errors.length){
        commit('DELETE_DEAL', dealId)
        pop_ok('Черновик удален.')
      } else {
        pop_error('Ошибка при удалении черновика!')
        console.log('delete_deal failed. errors:', data.errors)
        if (data.msg) console.log('msg:', data.msg)
      }

      return data
    },

    async accept_deal({getters}, dealId){
      const data = await post('/api/deal', {action:'accept',dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        if (deal){
          deal.state = data.deal.state
          Vue.set(deal, 'accepted', data.deal.accepted)
        }

        data.chains.forEach(c=>{
          const deal = getters.dealById(c.dealId)
          if (!deal.paybacks){
            deal.paybacks = []
          }
          const pb = deal.paybacks.find(p=>p.id==c.id)
          if (!pb){
            deal.paybacks.push({id:c.id, value:c.value})
          }
        })

        data.closed_deals.forEach(id=>{
          const deal = getters.dealById(id)
          deal.state = 'fulfilled'
          Vue.delete(deal, 'extend_request')
        })

        pop_ok('Предложение принято!')

      } else {
        pop_error('Ошибка при принятии предложения!')
        console.log('accept_deal errors', data.errors)
      }
      return data
    },

    async reject_deal({commit}, dealId){
      const data = await post('/api/deal', {action:'reject',dealId})
      if (!data.errors.length){
        commit('DELETE_DEAL', dealId)
        pop_ok('Предложение отклонено.')
      } else {
        pop_error('Ошибка при отклонении предложения!')
      }
      return data
    },

    async cancel_deal({getters}, dealId){
      const data = await post('/api/deal', {action:'cancel',dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        deal.state = data.deal.state
        Vue.delete(deal, 'offered')
        pop_ok('Предложение отменено.')
      } else {
        pop_error('Ошибка отмены предложения.')
      }
      return data
    },

    async close_deal({getters}, dealId){
      const data = await post('/api/deal', {action:'close',dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        deal.state = data.deal.state
        Vue.set(deal, 'fulfilled', data.deal.fulfilled)
        pop_ok('Сделка завершена.')
      } else {
        pop_error('Ошибка при закрытии сделки.')
      }
      return data
    },

    async request_deal_extend({getters}, {days, dealId}){
      const data = await post('/api/deal', {action:'requestextend',days,dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        Vue.set(deal, 'extend_request', data.extend_request)
        pop_ok('Запрос на продление сделки отправлен.')
      } else {
        pop_error('Ошибка при запрос на продление сделки.')
      }
      return data
    },

    async cancel_extend_request({getters}, dealId){
      const data = await post('/api/deal', {action:'cancelrequest',dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        Vue.delete(deal,'extend_request')
        pop_ok('Запрос на продление сделки отменен.')
      } else {
        pop_error('Ошибка при отмене запроса на продление сделки.')
      }
      return data
    },

    async extend_deal({getters}, {dealId, days}){
      const data = await post('/api/deal', {action:'extend',days,dealId})
      if (!data.errors.length){
        const deal = getters.dealById(dealId)
        deal.days = data.days
        deal.state = data.state
        Vue.delete(deal,'extend_request')
        pop_ok('Сделка продлена.')
      } else {
        pop_error('Ошибка при продлении сделки.')
      }
      return data
    },

    async load_deals_details({state, getters}){
      if (state.deals_details_loaded) return;
      const data = await post('/api/data/deals/details', {
        dealsIds: state.deals.map(d=>d.id)
      })

      if (data.status!='OK') {
        console.warn('load_deals_details failed!')
        pop_error('Ошибка! Детали сделок не удалось загрузить.')
        return
      }

      state.deals_details_loaded = true

      data.deals.forEach(dl=>{
        const deal = getters.dealById(dl.id)
        if (deal){
          Vue.set(deal, 'details', dl.details)
        } else {
          console.warn('deal', dl.id, 'not found. skipping')
        }
      })
    },

  }
})
export default store



function findInState(k, field) {
  return state => i => state[field].find(j => j[k] == i)
}
