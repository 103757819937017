<template lang='pug'>
	.editor
		#tools(ref='tools' v-show='toolsVisible')
			select.ql-font
			select.ql-size
				option(value='small') мелкий
				option(selected) обычный
				option(value='large') большой
				option(value='huge') огромный
			button.ql-bold
			button.ql-italic
			button.ql-underline
			button.ql-strike
			button.ql-list(value='ordered')
			button.ql-list(value='bullet')
			button.ql-indent(value='-1')
			button.ql-indent(value='+1')
			select.ql-align
			select.ql-color
			select.ql-background
			button.ql-clean
			button.ql-hide(@click='hide' style='float:right;') ×
		#editor(ref='editor')
</template>






<script>
	import {
		loadJs,
		loadJs3,
		// ensureCss,
	} from '@/funcs'


	export default {
		name: 'Editor',
		// props: ['value'],
		props: {
			value: {type: String},
			tools: {default: true},
		},

		data(){
			return {
				contentEl: null,
				showTools: false
			}
		},

		watch: {
			value(val){
				if (val!==this.contentEl.innerHTML)
					this.contentEl.innerHTML=val
			}
		},

		computed: {
			toolsVisible() {
				return this.showTools && this.tools
			},
		},

		// created(){
		// 	ensureCss('/static/css/quill.snow.css')
		// },

		mounted(){
			this.$refs.editor.innerHTML = this.value
			loadJs3('/static/js/quill.min.js').then(() => {
				this.quill = new Quill(this.$refs.editor, {
					theme: 'snow',
					placeholder: 'Детали сделки',
					modules: {toolbar: this.$refs.tools}
				})

				this.showTools = true

				if (!this.contentEl) {
					this.contentEl = this.$el.querySelector('.ql-editor')
				}

				this.quill.on('text-change', () => {
					this.$emit('input', this.contentEl.innerHTML)
				})
			})

		},

		methods: {
			hide() {this.$emit('hidetools')},
		}
	}
</script>






<style scoped>
.editor {
	max-width: 100vw;
}
.ql-container {
	font-size: unset !important;
}
.ql-container.ql-snow {
	border: none !important;
	background-color: rgba(255,255,255,.5);
}
.ql-toolbar.ql-snow {
	border: none !important;
	background-color: rgba(0,0,0,.1);
}
.ql-hide {
	float: right;
}
</style>
