<template>
  <div>
    <!-- COLUMN HEADERS -->
    <div class="goodline gray">
      <div class="col-40 tal">наименование</div>
      <div class="col-20 tac">цена</div>
      <div class="col-20 tac">кол-во</div>
      <div class="col-20 tar">сумма</div>
    </div>


    <!-- GOODS -->
    <div class="goodline" v-for="dealgood in displaygoods" :key="dealgood.id">
      <div class="col-40 tal">{{goodName($store, dealgood)}}</div>
      <div class="col-20 tac">{{dealgood.price}}</div>
      <div class="col-20 tac">{{dealgood.q}}</div>
      <div class="col-20 tar">
        {{dealgood.price * dealgood.q}}
        <!-- <a class="btn" v-if="editable" @click='remove(dealgood)'>&times;</a> -->
        <img v-if="editable" @click='remove(dealgood)' class="vat" src="/static/img/close2.svg" alt="remove" style="height:1em;">
      </div>
    </div>


    <!-- ADD-NEW-GOOD COLUMN -->
    <div class="goodline" v-if="editable">
      <!-- GOOD NAME -->
      <div>
        <div class="col-50 tal vam gray">наименование</div>
        <div class="col-50 tac vam">
          <!-- FREE GOOD NAME INPUT -->
          <template v-if='goods.length == 0'>
            <input class="input vam" type="text" list='goodslist' v-model='name'>
            <datalist id="goodslist">
              <option v-for='good in $store.state.goods.slice(1)' :value="good.name" :key="good.id"></option>
            </datalist>
          </template>
          <!-- LOCKED USER GOODS -->
          <template v-else>
            <select class="input vam" @change='set_price' v-model.trim='name'>
              <option v-for="good in goods" :value="good.id" :key="good.id">{{good.name}}</option>
            </select>
          </template>
        </div>
      </div>

      <!-- GOOD PRICE -->
      <div>
        <div class="col-50 tal vam gray">цена</div>
        <div class="col-50 tac vam">
          <input v-if='goods.length > 0' type="number" class="input tac" style='padding-right:0;' disabled='true' v-model='price'>
          <input v-else type="number" min="0" class="input tac" v-onlyNums style="padding-right:0;" v-model='price'>
        </div>
      </div>

      <!-- GOOD Q -->
      <div>
        <div class="col-50 tal vam gray">кол-во</div>
        <div class="col-50 tac vam">
          <input type="number" class="input tac" v-onlyNums style="padding-right:0;" v-model="q">
        </div>
      </div>

      <!-- BUTTONS -->
      <div>
        <button @click='add' class="button button--blue" style="font-size:1em;height: 1.5em;width: 100%;margin:0;padding:0;">ДОБАВИТЬ</button>
      </div>

      <!-- LOADING SPINNER -->
      <div class="busy" v-if="busy">
        <img src="static/img/spinner.svg" class="spinner">
      </div>
    </div>




    <!-- TOTAL -->
    <div class="goodline">
      <div class="col-40 tal bold">ИТОГО</div>
      <div class="col-20"></div>
      <div class="col-20"></div>
      <div class="col-20 tar bold">{{total}}</div>
    </div>



  </div>
</template>






<script>
import {
  goodName,
  total,
  setNoGoodsDeal,
  unsetNoGoodsDeal,
  isEditable,
} from '@/deals'
import {
  clone,
} from '@/funcs'


export default {
  props: ['deal'],

  data() {
    return {
      name: "",
      price: 0,
      q: 0,
      busy: true,
      goods: [],

      // editable: true,
    }
  },

  created() {
    if (this.editable) {
      this.fetch_usergoods()
    }
  },

  computed: {
    editable() {
      return isEditable(this.deal)
    },

    displaygoods() {
      return this.deal.dealgoods.filter(dg => dg.goodId != 1)
    },

    total() {
      return total(this.deal)
    },
  },

  watch: {
    "deal.sellerId": function() {
      this.fetch_usergoods()
    },
  },


  methods: {
    goodName,

    add() {
      if (!this.editable) return;

      const pricelist = this.goods.length > 0
      const usergood = this.goods.find(g => g.id == this.name)
      const name = pricelist ? usergood.name : normalizeGoodname(this.name)

      if (!name) return;

      const price = parseFloat(this.price)

      if (price <= 0) return;

      const q = parseFloat(this.q)

      if (q <= 0) return;

      // this.$emit('add', {name, price, q, pricelist})
      const dealgood = {
        id: null,
        price,
        q,
      }
      const good = this.$store.getters.goodByName(name)
      if (good) {
        dealgood.goodId = good.id
      } else {
        dealgood.goodName = name
      }
      if (pricelist && usergood) {
        dealgood.usergoodId = usergood.id
      }
      if (this.deal.dealgoods.length == 1) {
        unsetNoGoodsDeal(this.deal)
      }
      this.deal.dealgoods.push(dealgood)
      this.name = ''
      this.price = 0
      this.q = 0
    },

    remove(dealgood) {
      if (!this.editable) return;

      if (this.deal.dealgoods.length == 1 && this.deal.dealgoods[0] == dealgood) {
        setNoGoodsDeal(this.deal)
      } else {
        this.deal.dealgoods = this.deal.dealgoods.filter(dg => dg != dealgood)
      }

      // const index = this.deal.dealgoods.findIndex(dg => dg == dealgood)
      // let dealprice = 0
      // if (index > -1) {
      //   dealprice = this.deal.dealgoods[index].price * this.deal.dealgoods[index].q
      //   this.deal.dealgoods.splice(index, 1)
      // }
      // if (this.deal.dealgoods.length == 0) {
      //   setNoGoodsDeal(this.deal)
      //   this.deal.dealgoods[0].price = dealprice
      // }
    },

    set_price() {
      this.price = this.goods.find(g => g.id == this.name).price
    },

    fetch_usergoods() {
      this.busy = true
      if (this.goods.length>0) {
        setNoGoodsDeal(this.deal)
      }
      this.goods.splice(0)
      this.name = ''
      this.$store.dispatch('get_user_goods', this.deal.sellerId).then(goods => {
        this.busy = false
        if (goods.length==0) return;
        else this.goods = clone(goods)
      })
    },
  }
}

function normalizeGoodname(name) {
  return name.trim().replace(/\s\s+/g,' ')
}
</script>






<style lang="scss" scoped>



.input {
  color: #222;
  border-radius: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,.25);
}

.goodline {
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: .4em 0;
  position: relative;

  .busy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.5);
  }

  .input {
    padding: 0 .4em;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
  }

  .button {
    padding: 0;
    height: 24px;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
    font-size: 12px;
    line-height: 24px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;

    background-color:#6093DF;
    border:none;
    border-radius:3px;
    color:white;
  }

  .input,.button {
    width: calc(100% - 1em);
  }

  .tal,.tar {
    .input,.button {
      width: calc(100% - .5em);
    }
  }
}

.spinner {
  animation: spin .8s steps(12) infinite;
  height: 70%;
  margin-top: 1%;
  // font-size: 0;
}

</style>
