import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {onlyNums} from './directives'

// import VModal from 'vue-js-modal'
// Vue.use(VModal)
import { VDropdown } from 'vuetensils/src/components'
Vue.component('VDropdown', VDropdown)


Vue.config.productionTip = false
Vue.config.devtools = true

Vue.directive('onlyNums', onlyNums)

store.dispatch('initState').then(() => {
  window.app = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

