import { allowOnlyNums } from "./utils"

export const focus = {
  inserted(el){
    // console.log('focus', el)
    el.focus()
  }
}

export const onlyNums = {
  bind(el, binding, vnode) {
    vnode.remove_onlyNums = allowOnlyNums(el)
  },
  unbind(el, binding, vnode) {
    if (vnode.remove_onlyNums) {
      vnode.remove_onlyNums()
    }
  }
}
