<template>
  <div class="dealgoods">
    <div
      v-for='dealgood in deal.dealgoods'
      :key='dealgood.id'
      class='dealgoods__dealgood'
    >
      <div class='flex space-between align-items-center'>
        <div>{{goodName(dealgood)}}</div>
        <img @click='remove(dealgood)' src="/static/img/close2.svg" alt="delete" class="button" style='height: 1em;'>
      </div>

      <div>
        <div class="col-33 tac vam">{{dealgood.price}}</div>
        <div class="col-33 tac vam">
          <input
            v-if='editable'
            v-model='dealgood.q'
            class='dealgood__q vam'
            type="number"
            v-onlyNums
            min='0'
          >
          <span v-else>{{dealgood.q}}</span>
        </div>
        <div class="col-33 tac vam">{{dealgood.q * dealgood.price}}</div>
      </div>

      <div v-if='dealgood.usergoodId'>
        <img
          v-for='img in usergoodById(dealgood.usergoodId).imgs'
          :key='img.id'
          :src='img.url'
          style='width: 100px;'
        >
        <p class='f-22'>{{usergoodById(dealgood.usergoodId).details}}</p>
      </div>
    </div>
  </div>
</template>






<script>
import {
  // goodsSummary,
  goodName,
  // dealState,
  isEditable,
  setNoGoodsDeal,
} from '@/deals'



export default {
  props: ['deal'],

  // data() {
  //   return {
  //     withImages: dealState(this.deal) == 'offered',
  //   }
  // },

  computed: {
    editable() {
      return isEditable(this.deal)
    },
  },

  methods: {
    goodName(dealgood) {
      return goodName(this.$store, dealgood)
    },

    usergoodById(usergoodId) {
      return this.deal.seller.goods.find(ug => ug.id == usergoodId)
    },

    remove(dealgood) {

      if (this.editable) {
        if (this.deal.dealgoods.length == 1 && this.deal.dealgoods[0] == dealgood) {
          setNoGoodsDeal(this.deal)
        } else {
          this.deal.dealgoods = this.deal.dealgoods.filter(dg => dg != dealgood)
        }
      }

    },
  }
}
</script>






<style lang="scss" scoped>
.dealgoods {
  background-color: rgba(255,255,255,.5);
  padding: .5em;
  // border-bottom: 1px solid rgba(0,0,0,.1);

  &__dealgood {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  .dealgood {
    &__q {
      margin: 0;
      width: 100%;
      vertical-align: middle;
      font-size: 1em;
      text-align: center;
    }
  }
}
</style>
