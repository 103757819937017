<template lang="pug">
	.deal.f-22.tal(:style='{backgroundColor:colors.body}')
		.tal(v-if='editable') Срок:&nbsp;
			input.tar(
				v-model='deal.days'
				type='number'
				min='0'
				style='border:none;margin:0;width:10%;border-radius:.25em;'
			)
			| &nbsp;{{days(deal.days)}}

		//- Counterparts
		div(style='margin:.5em 0;')
			.col-45.tac(v-if='deal.creator_is_seller')
				.dimgray.f-22(style='text-decoration:underline;') Продавец
				.f-22 {{someName(seller)}}
			.col-45.tac(v-else)
				.dimgray.f-22(style='text-decoration:underline;') Покупатель
				.f-22 {{someName(buyer)}}

			.col-10.tac
				a.btn.f-22(v-if='editable', @click='swop') ↔

			.col-45.tac(v-if='deal.creator_is_seller')
				.dimgray.f-22(style='text-decoration:underline;') Покупатель
				.f-22(v-if='deal.buyerId') {{someName(buyer)}}
					a.btn.f-16(
						v-if='editable'
						@click='unsetCounterpart'
						style='margin-left:.5em;'
					) сбросить
				div(v-else-if='editable')
					input.f-22.tac(
						v-model='searchUsername'
						@input='search(true)'
						@keyup.enter='search(false)'
						style='border:none;margin:0;width:100%;border-radius:.25em;'
						placeholder='искать'
					)
			.col-45.tac(v-else)
				.dimgray.f-22(style='text-decoration:underline;') Продавец
				.f-22(v-if='deal.sellerId') {{someName(seller)}}
					a.btn.f-16(
						v-if='editable'
						@click='unsetCounterpart'
						style='margin-left:.5em;'
					) сбросить
				div(v-else-if='editable')
					input.f-22.tac(
						v-model='searchUsername'
						@input='search(true)'
						@keyup.enter='search(false)'
						style='border:none;margin:0;width:100%;border-radius:.25em;'
						placeholder='искать'
					)

			//- Search User List
			ul.searchlist(v-if='searchList.length')
				li(
					v-for='user in searchList'
					:key='user.id'
					@click='setCounterpart(user)'
				)
					UserRow(:user='user')


		.rad
			//- Goods
			.click-title(
				@click='open=!open'
				:style='{backgroundColor:colors.title, color:colors.font}'
			) Сумма: {{total(deal)}} {{rubs(total(deal))}}
			.goodslist.f-16(
				v-if='open'
				:style='{borderColor:colors.title}'
			)
				//- column titles
				.goodline.gray
					.col-40.f-22.tac наименование
					.col-20.f-22.tac цена
					.col-20.f-22.tac кол-во
					.col-20.f-22.tac сумма

				//- good lines
				.goodline(
					v-for='(dealgood, index) in deal.dealgoods'
					:key='dealgood.id'
				)
					.col-40.f-22.tac.vam
						span {{goodName(dealgood)}}
						a.btn(
							v-if='editable'
							style='margin-left:.5em;'
							@click='remDealgood(index)'
						) удалить
					.col-20.f-22.tac.vam {{dealgood.price}}
					.col-20.f-22.tac.vam {{dealgood.q}}
					.col-20.f-22.tac.vam {{dealgood.price*dealgood.q}}

				//- new good
				.goodline(v-if='editable')
					.col-40.vam
						input.f-22.tac(
							style='padding:0;border:none;width:100%;border-top-left-radius:.25em;border-bottom-left-radius:.25em;'
							list='goodslist'
							v-model='addDealgoodName'
							placeholder='выбрать'
						)
						datalist#goodslist
							option(
								v-for='good in $store.state.goods'
								:value='good.name'
							)
					.col-20.vam
						input.f-22.tac(
							type='number'
							min='0'
							style='padding:0;border:none;width:100%;'
							v-model='addDealgoodPrice'
						)
					.col-20.vam
						input.f-22.tac(
							type='number'
							min='0'
							style='padding:0;border:none;width:100%;border-top-right-radius:.25em;border-bottom-right-radius:.25em;'
							v-model='addDealgoodQ'
						)
					.col-20.f-22.tac.vam
						a.btn(@click='addDealgood') добавить


			//- Details
			template(v-if='deal.details||editable')
				div(
					@click='openDetails=!openDetails'
					style="cursor:pointer;padding-left:.25em;"
					:style='{backgroundColor:colors.title, color:colors.font}'
				) Детали
				div(v-if='openDetails')
					.ql-container.ql-snow(v-if='!editable')
						.ql-editor(v-html='deal.details')
					template(v-else)
						Editor(v-model='deal.details')

					//- textarea.f-22(
					//- 	v-else
					//- 	v-model='deal.details'
					//- 	style='width:99.4%;resize:none;border:none;border-bottom-left-radius:.25em;border-bottom-right-radius:.25em;'
					//- 	rows='10'
					//- )

		template(v-if='deal.extend_request')
			.tac(v-if='iAmSeller') Получен запрос о продлении срока сделки на {{deal.extend_request}} {{days(deal.extend_request)}}
			.tac(v-else) Отправлен запрос о продлении срока сделки на {{deal.extend_request}} {{days(deal.extend_request)}}

		//- Buttons
		.buttons(v-if='editable')
			.btn.red(@click='del') Удалить
			template(v-if='dirty')
				.btn.red(@click='discardChanges') Отмена
				.btn(@click='save') Сохранить
			.btn.gold(@click='offer') Предложить сделку

		.buttons(v-else-if='deal.state=="offered"')
			.btn.red(v-if='iAmCreator' @click='cancel') Отменить
			template(v-else)
				.btn.red(@click='reject') Отклонить
				.btn(@click='accept') Принять

		.buttons(v-else-if='["actual","outdated"].includes(deal.state)')
			template(v-if='iAmSeller')
				.btn.gold(@click='extend') Продлить
				.btn(@click='close') Закрыть
			template(v-else)
				.btn.gold(@click='requestDealExtend') Запросить продление
				.btn(v-if='deal.extend_request' @click='cancelExtendRequest') Отменить запрос
</template>













<script>
import {
	someName,
	debounce,
	clone,
	days,
	rubs,
	ensureCss
} from '@/funcs.js'

import {
	stateColor,
	total
} from '@/deals.js'

import UserRow from '@/components/UserRow.vue'
import Editor from '@/components/Editor.vue'




export default {
	name: 'Deal',
	props: ['deal'],
	components: {UserRow, Editor},

	data(){
		return {
			open: false,
			openDetails: false,
			searchUsername: '',
			searchList: []
		}
	},

	created(){
		if (this.deal.state=='draft'){
			this.addDealgoodName = ''
			this.addDealgoodPrice = 0
			this.addDealgoodQ = 0

			if (!this.deal.original){

				// fix deal.details plain text format to html
				// check if it is already html
				if (!(this.deal.details.startsWith('<p>')&&this.deal.details.endsWith('</p>')))
					this.deal.details = this.text2html(this.deal.details)

				this.deal.original = Object.freeze(clone(this.deal))
			}
		}

		if (this.deal.details){
			ensureCss('/static/css/quill.snow.css')
		}
	},

	mounted(){
		if (this.deal.state=='draft'){

			const log = this.$store.state.loggedInUser
			const users = log
				? this.$store.state.users.filter(user=>{
					return !user.deleted && user!=log
				})
				: this.$store.state.users.filter(user=>!user.deleted)

			const fuse = new Fuse(users, {
				shouldSort: true,
				threshold: 0.6,
				location: 0,
				distance: 100,
				maxPatternLength: 32,
				minMatchCharLength: 1,
				keys:['lastName','firstName','userid','slug']
			})

			this.debFuse = debounce(()=>{
				this.searchList = fuse.search(this.searchUsername)
			}, 500)
		}
	},

	computed: {
		colors(){
			return stateColor(this.deal.state)
		},
		editable(){
			return this.deal.state=='draft'
		},
		seller(){
			return this.$store.getters.userById(this.deal.sellerId)
		},
		buyer(){
			return this.$store.getters.userById(this.deal.buyerId)
		},
		dirty(){
			if (!this.editable) return false

			if (!['days','sellerId','buyerId','creator_is_seller','details'].every(k=>{
				if (this.deal[k]==this.deal.original[k])
					return true
				else {
					// console.log('dirty property:', k)
					// console.log('original:', this.deal.original[k])
					// console.log('current:', this.deal[k])
					// console.log('bool:', this.deal[k]==this.deal.original[k])
					// for (let i = 0, l = this.deal[k].length; i<l; i++){
					// 	console.log(i, this.deal[k][i], this.deal.original[k][i], this.deal[k][i]==this.deal.original[k][i])
					// }
				}
			})){
				return true
			}

			if (this.deal.dealgoods.length != this.deal.original.dealgoods.length){
				console.log('dirty dealgoods.length')
				return true
			}

			if (!this.deal.dealgoods.every(dg=>{
				const original_dg = this.deal.original.dealgoods.find(odg=>odg.id==dg.id)
				if (original_dg)
					return (dg.goodId==original_dg.goodId || dg.goodName==original_dg.goodName) &&
						dg.price==original_dg.price &&
						dg.q==original_dg.q
			})){
				console.log('dirty dealgoods')
				return true
			}
			return false
		},
		iAmSeller(){
			return this.$store.state.loggedInUser.id==this.seller.id
		},
		iAmCreator(){
			if (this.deal.creator_is_seller){
				return this.iAmSeller
			} else {
				return !this.iAmSeller
			}
		}
	},

	methods: {
		someName,
		total,
		days,
		rubs,
		// balance,
		goodName(dealgood){
			if (dealgood.goodId)
				return this.$store.getters.goodById(dealgood.goodId).name
			if (dealgood.goodName)
				return dealgood.goodName
			return 'NoName Good'
		},
		search(lengthLim=true){
			if (!lengthLim){
				if (this.searchUsername.length>0)
					this.debFuse()
				else this.searchList.length=0
			} else {
				if (this.searchUsername.length<4)
					this.searchList.length=0
				else
					this.debFuse()
			}
		},
		setCounterpart(user){
			// if (this.deal.counterpartId==user.id) return

			if (this.deal.creator_is_seller)
				this.deal.buyerId = user.id
			else
				this.deal.sellerId = user.id

			this.searchList.length = 0
			this.searchUsername = ''
			if (this.deal.counterpartId!=user.id){
				this.deal.counterpartId = user.id
				this.$emit('setUser', user)
			}
		},
		unsetCounterpart(){
			this.setCounterpart({id:null})
		},
		swop(){
			if (this.deal.creator_is_seller) {
				this.deal.counterpartId = this.deal.sellerId
				this.deal.creatorId = this.deal.buyerId
			} else {
				this.deal.counterpartId = this.deal.buyerId
				this.deal.creatorId = this.deal.sellerId
			}
			[this.deal.sellerId, this.deal.buyerId] = [this.deal.buyerId, this.deal.sellerId]
			this.deal.creator_is_seller = !this.deal.creator_is_seller
		},
		addDealgood(){
			const name = this.addDealgoodName.trim().replace(/\s\s+/g,' ')
			if (!name) return
			if (this.addDealgoodPrice<=0) return
			if (this.addDealgoodQ<=0) return
			const dealgood = {
				id: null,
				price: Number(this.addDealgoodPrice),
				q: Number(this.addDealgoodQ)
			}
			const good = this.$store.getters.goodByName(name)
			if (good)
				dealgood.goodId = good.id
			else
				dealgood.goodName = name
			this.deal.dealgoods.push(dealgood)
			this.addDealgoodName = ''
			this.addDealgoodPrice = 0
			this.addDealgoodQ = 0
		},
		remDealgood(index){
			this.deal.dealgoods.splice(index,1)
		},
		discardChanges(){
			// clone(this.deal.original) to this.deal will erase this.deal.original
			const orig = this.deal.original
			this.deal = clone(orig)
			this.deal.original = orig
			const deal = this.deal
			// deal.days = orig.days
			// deal.sellerId = orig.sellerId
			// deal.buyerId = orig.buyerId
			// deal.creator_is_seller = orig.creator_is_seller
			// deal.dealgoods = clone(orig.dealgoods)
			// deal.details = orig.details

			if (deal.creator_is_seller&&deal.buyerId){
				this.setCounterpart(this.buyer)
			} else if (!deal.creator_is_seller&&deal.sellerId) {
				this.setCounterpart(this.seller)
			} else {
				this.unsetCounterpart()
			}
		},
		text2html(value){
			// return value.replace(/\n/g, '<br>')
			return value.split('\n').map(l=>'<p>'+l+'</p>').join('')
		},
		isValid(){
			if (!(this.deal.sellerId&&this.deal.buyerId)){
				notie.alert({
					type:'warning',
					text:'Не выбран покупатель/продавец!'
				})
				return false
			}

			if (!this.deal.dealgoods.length){
				notie.alert({
					type:'warning',
					text:'Сделка на нулевую сумму! Добавьте какой-нибудь товар или укажите сумму и детали сделки.'
				})
				return false
			}

			return true
		},
		savePayload(){
			return clone(this.deal, [
				'id',
				'sellerId',
				'buyerId',
				'creator_is_seller',
				'state',
				'created',
				'dealgoods',
				'details',
				'days'
			])
		},
		updateDealOriginal(){
			delete this.deal.original
			this.$set(this.deal, 'original', Object.freeze(clone(this.deal)))
		},
		save(){
			if (!this.isValid()) return

			const deal = this.savePayload()

			this.$store.dispatch('save_deal', deal).then(data=>{
				if (!data.errors.length){
					for (let p in data.deal){
						// if (p=='id') continue
						if (this.deal[p]!=data.deal[p])
							this.deal[p] = data.deal[p]
					}
					this.updateDealOriginal()
				}
			})
		},
		offer(){
			if (!this.isValid()) return
			const deal = this.savePayload()
			this.$store.dispatch('offer_deal', {deal, dirty:this.dirty}).then(data=>{
				if (!data.errors.length){
					delete this.deal.original
					for(let p in data.deal){
						if (this.deal[p]!=data.deal[p]){
							this.deal[p] = data.deal[p]
						}
					}
					// this.updateDealOriginal()

				}
			})
		},
		del(){
			this.$store.dispatch('delete_deal', this.deal.id).then(data=>{
				this.$emit('delete')
			})
		},
		accept(){
			this.$store.dispatch('accept_deal', this.deal.id).then(data=>{
				notie.alert({text:'Сделка принята'})
			})
		},
		reject(){
			this.$store.dispatch('reject_deal', this.deal.id).then(data=>{
				notie.alert({text:'Сделка отклонена.'})
				this.$emit('delete')
			})
		},
		cancel(){
			this.$store.dispatch('cancel_deal', this.deal.id).then(data=>{
				notie.alert({text:'Предложение отменено.'})
				this.updateDealOriginal()
			})
		},
		close(){
			this.$store.dispatch('close_deal', this.deal.id).then(data=>{
				notie.alert({text:'Сделка закрыта.'})
			})
		},
		requestDealExtend(){
			notie.input({
				text: 'На сколько дней запросить продление?',
				placeholder: '7',
				allowed: new RegExp('[^0-9]', 'g'),
				submitCallback: value => {
					value = Number(value)
					if (value<=0){
						notie.alert({type:'warning',text:'Количество дней должно быть положительным.'})
						return
					}
					this.$store.dispatch(
						'request_deal_extend',
						{days:value,dealId:this.deal.id}
					).then(data=>{
						notie.alert({text:'Запрос о продлении отправлен.'})
					})
				},
				submitText: 'Отправить запрос',
				cancelText: 'Отменить'
			})
		},
		extend(){
			notie.input({
				text: 'На сколько дней продлить сделку?',
				placeholder: this.deal.extend_request || '7',
				allowed: new RegExp('[^0-9]', 'g'),
				submitText: 'Продлить',
				cancelText: 'Отменить',
				submitCallback: value => {
					value = Number(value)
					if (value<=0){
						notie.alert({type:'warning',text:'Количество дней должно быть положительным.'})
						return
					}
					this.$store.dispatch(
						'extend_deal',
						{days:value, dealId:this.deal.id}
					).then(data=>{
						notie.alert({text:'Сделка продлена.'})
					})
				}
			})
		},
		cancelExtendRequest(){
			this.$store.dispatch('cancel_extend_request', this.deal.id).then(data=>{
				notie.alert({
					text: 'Запрос о продлении отменен.'
				})
			})
		}
	}
}
</script>













<style scoped>
.deal {
	/* border: 2px solid darkblue; */
	padding: .5em;
}
.rad {
	border-radius: .25em;
	overflow: hidden;
}
.click-title {
	padding-left:.25em;
	cursor:pointer;
}
.searchlist {
	max-height: 50vh;
	overflow-y: auto;
	padding-bottom: 1%;
	list-style: none;
	margin: 0;
	padding-left: 0;
	margin-top: .1em;
}
.goodslist {
	background:rgba(255,255,255,.5);
	padding:0 .25em;
}
.goodline {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: inherit;
}
.goodline:last-child {
	border-bottom-width: 0;
}
.buttons {
	text-align: right;
	margin-top:.4em;
}
.ql-container.ql-snow {
	border: none !important;
	background-color: rgba(255,255,255,.5);
	max-height: 30em;
	min-height: 10em;
	overflow-y: auto;
}
</style>
