<template lang="pug">
  span.f-22(
    @click.stop='show=!show'
    @blur='show=false'
    tabindex='0'
  )
    img.vam.lh-2e.w-1e(src='/static/img/gear.svg')
    Hovermenu(v-show='show' style='right:0;')
      slot

</template>









<script>
import Hovermenu from '@/components/Hovermenu.vue'

export default {
  name: 'ButtonHoverMenu',
  components: {Hovermenu},
  data(){
    return {
      show: false
    }
  },
}
</script>








<style scoped>

</style>
