<template lang="pug">
  .scrollbox(style='padding-top:1em;')
    .userrow
      .content
        Cell(:u='log' :n=-2 style='position:absolute;left:0;top:1%;')
        .f-22
          span {{totalBalance}}


    //- FILTER & TABS




    .list.f-16(style='border-top:1px solid #a9a9a9;padding-top:1%;')

      .buttons-row

        .tab.filter.f-22(
          :class='{active: filter.length>1}'
        )
          input.f-22(
            v-model='filter'
            placeholder='фильтр'
          )

        VDropdown()
          template(v-slot:trigger)
            span {{curTab.ru}}&nbsp;
            span.badge {{countDeals(curTab)}}

          .tabs-drop
            .tabs-drop__item(
              v-for='t in tabs'
              :key='t.name'
              :class='{"tabs-drop__item--selected": t.name==tab}'
              @click='tab=t.name'
            )
              span {{t.ru}}&nbsp;
              span.badge(v-if='countDeals(t)') {{countDeals(t)}}

        //- .tab.f-22(
          v-for='t in tabs'
          v-if='countDeals(t)'
          :key='t.name'
          :class='{"active": tab==t.name}'
          :style='tabStyle(t)'
          @click='tab=t.name'
          )
          .content
            .text.f-22 {{t.ru}}
            .badge.f-22(v-if='countDeals(t)') {{countDeals(t)}}

        VDropdown()
          template(v-slot:trigger)
            span сортировка: {{sortRu(sort)}}

          .tabs-drop
            .tabs-drop__item
              a.btn(@click='order*=-1') {{order>0?'по возр.':'по уб.'}}
            .tabs-drop__item(:class='{"tabs-drop__item--selected": sort=="date"}')
              a.btn(@click='sort="date"') по дате
            .tabs-drop__item(:class='{"tabs-drop__item--selected": sort=="left"}')
              a.btn(@click='sort="left"') по сроку
            .tabs-drop__item(:class='{"tabs-drop__item--selected": sort=="state"}')
              a.btn(@click='sort="state"') по статусу
            .tabs-drop__item(:class='{"tabs-drop__item--selected": sort=="total"}')
              a.btn(@click='sort="total"') по сумме
            .tabs-drop__item(:class='{"tabs-drop__item--selected": sort=="balance"}')
              a.btn(@click='sort="balance"') по балансу





      //- .f-22.tac
        a.btn(@click='order*=-1') {{order>0?'по возр.':'по уб.'}}
        a.btn(:class='{"active": sort=="date"}' @click='sort="date"') по дате
        a.btn(:class='{"active": sort=="left"}' @click='sort="left"') по сроку
        a.btn(:class='{"active": sort=="state"}' @click='sort="state"') по статусу
        a.btn(:class='{"active": sort=="total"}' @click='sort="total"') по сумме
        a.btn(:class='{"active": sort=="balance"}' @click='sort="balance"') по балансу


    //- USERS LIST
    .list
      .userdeals(
        v-for='user in users'
        :key='user.id'
      )
        UserDeals(
          :user='user'
          :tab='tab'
          :filter='filter'
          :order='order'
          :sort='sort'
          @delete='del(user.id)'
          @settab='setTab'
        )

</template>






<script>
import Cell from '@/components/Cell.vue'
import UserDeals from '@/components/UserDeals4.vue'
import {mapState, mapGetters} from 'vuex'
import {
  ensureCounterpartId,
  dealsBalance,
  balance,
  tabs,
  stateColor
} from '../deals'

// import { VDropdown } from 'vuetensils/src/components'


const sort_ru_names = {
  date:"по дате",
  left:"по сроку",
  state:"по статусу",
  total:"по сумме",
  balance:"по балансу",
}



export default {
  components: {
    Cell,
    UserDeals,
    // VDropdown,
  },

  data(){
    /* const list = []

    const myId = this.$store.state.loggedInUser.id
    this.$store.state.deals.forEach(deal=>{
      ensureCounterpartId(deal, myId)

      const inlineDeal = {deal, open: false}

      let userDeals = list.find(ud=>
        ud.user.id==deal.counterpartId)

      if (!userDeals){
        userDeals = {
          user: deal.counterpartId
            ? this.$store.getters.userById(deal.counterpartId)
            : {id: null},
          open: false,
          deals: []
        }
        list.push(userDeals)
      }
      userDeals.deals.push(inlineDeal)
    }) */

    return {
      // list,
      tab: 'all',
      tabs,
      filter: '',
      order: -1, //// 1 - accending | -1 - decending
      sort: 'date' //// date|left|state|total|balance
    }
  },

  computed: {
    ...mapState({log:'loggedInUser'}),
    ...mapGetters(['totalBalance']),

    users(){
      const list = []
      const myId = this.log.id
      this.$store.state.deals.forEach(deal=>{
        ensureCounterpartId(deal, myId)
        // console.log('counterpart', deal.counterpartId)
        const user = this.$store.getters.userById(deal.counterpartId)
        if (user.id==myId){
          console.log('WRONG!')
          return
        }
        // console.log('user', user)
        if (!list.find(u=>u.id==user.id)){
          if (!('dealsOpen' in user)) user.dealsOpen=false
          list.push(user)
        }
      })
      return list
    },

    curTab(){
			return this.tabs.find(t=>t.name==this.tab)
		},
  },

  methods: {
    balance,
    stateColor,

    tabStyle(tab){
      // console.log('tab', tab)
      const stColor = stateColor(tab.state)
      // console.log('stColor', stColor)
      const style = {
        color: stColor.font,
        background: stColor.title
      }

      return style
    },

    setTab(name){
      this.tab=name
    },

    userBalance(userDeals){
      return dealsBalance(userDeals.deals.map(id=>id.deal), this.log)
    },

    dealSign(deal){
      return deal.sellerId==this.log.id?'+':'-'
    },

    countDeals(tab){
      if (!tab) return -1
      console.log('DealsView3.methods.countDeals this.log', this.log)
      const myId = this.log.id
      // const res = this.list.reduce((m, userDeals)=>{
      //   return m + userDeals.deals.filter(idl=>tab.test(idl.deal, myId)).length
      // }, 0)
      const res = this.$store.state.deals.filter(deal=>{
        return tab.test(deal, myId)
      }).length
      return res
    },

    del(index){
      this.list.splice(index,1)
    },

    open_deal(deal){
      this.list.forEach(ud=>{
        ud.open = false
        ud.deals.forEach(id=>{
          if (id.deal===deal){
            id.open=true
            ud.open=true
            this.tab = tabs.find(tab=> tab.name!='all'&&tab.test(deal)).name
          } else {
            id.open=false
          }
        })
      })
    },

    sortRu(name){
      return sort_ru_names[name]
    },
  }
}
</script>












<style scoped>


.userrow {
  position: relative;
  margin: 1% 2%;
  /* padding: 1% 2%; */
  /* border-bottom: 1px solid #a9a9a9; */
}

.userrow:before {
  content: '';
  padding-top: 15.54%;
  display: block;
}

.userrow .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding-left: .8em; */
  /* border-radius: 1em; */

  /* background: lightgoldenrodyellow; */

  text-align: center;
}

.userrow .content .f-22 span {
  font-size: 4em;
}

.list{
  margin-top:1%;
}
.list:last-child {
  padding-bottom:25%;
}


.tab.filter {
  background: #ccc;
  color: #444;
  transition: all .1s ease-out;
}
.tab.filter.active {
  background: #036db7;
  color: #f0f8ff;
  box-shadow: none;
}
.tab.filter input {
  color: inherit;
  background: transparent;
  display:inline-block;
  line-height: 1.3em;
  margin: 0;
  border: 0;
  text-align: center;
  font-weight: bold;
}

.tab.filter input:focus {
  outline: none;
}

.tab {
  display: inline-block;
  border-radius: 1em;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  margin: .2em;
  transition: box-shadow .1s ease-in;
}
.tab.active {
  box-shadow: 0 0 0 4px lightblue;
}

.tab .content {
  font-size: 0;
  background-color: rgba(0,0,0,.1);
  transition: background-color .1s ease-out;
}
.tab.active .content {
  background-color: rgba(255,255,255,.5);
}
.tab:hover .content {
  background-color: rgba(255,255,255,.1);
}
.tab.active:hover .content {
  background-color: rgba(255,255,255,.8);
}

.tab .text {
  display:inline-block;
  line-height: 1.3em;
  margin: 0 .5em;
}
.tab .badge {
  display: inline-block;
  background: rgba(0,0,0,.2);
  border-radius: 1em;
  min-width: 1.3em;
  line-height: 1.3em;
  text-align:center;
  margin: 0;
}

a.btn {
  margin: 0 .2em;
}
a.btn.active {
  text-decoration: underline;
}

</style>
<style lang="scss">
// .vts-dropdown {
//   &__trigger {

//   }
// }
</style>
