<template lang="pug">
  .editable(
    contenteditable,
    :class='classes',
    @input='input',
    @keydown='key',
    @focus='highlight=true',
    @blur='blur'
  )
</template>
<script>

const isNum = function(c){
  return '0123456789'.indexOf(c)>=0
}
const allowedKeys = ['Backspace','Delete','ArrowRight','ArrowLeft','Tab']
const modKeys = ['Backspace','Delete']

export default {
  name: "Editable",
  props: [
    "value",
    "placeholder"
  ],

  data(){
    return {
      highlight: false
    }
  },

  mounted(){
    this.$el.innerText = this.value || this.placeholder
  },

  methods: {
    key(e){
      console.log('key', e.key)

      if (e.target.innerText===this.placeholder){
        if (isNum(e.key)||modKeys.includes(e.key)){
          e.target.innerText=''
        } else if (e.key==='Tab') {
        } else {
          e.preventDefault()
        }
      } else if (!(isNum(e.key)||allowedKeys.includes(e.key))) {
        e.preventDefault()
      }

    },

    input(e) {
      console.log('input', e.target.innerText)

      if (e.target.innerText===''){
        e.target.innerText=this.placeholder
        this.$emit("input", '')
      } else {
        // e.target.innerText = Number(e.target.innerText)
        this.$emit("input", e.target.innerText)
      }

    },

    blur(){
      this.highlight = false
      if (this.$el.innerText===''){
        this.$el.innerText=this.placeholder
      }
    }
  },

  watch: {
    value(value) {
      if (value === '') {

      } else if (value !== this.$el.innerText){
        this.$el.innerText = value
      }
    }
  },

  computed: {
    classes(){
      return {
        placeholder: this.value==='',
        highlight: this.highlight
      }
    }
  }
};
</script>
<style scoped>
.editable {
  display: inline-block;
}
.editable:focus {
  background-color:white;
}
</style>
