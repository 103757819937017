<template lang="pug">
  .scrollbox
    UserList(:uidlist='$store.state.loggedInUser.bag')
</template>




<script>
import UserList from '@/components/UserList.vue'

export default {
  name: 'BagListView',
  components: {UserList},

}
</script>
