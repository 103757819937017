<template>
  <div class="page">
    <div class="page__top">
      <button>&lt;</button>
      <input class="search">
      <button>Q</button>
    </div>

    <div class="page__middle">
      <div class="hex-box__wrap">
        <div class="hex-box"></div>
      </div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
    </div>

    <div class="page__bottom"></div>
  </div>
</template>

<script>
function onResize() {}

export default {
  name: 'Test',

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeDestroy() {},

  destroyed() {},
}
</script>

<style scoped>
.page {
  font-size: 1rem;
  position: fixed;
  box-sizing: border-box;
  border: 2px dashed red;
  background-color: lightpink;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.page__top {
  box-sizing: border-box;
  border: 2px dashed blue;
  background-color: lightblue;
  width: 100%;
  /* min-height: 2rem; */
  display: flex;

}
.page__middle {
  box-sizing: border-box;
  border: 2px dashed gray;
  background-color: lightgray;
  /* min-height: 2rem; */
  flex-grow: 1;
  overflow-y: auto;
}
.page__bottom {
  box-sizing: border-box;
  border: 2px dashed green;
  background-color: lightgreen;
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 0; */
  /* width: 100%; */
  height: 2rem;
  min-height: 2rem;
}
.page__top input.search {
  margin: 0;
  border: none;
  line-height: 2rem;
  flex-grow: 1;
}
.box {
  margin: 10vh auto;
  box-sizing: border-box;
  border: 2px dashed coral;
  background: lightcyan;
  width: 30vh;
  height: 30vh;
}
.hex-box__wrap {
  position: relative;
  box-sizing: border-box;
  border: 2px dashed black;
  background-color: lightseagreen;
  margin: 0 auto;
}
.hex-box__wrap:before {
  content: '';
  display: block;
  padding-top: 125.4%;
}
.hex-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>